import React from 'react';

import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';

import { useDiscussion } from 'src/api/discussion';
import Page from 'src/components/layout/Page';
import DiscussionModal from 'src/components/modals/DiscusssionModal/DiscussionModal';
import Breadcrumbs from 'src/components/ui-kit/Breadcrumbs';
import Loading from 'src/components/ui-kit/Loading';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
// import { showErrorMessage, showSuccessMessage } from 'src/helpers/toast';
import { useModal } from 'src/helpers/useModal';
import { ReactComponent as IconEdit } from 'src/images/icons/edit.svg';
import DiscussionCommentsSection from 'src/views/Discussions/DiscussionComments/DiscussionCommentsSection';
import DiscussionImage from 'src/views/Discussions/DiscussionComments/DiscussionImage';

import useDiscussionCommentsNavigation from './useDiscussionCommentsNavigation';

function DiscussionComments() {
  const { t } = useTranslation();
  const { discussionId } = useParams();
  const { role } = useCurrentUser();
  const { openModal } = useModal();

  const { data: discussion, isFetching, refetch } = useDiscussion({ discussionId, role });
  // const { mutateAsync: deleteDiscussion } = useDeleteDiscussionMutation();

  const { breadcrumbs } = useDiscussionCommentsNavigation(discussion);

  const onEditPost = () => {
    openModal(DiscussionModal, {
      discussion,
      onSuccess: refetch,
    });
  };

  // TODO: Enable deleting
  // const onDeletePost = async () => {
  //   try {
  //     // eslint-disable-next-line no-alert
  //     if (window.confirm(t('Are you sure you want to delete the discussion?
  //     This cannot be undone.'))) {
  //       await deleteDiscussion({ id: discussion.id });
  //       showSuccessMessage('Post successfully deleted');
  //       navigate(`/${role}/discussion`);
  //     }
  //   } catch (e) {
  //     showErrorMessage(e.message);
  //   }
  // };

  if (isFetching) {
    return (
      <Page title={t('Loading')}>
        <Loading />
      </Page>
    );
  }

  return (
    <Page className="pb-8" title={discussion.title}>
      <section className="mt-1 flex items-center justify-between">
        <div className="grid gap-2">
          <h1>{discussion.title}</h1>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>

        { role === 'teacher' && (
          <div className="flex gap-3 items-center">
            <button
              className="button--primary flex gap-1 items-center"
              onClick={onEditPost}
            >
              <IconEdit />
              {t('Edit Discussion')}
            </button>
            {/* <button */}
            {/*  className="button--danger" */}
            {/*  onClick={onDeletePost} */}
            {/* > */}
            {/*  {t('Delete')} */}
            {/* </button> */}
          </div>
        )}
      </section>

      <section className="my-4">
        <NavLink
          className="text-lg ho-link flex gap-2 items-center"
          to={`/teacher/classrooms/${discussion.classroomId}`}
        >
          <span>{discussion.classroomName}</span>
        </NavLink>
      </section>

      <section className="grid gap-6 mt-8 grid-cols-[395px_auto]">
        <div className="ho-card overflow-hidden">
          <DiscussionImage discussion={discussion} onImageChange={refetch} />

          <div className="p-6">
            <NavLink className="text-Blue hover:underline text-sm" to={`/teacher/classrooms/${discussion.classroomId}`}>
              {discussion.classroomName}
            </NavLink>

            <h5 className="mt-1 mb-2">{discussion.title}</h5>

            <p className="html prose" dangerouslySetInnerHTML={{ __html: discussion.value }} />
          </div>
        </div>

        <div className="xl:pr-24">
          <DiscussionCommentsSection discussion={discussion} />
        </div>
      </section>
    </Page>
  );
}

export default DiscussionComments;
