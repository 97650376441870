import React from 'react';

import { useTranslation } from 'react-i18next';

import DataGrid from 'src/components/ui-kit/DataGrid';
import MenuDropdown from 'src/components/ui-kit/MenuDropdown';
import { useModal } from 'src/helpers/useModal';
import useClassActions from 'src/views/Teacher/ManageClasses/useClassActions';

function ClassesGridView() {
  const onLoadMore = () => {};
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { getDropdownItems } = useClassActions({ openModal });

  const classes = [
    {
      code: 'XGTB7R',
      description: 'Co-teachers: Marcus Klotz and Heather Menar',
      id: 1,
      name: 'Class Name A',
      studentsCount: 23,
    },
    {
      code: 'DCDB7R',
      description: 'Co-teachers: Marcus Klotz and Heather Menar',
      id: 2,
      name: 'Class Name B',
      studentsCount: 1,
    },
  ];

  return (
    <DataGrid
      hasNextPage={false}
      isFetching={false}
      onLoadMore={onLoadMore}
    >
      <table className="table-grid">
        <thead>
          <tr className="ho-font-rounds text-sm grid-cols-[3fr_1fr_1fr_98px]">
            <th>{t('Class name')}</th>
            <th>{t('Invite code')}</th>
            <th>{t('Class roster')}</th>
            <th />
          </tr>
        </thead>

        <tbody className="mt-5">
          {classes.map((data) => (
            <tr key={data.id} className="grid-cols-[3fr_1fr_1fr_98px]">
              <td>{data.name}</td>
              <td>{data.code}</td>
              <td>23 students</td>
              <td>
                <MenuDropdown data={data} items={getDropdownItems(data)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </DataGrid>
  );
}

export default ClassesGridView;
