import React, { createContext, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { useClassrooms } from 'src/api/classrooms';
import { statuses, useCourses } from 'src/api/courses';
import { useStudentFeedbackStats } from 'src/api/stats';
import { useCurrentUser } from 'src/contexts/useCurrentUser';

export const DashboardContext = createContext({
  classrooms: [],
  // Currently, selected course on the dashboard
  course: null,
  courses: [],
  fetchClassrooms: () => {},
  hasClassrooms: false,
  hasCourses: false,
  isFetching: false,
  setCourse: () => {},
  studentFeedbackStats: {},
});

export function DashboardProvider(props) {
  const { children } = props;
  const { role } = useCurrentUser();

  const [course, setCourse] = useState(null);

  // fetch courses
  const { isFetching: isFetchingCourses, items: courses = [] } = useCourses({
    checkMode: true,
    role,
    size: 20,
    status: statuses.PURCHASED_ONLY,
  }, {
    onSuccess(response) {
      const firstCourse = response.pages[0]?.content[0];
      setCourse(firstCourse);
    },
  });

  const hasCourses = courses.length > 0;

  // fetch classrooms
  const {
    isFetching: isFetchingClassrooms,
    items: classrooms = [],
    refetch: fetchClassrooms,
  } = useClassrooms({ size: 20 }, { enabled: hasCourses });

  // fetch student feedback stats
  const {
    data: studentFeedbackStats = { engagement: {}, rigor: {} },
    isFetching: isFetchingStudentFeedbackStats,
  } = useStudentFeedbackStats(
    { courseId: course?.id },
    { enabled: hasCourses && !!course },
  );

  const hasClassrooms = classrooms.length > 0;

  const isFetching = isFetchingCourses
    || isFetchingClassrooms
    || isFetchingStudentFeedbackStats;

  const context = useMemo(() => ({
    classrooms,
    course,
    courses: [],
    fetchClassrooms,
    hasClassrooms,
    hasCourses,
    isFetching,
    setCourse,
    studentFeedbackStats,
  }), [
    classrooms,
    course,
    fetchClassrooms,
    hasClassrooms,
    hasCourses,
    isFetching,
    studentFeedbackStats,
  ]);

  return (
    <DashboardContext.Provider value={context}>
      {children}
    </DashboardContext.Provider>
  );
}

DashboardProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useDashboard = () => React.useContext(DashboardContext);
