import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useCurrentUser } from 'src/contexts/useCurrentUser';
import { useLessonPreviewUrl } from 'src/helpers/lessons';
import { ReactComponent as IconOpenBook } from 'src/images/icons/open-book.svg';
import BreadcrumbModel from 'src/models/BreadcrumbModel';
import SidebarMenuItemModel from 'src/models/SidebarMenuItemModel';

function useLessonNavigation(lesson, course) {
  const { t } = useTranslation();
  const { role } = useCurrentUser();
  const { buildUrl } = useLessonPreviewUrl();
  const { courseId, lessonId } = useParams();
  const navigate = useNavigate();

  const menuItems = lesson.modules.map((module, index) => new SidebarMenuItemModel({
    Icon: <IconOpenBook />,
    action() {
      navigate(buildUrl({ courseId, lessonId, moduleId: index + 1 }));
    },
    key: module.type,
    title: t(module.type),
  }));

  const breadcrumbs = [
    new BreadcrumbModel({
      key: 'courses',
      name: t('Courses'),
      route: '/student/courses',
    }),
    course ? new BreadcrumbModel({
      key: `course-${course.id}`,
      name: course.name,
      route: `/${role}/courses/${course.id}`,
    }) : null,
    lesson ? new BreadcrumbModel({
      key: `lesson-${lesson.id}`,
      name: lesson.name,
      route: null,
    }) : null,
  ].filter(Boolean);

  const [activeMenuItem, setActiveMenuItem] = useState(menuItems[0].key);

  return {
    activeMenuItem,
    breadcrumbs,
    menuItems,
    setActiveMenuItem,
  };
}

export default useLessonNavigation;
