import React from 'react';

import { format } from 'date-fns';
import PropTypes from 'prop-types';

import TextClamp from 'src/components/ui-kit/TextClamp';

function Notification(props) {
  const { data } = props;

  return (
    <div className="Notification ho-card px-5 py-4 grid grid-cols-[120px_auto] gap-4">
      <img
        alt={data.title}
        src={data.imageUrl}
      />

      <div>
        <h5>{data.title}</h5>
        <TextClamp clampClassName="line-clamp-2">
          <p className="text-sm mb-0 mt-2 text-ho-grey-300 html prose" dangerouslySetInnerHTML={{ __html: data.content }} />
        </TextClamp>
        <time className="block text-sm mt-5 text-right text-ho-blue-400">
          {format(new Date(data.date * 1000), 'PPpp')}
        </time>
      </div>
    </div>
  );
}

Notification.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Notification;
