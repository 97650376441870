import React, { useLayoutEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { signInWithEmailAndPassword, browserLocalPersistence, browserSessionPersistence } from 'firebase/auth';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import { clearAuthData, setToken } from 'src/api/api';
import { firebaseAuth } from 'src/api/firebase';
import { getUserRole, useCurrentUser, getUserDefaultRoute } from 'src/contexts/useCurrentUser';
import { showErrorMessage } from 'src/helpers/toast';
import useValidationHelpers from 'src/helpers/useValidationHelpers';

const schema = () => yup.object({
  email: yup
    .string()
    .required(i18n.t('Field is required'))
    .email(i18n.t('Must be a valid email field')),
  password: yup
    .string()
    .required(i18n.t('Field is required')),
  rememberMe: yup
    .boolean()
    .required(i18n.t('Field is required')),
}).required();

const initialFormData = {
  email: '',
  password: '',
  rememberMe: false,
};

// eslint-disable-next-line no-alert
const confirmLoginWithOtherRole = (role) => window.confirm(
  i18n.t('You are trying to login as a {{role}} instead. Do you want to continue?', { role }),
);

function LoginWithEmailForm(props) {
  const { userRole } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const {
    formState,
    handleSubmit,
    register,
    reset,
    setFocus,
  } = useForm({
    defaultValues: initialFormData,
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema()),
  });

  const { fetchMe } = useCurrentUser();

  const { getFieldError } = useValidationHelpers({
    formState,
  });

  useLayoutEffect(() => {
    setFocus('email');
  }, [setFocus]);

  const onSubmit = async (formData, event) => {
    event.preventDefault();

    try {
      await firebaseAuth.setPersistence(
        formData.rememberMe
          ? browserLocalPersistence
          : browserSessionPersistence,
      );
      const response = await signInWithEmailAndPassword(
        firebaseAuth,
        formData.email,
        formData.password,
      );
      setToken(response.user.accessToken);
      const { data: user } = await fetchMe();
      const loggedInUserRole = getUserRole(user);
      const loggedInUserDefaultRoute = params.get('redirect') || getUserDefaultRoute(user);

      // If user is trying to log in with another type ask for confirmation.
      // Proceed if confirmed. Reset the form and remove auth data otherwise
      if (loggedInUserRole !== userRole && !confirmLoginWithOtherRole(loggedInUserRole)) {
        reset();
        clearAuthData();
      } else {
        navigate(loggedInUserDefaultRoute);
      }
    } catch (e) {
      showErrorMessage(e.message);
    }
  };

  return (
    <form
      className="LoginWIthEmailForm w-[500px] ho-card p-6 grid gap-10"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        <h4>{t('Sign in')}</h4>
        <p className="mt-1 text-xs">{t('Please, sign in below')}</p>
      </div>

      <div>
        <input
          className={classNames('input mt-2', { 'input--has-error': getFieldError('email') })}
          id="LoginWIthEmailForm__email"
          name="email"
          placeholder={t('Email*')}
          {...register('email')}
        />
        <p className="error-message mt-1">{getFieldError('email')}</p>
      </div>

      <div>
        <input
          className={classNames('input w-full', { 'input--has-error': getFieldError('password') })}
          id="LoginWIthEmailForm__password"
          name="password"
          placeholder={t('Password*')}
          type="password"
          {...register('password')}
        />
        <p className="error-message mt-1">{getFieldError('password')}</p>
      </div>

      <div className="flex justify-between items-center mt-10">
        <div>
          <p className="mt-1 text-xs">{t("Don't have an account?")}</p>
          <NavLink className="underline mt-1 text-Blue" to={`/${userRole}/signup`}>
            {t('Sign up')}
          </NavLink>
        </div>

        <button
          className={classNames('button--primary', { 'visually-disabled': !formState.isValid })}
          type="submit"
        >
          {t('Sign in')}
        </button>
      </div>
    </form>
  );
}

LoginWithEmailForm.propTypes = {
  userRole: PropTypes.oneOf(['teacher', 'student']).isRequired,
};

export default LoginWithEmailForm;
