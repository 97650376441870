import React, { useState } from 'react';

import PropTypes from 'prop-types';
import Cropper from 'react-cropper';
import { useTranslation } from 'react-i18next';

import Modal from 'src/components/ui-kit/Modal';
import { toBlob } from 'src/helpers/file';

function CropImageModal(props) {
  const { cropperProps, image, isOpen, onClose, onSuccess, title } = props;
  const { t } = useTranslation();
  const [cropper, setCropper] = useState(null);

  const onSave = async () => {
    const canvas = cropper.getCroppedCanvas();
    const blob = await toBlob(canvas);
    onSuccess(blob);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      shouldShowCloseButton={false}
      title={title}
    >
      <Cropper
        aspectRatio={1}
        autoCropArea={1}
        background
        initialAspectRatio={1}
        onInitialized={setCropper}
        src={image}
        style={{
          background: 'white',
          height: 400,
          width: '100%',
        }}
        viewMode={1}
        {...cropperProps}
      />

      <div className="mt-6 flex items-center justify-between">
        <button
          className="button--secondary-outlined"
          onClick={onClose}
          type="button"
        >
          {t('Cancel')}
        </button>

        <button
          className="button--primary"
          onClick={onSave}
        >
          {t('Save')}
        </button>
      </div>
    </Modal>
  );
}

CropImageModal.propTypes = {
  cropperProps: PropTypes.object.isRequired,
  image: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default CropImageModal;
