import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as IconBook } from 'src/images/icons/book.svg';
import { ReactComponent as IconOpenBook } from 'src/images/icons/open-book.svg';
import { ReactComponent as IconUser } from 'src/images/icons/user.svg';
import BreadcrumbModel from 'src/models/BreadcrumbModel';
import SidebarMenuItemModel from 'src/models/SidebarMenuItemModel';

function useCourseNavigation(course) {
  const { t } = useTranslation();

  const menuItems = [
    new SidebarMenuItemModel({
      Icon: <IconOpenBook className="w-4" />,
      key: 'overview',
      title: t('Overview'),
    }),
    new SidebarMenuItemModel({
      Icon: <IconUser className="w-4" />,
      key: 'professor',
      title: t('Professor'),
    }),
    new SidebarMenuItemModel({
      Icon: <IconBook className="w-4" />,
      key: 'lessons',
      title: t('Lessons'),
    }),
  ].filter(Boolean);

  const breadcrumbs = [
    new BreadcrumbModel({
      key: 'courses',
      name: t('Courses'),
      route: '/student/courses',
    }),
    course ? new BreadcrumbModel({
      key: course.id,
      name: course.name,
      route: null,
    }) : null,
  ].filter(Boolean);

  const [activeMenuItem, setActiveMenuItem] = useState(menuItems[0].key);

  return {
    activeMenuItem,
    breadcrumbs,
    menuItems,
    setActiveMenuItem,
  };
}

export default useCourseNavigation;
