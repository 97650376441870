import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useCurrentUser } from 'src/contexts/useCurrentUser';
import QuizAnswers from 'src/views/Study/LessonModule/contentItems/QUIZ/QuizAnswers';

function QuizFieldset(props) {
  const { formState, index, loading, onFormStateChange, quiz } = props;
  const { role } = useCurrentUser();

  const hasMultipleAnswers = quiz.answers.filter((answer) => answer.correct).length > 1;

  const type = hasMultipleAnswers ? 'multiple' : 'singular';

  const handleChange = async (event, answer) => {
    const newValue = {
      ...formState,
    };

    const { id } = answer;
    const newAnswer = { id, quizId: quiz.id };
    if (type === 'singular') {
      newValue.answers = [newAnswer];
      newValue.quizAnswerIds = [id];
    } else if (!newValue.quizAnswerIds.includes(answer.id)) {
      newValue.answers.push(newAnswer);
      newValue.quizAnswerIds.push(answer.id);
    }
    onFormStateChange(newValue);
  };

  const isChecked = (answer) => formState.quizAnswerIds.includes(answer.id);

  // Teacher can only view responses
  // We disable the answer if no attempts left
  const isDisabled = () => !!(role === 'teacher' || formState.completed);

  const elementType = type === 'singular' ? 'radio' : 'checkbox';

  return (
    <fieldset key={index}>
      <legend className="my-2 html prose" dangerouslySetInnerHTML={{ __html: quiz.body }} />

      {quiz.answers.map((answer) => (
        <div key={answer.id} className="mb-2 flex gap-2 items-center">
          <input
            checked={isChecked(answer)}
            className={elementType}
            disabled={isDisabled()}
            id={`input-${index}-${answer.id}`}
            name={`${elementType}-group-${index}`}
            onChange={(event) => handleChange(event, answer)}
            type={elementType}
            value={`input-${index}-${answer.id}`}
          />
          <label
            className={classNames({
              'text-ho-grey-200': formState.completed,
            })}
            htmlFor={`input-${index}-${answer.id}`}
          >
            <span dangerouslySetInnerHTML={{ __html: answer.body }} />
          </label>
        </div>
      ))}

      {formState.attempted && !loading && (
        <QuizAnswers formState={formState} quiz={quiz} />
      )}
    </fieldset>
  );
}

QuizFieldset.propTypes = {
  formState: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  onFormStateChange: PropTypes.func.isRequired,
  quiz: PropTypes.object.isRequired,
};

export default QuizFieldset;
