import React, { useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function TextClamp(props) {
  const { children, clampClassName, enabled } = props;
  const [showMore, setShowMore] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="TextClamp">
      <div className={classNames({ [clampClassName]: !showMore && enabled })}>
        {children}
      </div>

      {enabled && (
        <button
          className="button--text ho-link mt-2"
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? t('Show less') : t('Show more')}
        </button>
      )}
    </div>
  );
}

TextClamp.defaultProps = {
  enabled: true,
};

TextClamp.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  clampClassName: PropTypes.string.isRequired,
  enabled: PropTypes.bool,
};

export default TextClamp;
