import React, { useState } from 'react';

import Fuse from 'fuse.js';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DataGrid from 'src/components/ui-kit/DataGrid';
import InputSearch from 'src/components/ui-kit/InputSearch';

function findDocuments(course, searchValue) {
  const docs = course.teacherDocItems ? course.teacherDocItems.slice() : [];

  if (!searchValue) {
    return docs;
  }

  const fuse = new Fuse(docs, {
    keys: ['title', 'body'],
    threshold: 0.2,
  });
  return fuse.search(searchValue).map(({ item }) => item);
}

function CourseTeacherDocuments(props) {
  const { course } = props;

  const [searchValue, setSearchValue] = useState('');

  const { t } = useTranslation();

  const items = findDocuments(course, searchValue);

  return (
    <section className="CourseTeacherDocuments ho-card">
      <div className="p-6">
        <InputSearch
          className="w-96"
          onInput={(event) => setSearchValue(event.target.value)}
          placeholder={t('Find')}
          value={searchValue}
        />
      </div>
      <DataGrid
        emptyText={t('No teacher documents available')}
        hasNextPage={false}
        isEmpty={items.length === 0}
        isFetching={false}
        onLoadMore={() => {}}
      >
        <table className="table-grid">
          <thead>
            <tr className="grid px-8 justify-start bg-MainYellow/[0.15] h-10 items-center">
              <th>{t('Name and the link')}</th>
            </tr>
          </thead>

          <tbody>
            {items.map((item, index) => (
              <tr key={index} className="grid px-8 justify-start items-center h-14">
                <td>
                  {item.body ? (
                    <a
                      className="text-base ho-link-underline"
                      href={item.body}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {item.title}
                    </a>
                  ) : (item.title)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </DataGrid>
    </section>
  );
}

CourseTeacherDocuments.propTypes = {
  course: PropTypes.object.isRequired,
};

export default CourseTeacherDocuments;
