import { useMutation, useQuery } from 'react-query';

import { api } from 'src/api/api';

export const DISCUSSION_COMMENTS_QUERY_NAME = 'DISCUSSION_COMMENTS_QUERY_NAME';

export const useDiscussionComments = ({ discussionId, role }) => useQuery(
  [DISCUSSION_COMMENTS_QUERY_NAME, discussionId],
  () => api.get(`/${role}/discussions/${discussionId}/comments`).then((response) => response.data),
);

export const createComment = ({ body, discussionId, role }) => api.post(`/${role}/discussions/${discussionId}/comments`, body);

export const useCreateDiscussionCommentsMutation = () => useMutation(createComment);
