import { useTranslation } from 'react-i18next';

import BreadcrumbModel from 'src/models/BreadcrumbModel';

function useDiscussionCommentsNavigation(discussion) {
  const { t } = useTranslation();

  const breadcrumbs = [
    new BreadcrumbModel({
      key: 'discussions',
      name: t('Discussions'),
      route: '/teacher/discussion',
    }),
    discussion ? new BreadcrumbModel({
      key: discussion.id,
      name: discussion.title,
      route: null,
    }) : null,
  ].filter(Boolean);

  return {
    breadcrumbs,
  };
}

export default useDiscussionCommentsNavigation;
