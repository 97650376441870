import React, { Fragment } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { moduleHasContentItem } from 'src/helpers/lessons';
import IMAGE from 'src/views/Study/LessonModule/contentItems/IMAGE';
import QUESTIONNAIRE from 'src/views/Study/LessonModule/contentItems/QUESTIONNAIRE';
import QUIZ from 'src/views/Study/LessonModule/contentItems/QUIZ/QUIZ';
import TEXT from 'src/views/Study/LessonModule/contentItems/TEXT';
import VIDEO from 'src/views/Study/LessonModule/contentItems/VIDEO';

function DEFAULT(props) {
  const { module } = props;

  const hasMedia = moduleHasContentItem(module, 'IMAGE') || moduleHasContentItem(module, 'VIDEO');

  return (
    <>
      {module.contentItems.map((contentItem) => (
        <Fragment key={contentItem.id}>
          {contentItem.type === 'VIDEO' && (
            <VIDEO
              className="order-first"
              contentItem={contentItem}
            />
          )}

          {contentItem.type === 'TEXT' && (
            <TEXT
              className={classNames({ 'col-span-2': !hasMedia })}
              contentItem={contentItem}
            />
          )}

          {contentItem.type === 'IMAGE' && (
            <IMAGE
              contentItem={contentItem}
            />
          )}

          {contentItem.type === 'QUIZ' && (
            <QUIZ
              className="col-span-2"
              contentItem={contentItem}
            />
          )}

          {contentItem.type === 'QUESTIONNAIRE' && (
            <QUESTIONNAIRE
              className="col-span-2"
              contentItem={contentItem}
            />
          )}
        </Fragment>
      ))}
    </>
  );
}

DEFAULT.propTypes = {
  module: PropTypes.object.isRequired,
};

export default DEFAULT;
