import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import EmptyState from 'src/components/ui-kit/EmptyState';
import Loading from 'src/components/ui-kit/Loading';

function DataGrid(props) {
  const {
    children, className, emptyText, hasNextPage, isEmpty, isFetching, onLoadMore,
  } = props;

  const { t } = useTranslation();

  return (
    <div className={classNames('DataGrid', className)}>
      {!isEmpty && children}

      {hasNextPage && !isFetching && (
        <button className="button--secondary mt-10 block mx-auto" onClick={onLoadMore}>
          Load more
        </button>
      )}
      {isFetching && (
        <Loading />
      )}
      {isEmpty && !isFetching && (
        <div className="pb-10">
          <EmptyState text={emptyText || t('No data found')} />
        </div>
      )}
    </div>
  );
}

DataGrid.defaultProps = {
  className: '',
  emptyText: '',
};

DataGrid.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  emptyText: PropTypes.string,
  hasNextPage: PropTypes.bool.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onLoadMore: PropTypes.func.isRequired,
};

export default DataGrid;
