export function transformInfiniteQueryResult(result) {
  const { data, hasNextPage, isFetched, isFetching, ...other } = result;

  return {
    hasNextPage: !!hasNextPage,
    isFetched: !!isFetched,
    isFetching: !!isFetching,
    items: (data?.pages ?? []).map((item) => item.content).flat(1),
    ...other,
  };
}
