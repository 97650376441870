import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { getContentItem } from 'src/helpers/lessons';
import QUIZ from 'src/views/Study/LessonModule/contentItems/QUIZ/QUIZ';
import TEXT from 'src/views/Study/LessonModule/contentItems/TEXT';
import VIDEO from 'src/views/Study/LessonModule/contentItems/VIDEO';

function MINI_VIDEO(props) {
  const { module } = props;
  const [videoEl, setVideoEl] = useState(null);

  return (
    <>
      <div className="grid grid-cols-2 gap-6 col-span-2">
        <VIDEO
          contentItem={getContentItem(module, 'VIDEO')}
          onLoaded={setVideoEl}
        />

        {videoEl && (
          <div
            className="overflow-y-scroll"
            style={{ height: `${videoEl.getBoundingClientRect().height + 8}px` }}
          >
            <TEXT contentItem={getContentItem(module, 'TEXT')} />
          </div>
        )}
      </div>

      <QUIZ
        className="col-span-2"
        contentItem={getContentItem(module, 'QUIZ')}
      />
    </>
  );
}

MINI_VIDEO.propTypes = {
  module: PropTypes.object.isRequired,
};

export default MINI_VIDEO;
