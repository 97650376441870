import React from 'react';

import { useTranslation } from 'react-i18next';

import { SUPPORT_URL } from 'src/config';
import { openUrlInNewTab } from 'src/helpers/url';
import { ReactComponent as IconComputer } from 'src/images/icons/computer.svg';
import { ReactComponent as IconCommunication } from 'src/images/icons-deprecated/communication.svg';
import { ReactComponent as IconHelp } from 'src/images/icons-deprecated/help.svg';
import NavigationItemModel from 'src/models/NavigationItemModel';

import Courses from './Courses/Courses';
import Discussions from '../Discussions/Discussions';
import Notifications from '../Notifications/Notifications';
import IconNotificationsFetcher from '../Teacher/IconNotificationsFetcher';

function useStudentNavigation() {
  const { t } = useTranslation();

  const navigationItems = [
    new NavigationItemModel(
      {
        Icon: <IconComputer />,
        absolutePath: '/student/courses',
        component: <Courses />,
        name: t('Courses'),
        relativePath: 'courses',
        to: '/student/courses/',
      },
    ),
    new NavigationItemModel(
      {
        Icon: <IconCommunication className="fill-current" />,
        absolutePath: '/student/discussion',
        component: <Discussions />,
        name: t('Discussion'),
        relativePath: 'discussion',
        to: '/student/discussion',
      },
    ),
    new NavigationItemModel(
      {
        Icon: <IconHelp className="stroke-current" />,
        absolutePath: '',
        action() {
          openUrlInNewTab(SUPPORT_URL);
        },
        name: t('Help'),
      },
    ),
    new NavigationItemModel(
      {
        Icon: <IconNotificationsFetcher />,
        absolutePath: '/student/notifications',
        component: <Notifications />,
        name: t('Notifications'),
        relativePath: 'notifications',
        to: '/student/notifications',
      },
    ),
  ];

  return {
    navigationItems,
  };
}

export default useStudentNavigation;
