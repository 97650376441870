import React from 'react';

import PropTypes from 'prop-types';

import { useCurrentUser } from 'src/contexts/useCurrentUser';
import { ReactComponent as Logo } from 'src/images/logo.svg';

import CurrentUserDropdown from './CurrentUserDropdown';

function Header(props) {
  const { Menu } = props;
  const { defaultRoute } = useCurrentUser();

  return (
    <header className="h-[88px] bg-MainBlue flex justify-between items-center px-6 fixed w-full z-50">
      <a href={defaultRoute}>
        <Logo />
      </a>
      {Menu}
      <CurrentUserDropdown />
    </header>
  );
}

Header.propTypes = {
  Menu: PropTypes.object.isRequired,
};

export default Header;
