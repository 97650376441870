import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useCreateDiscussionCommentsMutation } from 'src/api/discussion-comments';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import { showErrorMessage } from 'src/helpers/toast';

const schema = () => yup.object({
  comment: yup
    .string()
    .required(i18n.t('Field is required')),
}).required();

const initialFormData = () => ({
  comment: '',
});

function CommentForm(props) {
  const { discussion, onSuccess } = props;

  const { t } = useTranslation();
  const { role } = useCurrentUser();

  const { formState, handleSubmit, register, reset } = useForm({
    defaultValues: initialFormData(),
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema()),
  });

  const { mutateAsync: createComment } = useCreateDiscussionCommentsMutation();

  const onSubmit = async (formData, event) => {
    event.preventDefault();

    try {
      await createComment({
        body: { body: formData.comment },
        discussionId: discussion.id,
        role,
      });
      onSuccess();
      reset();
    } catch (e) {
      showErrorMessage(e.message);
    }
  };

  return (
    <form
      className="CommentForm"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="ho-card px-6 pt-6 pb-4">
        <textarea
          className="textarea"
          id="CommentForm__comment"
          name="comment"
          placeholder={t('Write your response here')}
          rows={4}
          {...register('comment')}
        />
      </div>

      <div className="mt-5 flex justify-center">
        <button
          className="button--primary"
          disabled={!formState.isValid}
        >
          {t('Submit')}
        </button>
      </div>
    </form>
  );
}

CommentForm.propTypes = {
  discussion: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default CommentForm;
