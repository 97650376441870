import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useDeleteClassroomMutation } from 'src/api/classrooms';
import Alert from 'src/components/ui-kit/Alert';
import Modal from 'src/components/ui-kit/Modal';
import { showErrorMessage, showSuccessMessage } from 'src/helpers/toast';

function DeleteClassroomModal(props) {
  const { classroom, isOpen, onClose, onSuccess } = props;

  const { mutateAsync: deleteClassroom } = useDeleteClassroomMutation();

  const { t } = useTranslation();

  const onDeleteClassroom = async () => {
    try {
      await deleteClassroom({ id: classroom.id });
      showSuccessMessage('Classroom successfully deleted');
      onSuccess();
    } catch (e) {
      showErrorMessage(e.message);
    }
  };
  const handleDelete = () => onDeleteClassroom({ classroom, onSuccess });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      shouldShowCloseButton={false}
      size="sm"
      title={t('Delete classroom')}
    >
      <div className="-mt-10">
        {['PRE_LAUNCH', 'STARTED'].includes(classroom.launchStatus) && (
          <div className="mt-2">
            <Alert
              message={t('The Classroom is already launched. If you delete it, your students wont be able to continue studying')}
              variant="error"
            />
          </div>
        )}
        <p className="mt-2">{t('Are you sure you want to delete classroom?')}</p>
        <div className="mt-6 flex items-center justify-between">
          <button
            className="button--secondary-outlined"
            onClick={onClose}
            type="button"
          >
            {t('Cancel')}
          </button>

          <button
            className="button--primary"
            onClick={handleDelete}
            type="submit"
          >
            {t('Delete')}
          </button>
        </div>
      </div>
    </Modal>
  );
}

DeleteClassroomModal.propTypes = {
  classroom: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default DeleteClassroomModal;
