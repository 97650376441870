import React from 'react';

import { Route, Routes } from 'react-router-dom';

import Lesson from 'src/views/Study/Lesson/Lesson';

function Study() {
  return (
    <Routes>
      <Route element={<Lesson />} path="courses/:courseId/lessons/:lessonId/*" />
    </Routes>
  );
}

export default Study;
