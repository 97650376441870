import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { useAccountLogoMutation } from 'src/api/account';
import CropImageModal from 'src/components/common/CropImageModal';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import { toBase64 } from 'src/helpers/file';
import { showErrorMessage, showSuccessMessage } from 'src/helpers/toast';
import { useModal } from 'src/helpers/useModal';
import { ReactComponent as IconEdit } from 'src/images/icons-deprecated/edit.svg';
import { ReactComponent as IconUser } from 'src/images/icons-deprecated/user.svg';

function ProfileAvatar() {
  const { fetchMe, me } = useCurrentUser();
  const { t } = useTranslation();
  const inputRef = useRef();
  const { closeModal, openModal } = useModal();
  const { mutateAsync: updateLogo } = useAccountLogoMutation();

  const onChange = async (event) => {
    const file = event.target.files[0];
    const image = await toBase64(file);
    openModal(CropImageModal, {
      cropperProps: {
        aspectRatio: 1,
        initialAspectRati: 1,
      },
      image,
      onClose() {
        inputRef.current.value = '';
      },
      async onSuccess(blob) {
        try {
          await updateLogo(blob);
          await fetchMe();
          closeModal();
          showSuccessMessage(t('Profile image successfully updated'));
        } catch (e) {
          showErrorMessage(e.message);
        }
      },
      title: t('Edit profile image'),
    });
  };

  return (
    <div className="ProfileAvatar w-[120px] h-[120px] rounded-[10px] relative group cursor-pointer">
      {me.logoUrl ? (
        <img alt={t('Profile')} className="w-full rounded-[10px]" src={me.logoUrl} />
      ) : (
        <div className="h-full bg-ho-grey p-8 rounded-[10px]">
          <IconUser className="fill-current" />
        </div>
      )}
      <div className="hidden group-hover:block absolute right-1 top-1">
        <button className=" p-2 bg-ho-green rounded-full" onClick={() => inputRef.current.click()}>
          <IconEdit className="stroke-white" />
          <input
            ref={inputRef}
            accept="image/png, image/jpeg, image/gif, image/bmp, image/svg"
            className="hidden"
            onChange={onChange}
            type="file"
          />
        </button>
      </div>
    </div>
  );
}

export default ProfileAvatar;
