import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useInviteClassroomMembersMutation } from 'src/api/classrooms';
import InputMultipleEmails from 'src/components/ui-kit/InputMultipleEmails/InputMultipleEmails';
import useMultipleEmailsForm from 'src/components/ui-kit/InputMultipleEmails/useMultipleEmailsForm';
import Modal from 'src/components/ui-kit/Modal';
import { showErrorMessage, showSuccessMessage } from 'src/helpers/toast';
import { useModal } from 'src/helpers/useModal';

function InviteMembersModal(props) {
  const { classroom, isOpen, onClose, onSuccess } = props;

  const { t } = useTranslation();
  const { closeModal } = useModal();
  const { mutateAsync: inviteMembers } = useInviteClassroomMembersMutation();

  const { control, formState, handleSubmit } = useMultipleEmailsForm();

  const onSubmit = async (formData, event) => {
    event.preventDefault();
    try {
      await inviteMembers({
        body: {
          emails: formData.emails,
        },
        classroomId: classroom.id,
      });
      onSuccess();
      closeModal();
      showSuccessMessage(t('Members successfully invited'));
    } catch (e) {
      showErrorMessage(e.message);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      shouldShowCloseButton={false}
      title={t('Invite members')}
    >
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <label className="ml-2.5">{t('Emails')}</label>
        <Controller
          control={control}
          name="emails"
          render={({ field: { onChange } }) => (
            <InputMultipleEmails onChange={onChange} />
          )}
        />

        <div className="mt-6 flex items-center justify-between">
          <button
            className="button--secondary-outlined"
            onClick={onClose}
            type="button"
          >
            {t('Cancel')}
          </button>

          <button
            className="button--primary"
            disabled={!formState.isValid}
            type="submit"
          >
            {t('Invite')}
          </button>
        </div>
      </form>
    </Modal>
  );
}

InviteMembersModal.defaultProps = {
  onClose: null,
};

InviteMembersModal.propTypes = {
  classroom: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
};

export default InviteMembersModal;
