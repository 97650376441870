import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Divider from 'src/components/ui-kit/Divider';

import CourseVideo from './CourseVideo';

function CourseOverview(props) {
  const { course } = props;
  const { t } = useTranslation();

  return (
    <section className="CourseAbout ho-card p-6">
      <h4 className="text-center">{t('About the course')}</h4>

      <p
        className="mt-4 prose max-w-full"
        dangerouslySetInnerHTML={{ __html: course.description }}
      />

      <Divider className="my-8" />

      <div className="px-7">
        <CourseVideo course={course} />
      </div>
    </section>
  );
}

CourseOverview.propTypes = {
  course: PropTypes.object.isRequired,
};

export default CourseOverview;
