import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useUpdateProfileMutation } from 'src/api/account';
import Modal from 'src/components/ui-kit/Modal';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import { showErrorMessage, showSuccessMessage } from 'src/helpers/toast';
import { useModal } from 'src/helpers/useModal';

import ProfileForm from './ProfileForm';

function ProfileModal(props) {
  const { isOpen, onClose } = props;
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const { fetchMe, role } = useCurrentUser();

  const { mutateAsync: updateProfile } = useUpdateProfileMutation();

  const onSubmit = async (formData, event) => {
    event.preventDefault();

    try {
      await updateProfile({
        firstName: formData.firstName,
        lastName: formData.lastName,
        ...role === 'teacher' && {
          school: formData.school,
          title: formData.title,
        },
      });
      closeModal();
      await fetchMe();
      showSuccessMessage(t('Profile successfully updated'));
    } catch (e) {
      showErrorMessage(e.message);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('Edit profile')}
    >
      <ProfileForm onSubmit={onSubmit} />
    </Modal>
  );
}

ProfileModal.defaultProps = {
  onClose: null,
};

ProfileModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default ProfileModal;
