import React, { createContext, useMemo } from 'react';

import PropTypes from 'prop-types';
import { useParams, useSearchParams } from 'react-router-dom';

import { useCourse, useCourseNotes, transformNotesResponse } from 'src/api/courses';
import { useCurrentUser } from 'src/contexts/useCurrentUser';

export const CurrentLessonContext = createContext({
  course: null,
  isFetching: false,
  moduleStatusesMap: {},
  notes: [],
  questionnaireNotesMap: {},
  quizNotesMap: {},
});

export function CurrentLessonProvider(props) {
  const { children } = props;

  const { courseId, lessonId } = useParams();
  const { role } = useCurrentUser();
  const [searchParams] = useSearchParams();

  const { data: course, isFetching, refetch: fetchCourse } = useCourse({ courseId, role });
  const {
    data: notes,
    refetch: fetchNotes,
  } = useCourseNotes({ courseId, role, studentId: searchParams.get('studentId') });

  const moduleStatusesMap = useMemo(
    () => course?.courseTrackingModel?.moduleStatuses ?? {},
    [course],
  );
  const lesson = course ? course.lessons.find(({ id }) => lessonId === String(id)) : null;
  const { questionnaireNotesMap, quizNotesMap } = transformNotesResponse(notes);

  const context = useMemo(() => ({
    course,
    fetchCourse,
    fetchNotes,
    isFetching,
    lesson,
    moduleStatusesMap,
    notes,
    questionnaireNotesMap,
    quizNotesMap,
  }), [
    course, fetchCourse, fetchNotes, isFetching, lesson,
    moduleStatusesMap, notes, questionnaireNotesMap, quizNotesMap,
  ]);

  return (
    <CurrentLessonContext.Provider value={context}>
      {children}
    </CurrentLessonContext.Provider>
  );
}

CurrentLessonProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useCurrentLesson = () => React.useContext(CurrentLessonContext);
