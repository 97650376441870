import React from 'react';

import PropTypes from 'prop-types';
import StarRatingComponent from 'react-star-rating-component';

import colors from 'src/theme/colors';

function StarRating(props) {
  const { name, onChange, value } = props;
  return (
    <div className="StarRating">
      <StarRatingComponent
        emptyStarColor={colors['ho-grey-100']}
        name={name}
        onStarClick={onChange}
        starCount={5}
        value={value}
      />
      <style>
        {`
         .dv-star-rating-star {
           font-size: 24px;
           padding: 4px;
         }
        `}
      </style>
    </div>
  );
}

StarRating.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

export default StarRating;
