import React from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Page from 'src/components/layout/Page';
import { useCurrentLesson } from 'src/contexts/useCurrentLesson';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import ModuleDynamicContent from 'src/views/Study/LessonModule/ModuleDynamicContent';
import ModuleNavigation from 'src/views/Study/LessonModule/ModuleNavigation';
import ModuleTrackingProgress from 'src/views/Study/LessonModule/ModuleTrackingProgress';
import ModuleTrackingStatus from 'src/views/Study/LessonModule/ModuleTrackingStatus';

function LessonModule() {
  const { moduleId } = useParams();
  const { t } = useTranslation();
  const { role } = useCurrentUser();
  const { lesson } = useCurrentLesson();

  const moduleIndex = Number(moduleId) - 1;
  const module = lesson.modules[moduleIndex];

  return (
    <Page className="h-full grid gap-6" title={t(module.type)}>
      <section className="overflow-auto ho-card !bg-Grey p-6 grid gap-4">
        <ModuleDynamicContent module={module} />
      </section>

      <ModuleNavigation />

      <footer className="py-4 flex items-center justify-between gap-6">
        {role === 'student' && (
          <>
            <ModuleTrackingStatus module={module} />
            <ModuleTrackingProgress lesson={lesson} />
          </>
        )}
      </footer>
    </Page>
  );
}

export default LessonModule;
