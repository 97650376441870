import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// NOTE: Only single answers are supported
function QuizAnswers(props) {
  const { formState } = props;

  const { t } = useTranslation();

  return (
    <div className="QuizAnswers py-1">
      {formState.correct ? (
        <span className="text-ho-green italic">
          {t('Your answer is correct')}
        </span>
      ) : (
        <span className="text-ho-declined italic">
          {formState.completed ? t('Your answer is wrong') : t('Your answer is wrong. Try again')}
        </span>
      )}
    </div>
  );
}

QuizAnswers.propTypes = {
  formState: PropTypes.object.isRequired,
};

export default QuizAnswers;
