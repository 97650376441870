import React from 'react';

import i18n from 'i18next';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { useCourse } from 'src/api/courses';
import { useTeacherClassroomMemberStats, useTeacherClassroomStats } from 'src/api/stats';
import CourseCard from 'src/components/common/CourseCard';
import CourseLessonsProgressChart from 'src/components/common/CourseLessonsProgressChart';
import Loading from 'src/components/ui-kit/Loading';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import ClassroomStudentProgressTableView from 'src/views/Teacher/ClassroomStudent/ClassroomStudentProgressTableView';

function buildChartData(course, stats) {
  return course.lessons.map((lesson) => ({
    name: lesson.name,
    [i18n.t('progress')]: Math.floor(stats[lesson.id] * 100),
  }));
}

function ClassroomStudentStats(props) {
  const { classroom, studentName } = props;

  const { classroomId, studentId } = useParams();

  const { role } = useCurrentUser();
  const { data: course, isFetching: isFetchingCourse } = useCourse({
    courseId: classroom.courseId,
    role,
  });

  const {
    data: classroomStats = {},
    isFetching: isFetchingClassroomStats,
  } = useTeacherClassroomStats({ classroomId });

  const { data: studentStats = {}, isFetching: isFetchingStats } = useTeacherClassroomMemberStats({
    classroomId,
    studentId,
  });

  if (isFetchingCourse || isFetchingStats || isFetchingClassroomStats) {
    return (
      <Loading />
    );
  }

  const chartData = buildChartData(course, studentStats);

  return (
    <section className="ClassroomStudentStats grid gap-6 grid-cols-[395px_1fr]">
      <div className="grid gap-4">
        <CourseCard
          course={course}
          progress={Math.floor(classroomStats[studentId] * 100)}
          showProgress
        />

        <div className="ho-card p-4">
          <CourseLessonsProgressChart data={chartData} />
        </div>
      </div>

      <div>
        <ClassroomStudentProgressTableView
          course={course}
          stats={studentStats}
          studentName={studentName}
        />
      </div>
    </section>
  );
}

ClassroomStudentStats.propTypes = {
  classroom: PropTypes.object.isRequired,
  studentName: PropTypes.string.isRequired,
};

export default ClassroomStudentStats;
