import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactComponent as IconUser } from 'src/images/icons-deprecated/user.svg';

const sizes = ['sm', 'lg', 'responsive'];
const variants = ['primary', 'secondary', 'rectangular'];

function Avatar(props) {
  const { image, size, variant } = props;

  const { t } = useTranslation();

  return (
    <div className={classNames('Avatar flex items-center justify-center', {
      'h-10 w-10': size === 'sm',
      'h-24 w-24': size === 'lg',
      'text-MainYellow': variant === 'primary',
      'text-ho-grey-200': variant === 'secondary',
      'w-full h-full': size === 'responsive',
    })}
    >
      {image ? (
        <img
          alt={t('Avatar')}
          className={classNames('block h-full w-full ', {
            rounded: variant === 'rectangular',
            'rounded-full': variant !== 'rectangular',
          })}
          src={image}
        />
      ) : (
        <div className={classNames('Avatar__no-image h-full w-full flex items-center justify-center rounded-full border-2', {
          'border-MainYellow': variant === 'primary',
          'border-ho-grey-200': variant === 'secondary',
        })}
        >
          <div className={classNames({
            'h-5 w-5': size === 'sm',
            'h-8 w-8': size === 'lg',
          })}
          >
            <IconUser className="fill-current" />
          </div>
        </div>
      )}
    </div>
  );
}

Avatar.defaultProps = {
  image: '',
  variant: 'primary',
};

Avatar.propTypes = {
  image: PropTypes.string,
  size: PropTypes.oneOf(sizes).isRequired,
  variant: PropTypes.oneOf(variants),
};

export default Avatar;
