import { useInfiniteQuery } from 'react-query';

import { api } from 'src/api/api';
import { transformInfiniteQueryResult } from 'src/api/helpers';

export const NOTIFICATIONS_QUERY_NAME = 'NOTIFICATIONS_QUERY_NAME';

export function useNotifications({ size }) {
  const fetchNotifications = ({ pageParam = 0 }) => api.get('/account/notifications', {
    params: {
      page: pageParam,
      size,
    },
  }).then((response) => response.data);

  const result = useInfiniteQuery(NOTIFICATIONS_QUERY_NAME, fetchNotifications, {
    cacheTime: 0,
    getNextPageParam: (lastPage) => (lastPage.last ? undefined : lastPage.number + 1),
  });

  return transformInfiniteQueryResult(result);
}
