import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

function useMultipleEmailsForm() {
  const { t } = useTranslation();

  const initialFormData = {
    emails: [],
  };

  const validationResolver = yupResolver(yup.object({
    emails: yup
      .array()
      .test(
        'emailsAreRequired',
        t('At least one email is required'),
        (emails) => emails.length > 0,
      ),
  }).required());

  return useForm({
    criteriaMode: 'all',
    defaultValues: initialFormData,
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: validationResolver,
  });
}

export default useMultipleEmailsForm;
