import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { useStudentFeedback } from 'src/api/feedback';
import FeedbackModal from 'src/components/modals/FeedbackModal';
import ProgressBar from 'src/components/ui-kit/ProgressBar';
import { useCurrentLesson } from 'src/contexts/useCurrentLesson';
import { calculateProgress } from 'src/helpers/lessons';
import useFeedbackLocalStorage from 'src/helpers/useFeedbackLocalStorage';
import { useModal } from 'src/helpers/useModal';

function ModuleTrackingProgress(props) {
  const { lesson } = props;

  const { moduleStatusesMap } = useCurrentLesson();
  const { openModal } = useModal();
  const { courseId, lessonId } = useParams();
  const { hasProvidedFeedback, saveFeedback } = useFeedbackLocalStorage({ courseId, lessonId });
  const { data: feedback, isFetched } = useStudentFeedback(
    { courseId, lessonId },
    {
      enabled: !hasProvidedFeedback(),
      onError() {},
    },
  );

  const progress = calculateProgress(moduleStatusesMap, lesson).ratio;

  useEffect(() => {
    if (progress === 100 && isFetched && !feedback && !hasProvidedFeedback()) {
      openModal(FeedbackModal, {
        courseId,
        lessonId,
        onClose: saveFeedback,
        onSuccess: saveFeedback,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, feedback, progress]);

  return (
    <div className="ModuleTrackingProgress">
      <ProgressBar progress={progress} showText />
    </div>
  );
}

ModuleTrackingProgress.propTypes = {
  lesson: PropTypes.object.isRequired,
};

export default ModuleTrackingProgress;
