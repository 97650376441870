import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

function Divider(props) {
  const { className } = props;
  return (
    <div className={classNames('Divider h-px bg-DarkBlue opacity-10', className)} />
  );
}

Divider.propTypes = {
  className: PropTypes.string,
};

Divider.defaultProps = {
  className: '',
};

export default Divider;
