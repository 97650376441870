/* eslint-disable max-len, no-irregular-whitespace */
import React from 'react';

import { useTranslation } from 'react-i18next';

import Page from 'src/components/layout/Page';

// generated with https://wordtohtml.net/
function Terms() {
  const { t } = useTranslation();

  return (
    <Page className="Terms w-full" title={t('Terms')}>
      <section className="html prose max-w-full px-24 py-5">
        <div>
          <h3>HistoryOwls.com&nbsp;Website&nbsp;Terms of Use&nbsp;</h3>
        </div>
        <div>
          <b>Last revised&nbsp;July 2022&nbsp;</b>
        </div>
        <div>
          <p>Welcome to&nbsp;History Owls&rsquo; website and thank you for&nbsp;your interest in&nbsp;our products and services (&ldquo;Services&rdquo;). The Services are provided by&nbsp;History Owls, LLC,&nbsp;with a mailing address of&nbsp;[address]&nbsp;(&ldquo;History Owls,&rdquo; the &ldquo;Company,&rdquo; &ldquo;we&rdquo; or &ldquo;us&rdquo;).&nbsp;</p>
        </div>
        <div>
          <p>
            These Terms of Use (&ldquo;Agreement&rdquo;) govern your access and use of the&nbsp;
            <a href="http://www.historyowls.com/" rel="noreferrer noopener" target="_blank">www.HistoryOwls.com</a>
            {' '}
            website&nbsp;(the &ldquo;Website&rdquo;). BY ACCESSING OR USING THE SITE, YOU REPRESENT AND WARRANT THAT YOU ARE AT LEAST 18 YEARS OF AGE AND YOU AGREE TO BE BOUND BY&nbsp;THE TERMS OF&nbsp;THIS AGREEMENT. PLEASE READ THIS AGREEMENT CAREFULLY. IF YOU DO NOT WISH TO BE BOUND BY THIS AGREEMENT OR YOU ARE UNDER 18 YEARS OF AGE, YOU MAY NOT ACCESS OR USE THE SITE. IF YOU ARE ACCESSING OR USING THE&nbsp;WEBSITE ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT AND WARRANT THAT YOU HAVE THE AUTHORITY TO BIND SUCH ENTITY TO THIS AGREEMENT, IN WHICH CASE &ldquo;YOU&rdquo; OR &ldquo;YOUR&rdquo; WILL REFER TO BOTH YOU AND SUCH ENTITY.&nbsp;
          </p>
        </div>
        <div>
          <p>&nbsp;</p>
        </div>
      </section>
    </Page>
  );
}

export default Terms;
