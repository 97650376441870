import React from 'react';

import IconNotifications from 'src/components/common/IconNotifications';

function IconNotificationsFetcher() {
  const count = 0;

  return (
    <IconNotifications count={count} />
  );
}

export default IconNotificationsFetcher;
