import React from 'react';

import capitalize from 'lodash-es/capitalize';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Footer from 'src/components/common/Footer';
import Page from 'src/components/layout/Page';
import { ReactComponent as LoginSvg } from 'src/images/login.svg';
import { ReactComponent as Logo } from 'src/images/logo.svg';

import LoginWithEmailForm from './LoginWithEmailForm';

function Login(props) {
  const { userRole } = props;

  const { t } = useTranslation();

  return (
    <Page className="Login grid grid-cols-[523px_auto]" title={t('Login')}>
      <section className="hidden lg:block bg-Blue h-screen">
        <div className="p-7">
          <Logo />
        </div>
        <div>
          <LoginSvg />
        </div>
      </section>

      <section className="flex-1 flex flex-col items-center justify-center h-screen bg-LightBlue">
        <div className="flex-1 flex flex-col justify-center">
          <div>
            <h1>{t('{{userRole}} account', { userRole: capitalize(userRole) })}</h1>

            <div className="mt-16 grid gap-5">
              {/* <LoginGoogle userRole={userRole} /> */}

              <LoginWithEmailForm userRole={userRole} />
            </div>
          </div>
        </div>
        <Footer variant="light" />
      </section>
    </Page>
  );
}

Login.propTypes = {
  userRole: PropTypes.oneOf(['teacher', 'student']).isRequired,
};

export default Login;
