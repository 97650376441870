import React, { useLayoutEffect } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useCurrentUser } from 'src/contexts/useCurrentUser';
import useValidationHelpers from 'src/helpers/useValidationHelpers';

import useProfileForm from './useProfileForm';

function ProfileForm(props) {
  const { onSubmit } = props;

  const { t } = useTranslation();
  const { me, role } = useCurrentUser();

  const { formState, handleSubmit, register, setFocus } = useProfileForm({ me });
  const { getFieldError } = useValidationHelpers({ formState });

  useLayoutEffect(() => {
    setTimeout(() => setFocus('firstName'));
  }, [setFocus]);

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="grid">
        <label className="label" htmlFor="ProfileForm__firstName">
          {t('First Name')}
          <span className="label-star"> *</span>
        </label>
        <input
          className={classNames('input mt-2', { 'input--has-error': getFieldError('firstName') })}
          id="ProfileForm__firstName"
          name="firstName"
          {...register('firstName')}
        />
        <p className="error-message mt-1">{getFieldError('firstName')}</p>
      </div>

      <div className="grid mt-5">
        <label className="label" htmlFor="ProfileForm__lastName">
          {t('Last Name')}
          <span className="label-star"> *</span>
        </label>
        <input
          autoComplete="off"
          className={classNames('input mt-2', { 'input--has-error': getFieldError('lastName') })}
          id="ProfileForm__lastName"
          name="lastName"
          {...register('lastName')}
        />
        <p className="error-message mt-1">{getFieldError('lastName')}</p>
      </div>

      {role === 'teacher' && (
      <>
        <div className="grid mt-5">
          <label className="label" htmlFor="ProfileForm__title">
            {t('Title')}
            <span className="label-star"> *</span>
          </label>
          <input
            autoComplete="off"
            className={classNames('input mt-2', { 'input--has-error': getFieldError('title') })}
            id="ProfileForm__title"
            name="title"
            {...register('title')}
          />
          <p className="error-message mt-1">{getFieldError('title')}</p>
        </div>

        <div className="grid mt-5">
          <label className="label" htmlFor="ProfileForm__school">
            {t('School')}
            <span className="label-star"> *</span>
          </label>
          <input
            autoComplete="off"
            className={classNames('input mt-2', { 'input--has-error': getFieldError('school') })}
            id="ProfileForm__school"
            name="school"
            {...register('school')}
          />
          <p className="error-message mt-1">{getFieldError('school')}</p>
        </div>
      </>
      )}

      <div className="mt-10">
        <button
          className={classNames('button--primary w-full', { 'visually-disabled': !formState.isValid })}
          type="submit"
        >
          {t('Save')}
        </button>
      </div>
    </form>
  );
}

ProfileForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ProfileForm;
