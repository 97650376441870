import React, { useState, useEffect } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useAddCourseQuizzesAnswersMutation } from 'src/api/courses';
import { useCurrentLesson } from 'src/contexts/useCurrentLesson';
import { buildForm, getQuizFormStates } from 'src/helpers/quiz';
import { showErrorMessage } from 'src/helpers/toast';
import QuizFieldset from 'src/views/Study/LessonModule/contentItems/QUIZ/QuizFieldset';

function QUIZ(props) {
  const { className, contentItem } = props;

  const { course, fetchCourse, isFetching: isFetchingCourse } = useCurrentLesson();
  const [formState, setFormState] = useState(buildForm(contentItem.quizzes, course));
  const { t } = useTranslation();
  const {
    isLoading: isSavingAnswers,
    mutateAsync: saveQuizAnswers,
  } = useAddCourseQuizzesAnswersMutation();

  useEffect(() => {
    setFormState(buildForm(contentItem.quizzes, course));
  }, [contentItem, course]);

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      await Promise.all(
        Object.values(formState).map((value) => saveQuizAnswers({
          body: {
            quizAnswerIds: value.quizAnswerIds,
          },
          courseId: course.id,
          quizId: value.quizId,
        })),
      );
      await fetchCourse();
    } catch (e) {
      showErrorMessage(e.message);
    }
  };

  const onFormStateChange = (_formState) => {
    setFormState((prevState) => ({
      ...prevState,
      [_formState.quizId]: _formState,
    }));
  };

  const { isAnswered, isCompleted } = getQuizFormStates(formState);

  const loading = isFetchingCourse || isSavingAnswers;

  // 2 attempts hardcoded
  const getSubmitButtonText = () => {
    if (loading) {
      return t('Loading...');
    }
    if (isAnswered) {
      return t('Submit ({{count}} / 2 Attempts Remaining)', { count: 1 });
    }
    return t('Submit ({{count}} / 2 Attempts Remaining)', { count: 2 });
  };

  return (
    <div className={classNames('Quiz', className)}>
      <h4>{contentItem.name}</h4>

      <form className="mt-6 grid gap-4" noValidate onSubmit={onSubmit}>
        {contentItem.quizzes.map((quiz, index) => (
          <QuizFieldset
            key={index}
            formState={formState[quiz.id]}
            index={index}
            loading={loading}
            onFormStateChange={onFormStateChange}
            quiz={quiz}
          />
        ))}

        {!isCompleted && (
          <button
            className="button--primary inline-block max-w-[360px]"
            disabled={!isAnswered || loading}
            type="submit"
          >
            {getSubmitButtonText()}
          </button>
        )}
      </form>
    </div>
  );
}

QUIZ.defaultProps = {
  className: '',
};

QUIZ.propTypes = {
  className: PropTypes.string,
  contentItem: PropTypes.object.isRequired,
};

export default QUIZ;
