import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

import { firebaseConfig } from 'src/config';

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const firebaseAuth = getAuth(app);

export const googleProvider = new GoogleAuthProvider();
googleProvider.addScope('email');

export const signInWithGoogle = () => signInWithPopup(firebaseAuth, googleProvider);
