import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useDeleteClassroomMembersMutation } from 'src/api/classrooms';
import Modal from 'src/components/ui-kit/Modal';
import { showErrorMessage, showSuccessMessage } from 'src/helpers/toast';

function DeleteMemberModal(props) {
  const { classroom, isOpen, member, onClose, onSuccess } = props;

  const { t } = useTranslation();

  const { mutateAsync: deleteMember } = useDeleteClassroomMembersMutation();

  const onDeleteMember = async () => {
    try {
      await deleteMember({ classroomId: classroom.id, memberId: member.id });
      showSuccessMessage('Student successfully deleted');
      onSuccess();
    } catch (e) {
      showErrorMessage(e.message);
    }
  };
  const handleDelete = () => onDeleteMember({ classroom, onSuccess });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      shouldShowCloseButton={false}
      size="sm"
      title={t('Delete student')}
    >
      <div className="-mt-10">
        <p className="mt-2">{t('Are you sure you want to delete the student?')}</p>
        <div className="mt-6 flex items-center justify-between">
          <button
            className="button--secondary-outlined"
            onClick={onClose}
            type="button"
          >
            {t('Cancel')}
          </button>

          <button
            className="button--primary"
            onClick={handleDelete}
            type="submit"
          >
            {t('Delete')}
          </button>
        </div>
      </div>
    </Modal>
  );
}

DeleteMemberModal.propTypes = {
  classroom: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  member: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default DeleteMemberModal;
