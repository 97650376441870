import { useMutation } from 'react-query';

import { api } from 'src/api/api';

export const statuses = {
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN_PROGRESS',
  NOT_STARTED: 'NOT_STARTED',
};

export const useCreateModuleTrackingMutation = () => useMutation(({ body }) => api.post('/student/tracking/modules', body));
