import React from 'react';

import { useTranslation } from 'react-i18next';

import { useCurrentUser } from 'src/contexts/useCurrentUser';
import WelcomeImageSrc from 'src/images/welcome.png';

function WelcomeBanner() {
  const { me } = useCurrentUser();

  const { t } = useTranslation();

  return (
    <section className="ho-card flex justify-between items-center py-2 px-10">
      <h2 className="py-6 text-ho-blue-600">
        {
          me.firstName && me.lastName
            ? t('Welcome, {{name}}!', { name: `${me.firstName} ${me.lastName}` })
            : t('Welcome!')
        }
      </h2>

      <img alt={t('Welcome!')} className="h-36" src={WelcomeImageSrc} />
    </section>
  );
}

export default WelcomeBanner;
