import React, { useLayoutEffect, useState } from 'react';

import classNames from 'classnames';
import { createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword } from 'firebase/auth';
import upperCase from 'lodash-es/upperCase';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

import { useUpdateProfileMutation } from 'src/api/account';
import { setToken } from 'src/api/api';
import { useFederatedSetupMutation } from 'src/api/auth';
import { firebaseAuth } from 'src/api/firebase';
import { PRIVACY_URL, TERMS_URL } from 'src/config';
import { getUserDefaultRoute, useCurrentUser } from 'src/contexts/useCurrentUser';
import { showErrorMessage } from 'src/helpers/toast';
import useValidationHelpers from 'src/helpers/useValidationHelpers';

import useSignupForm from './useSignupForm';

function SignupForm(props) {
  const { userRole } = props;

  const [showEmailVerificationView, setShowEmailVerificationView] = useState(false);
  const [firebaseUser, setFirebaseUser] = useState(null);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutateAsync: createFederatedSetup } = useFederatedSetupMutation();
  const { formState, handleSubmit, register, setFocus, watch } = useSignupForm({ userRole });
  const { fetchMe } = useCurrentUser();
  const { getFieldError } = useValidationHelpers({ formState });
  const { mutateAsync: updateProfile } = useUpdateProfileMutation();

  const formData = watch();

  useLayoutEffect(() => {
    setFocus('firstName');
  }, [setFocus]);

  const onSubmit = async (_formData, event) => {
    event.preventDefault();

    try {
      // Create firebase user
      const { user } = await createUserWithEmailAndPassword(
        firebaseAuth,
        _formData.email,
        _formData.password,
      );
      setFirebaseUser(user);
      setShowEmailVerificationView(true);

      await sendEmailVerification(user);
    } catch (e) {
      showErrorMessage(e.message);
    }
  };

  const onContinue = async () => {
    try {
      // Use the token to create federated setup
      const idToken = await firebaseUser.getIdToken(true);
      await createFederatedSetup({
        idToken,
        role: upperCase(userRole),
      });

      // If successful, login that user and set a token
      const response = await signInWithEmailAndPassword(
        firebaseAuth,
        formData.email,
        formData.password,
      );
      setToken(response.user.accessToken);

      // If successful update the user immediately with additional fields
      await updateProfile({
        firstName: formData.firstName,
        lastName: formData.lastName,
        ...userRole === 'teacher' && {
          school: formData.school,
          title: formData.title,
        },
      });

      const { data: me } = await fetchMe();

      const loggedInUserDefaultRoute = getUserDefaultRoute(me);
      // Redirect to the default user's route
      navigate(loggedInUserDefaultRoute);
    } catch (e) {
      showErrorMessage(e?.response?.data?.message ?? e.message);
    }
  };

  if (showEmailVerificationView) {
    return (
      <div className="w-[560px] grid justify-center">
        <p className="text-ho-grey-300">{t('Check your email for verification. Click "Continue" when that is done to proceed finishing setting up your account')}</p>

        <div className="mt-10">
          <button className="button--secondary" onClick={onContinue}>
            {t('Continue')}
          </button>
        </div>
      </div>
    );
  }

  return (
    <form
      className="SignupForm w-[560px] grid ho-card p-6 gap-6"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >

      <div>
        <h4>{t('Sign up')}</h4>
        <p className="mt-1 text-xs">{t('Please, sign up below')}</p>
      </div>

      <div className="grid grid-cols-2 gap-6">
        <section className="grid gap-1">
          <input
            className={classNames('input', { 'input--has-error': getFieldError('firstName') })}
            id="SignupForm__firstName"
            name="firstName"
            placeholder={t('First Name*')}
            {...register('firstName')}
          />
          <p className="error-message mt-1">{getFieldError('firstName')}</p>
        </section>

        <section className="grid gap-1">
          <input
            className={classNames('input', { 'input--has-error': getFieldError('lastName') })}
            id="SignupForm__lastName"
            name="lastName"
            placeholder={t('Last Name*')}
            {...register('lastName')}
          />
          <p className="error-message mt-1">{getFieldError('lastName')}</p>
        </section>
      </div>

      {userRole === 'teacher' && (
      <div className="grid grid-cols-2 gap-6">
        <section className="grid gap-1">
          <input
            className={classNames('input', { 'input--has-error': getFieldError('title') })}
            id="SignupForm__title"
            name="title"
            placeholder={t('Title')}
            {...register('title')}
          />
          <p className="error-message mt-1">{getFieldError('title')}</p>
        </section>

        <section className="grid gap-1">
          <input
            className={classNames('input', { 'input--has-error': getFieldError('school') })}
            id="SignupForm__school"
            name="lastName"
            placeholder={t('School')}
            {...register('school')}
          />
          <p className="error-message mt-1">{getFieldError('school')}</p>
        </section>
      </div>
      )}

      <section className="grid gap-1">
        <input
          className={classNames('input', { 'input--has-error': getFieldError('email') })}
          id="SignupForm__email"
          name="email"
          placeholder={t('Email*')}
          {...register('email')}
        />
        <p className="error-message mt-1">{getFieldError('email')}</p>
      </section>

      <section className="grid gap-1">
        <input
          autoComplete="off"
          className={classNames('input', { 'input--has-error': getFieldError('password') })}
          id="SignupForm__password"
          name="password"
          placeholder={t('Password*')}
          type="password"
          {...register('password')}
        />
        <p className="error-message mt-1">{getFieldError('password')}</p>
      </section>

      <section className="grid gap-1">
        <input
          autoComplete="off"
          className={classNames('input', { 'input--has-error': getFieldError('confirmPassword') })}
          id="SignupForm__confirm-password"
          name="password"
          placeholder={t('Confirm Password*')}
          type="password"
          {...register('confirmPassword')}
        />
        <p className="error-message mt-1">{getFieldError('confirmPassword')}</p>
      </section>

      <section className="flex gap-3 items-center">
        <input id="SignupForm__agree-to-services" type="checkbox" {...register('agreeToServices')} />
        <label className="text-sm text-center" htmlFor="SignupForm__agree-to-services">
          <Trans
            components={[
              (
                <a
                  className="ho-link"
                  href={TERMS_URL}
                  rel="noreferrer"
                  target="_blank"
                >
                  {t('Terms')}
                </a>
              ),
              (
                <a
                  className="ho-link"
                  href={PRIVACY_URL}
                  rel="noreferrer"
                  target="_blank"
                >
                  {t('Privacy')}
                </a>),
            ]}
            i18nKey="I have read and agree to the <0>terms of use</0> and <1>privacy policy</1>"
            t={t}
          />
        </label>
      </section>

      <section>
        <div className="mt-4 flex justify-between items-center mt-10">
          <div>
            <p className="mt-1 text-xs">{t('Already have an account?')}</p>
            <NavLink className="underline mt-1 text-Blue" to={`/${userRole}/login`}>
              {t('Login')}
            </NavLink>
          </div>

          <button
            className={classNames('button--primary', { 'visually-disabled': !formState.isValid })}
            type="submit"
          >
            {t('Sign up')}
          </button>
        </div>
      </section>
    </form>
  );
}

SignupForm.propTypes = {
  userRole: PropTypes.oneOf(['teacher', 'student']).isRequired,
};

export default SignupForm;
