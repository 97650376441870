import React, { useState, useCallback } from 'react';

import PropTypes from 'prop-types';
import ReactImageViewer from 'react-simple-image-viewer';

// Inspired by https://codesandbox.io/s/react-simple-image-viewer-demo-4itlr?file=/src/index.js:163-274
function ImageViewer(props) {
  const { altText, src, title } = props;

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const images = [
    src,
  ];

  return (
    <div className="ImageViewer">
      <button
        className="button--text"
        onClick={() => openImageViewer(0)}
      >
        <img
          alt={altText}
          src={src}
          title={title}
        />
      </button>
      {isViewerOpen && (
        <ReactImageViewer
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          closeOnClickOutside
          currentIndex={currentImage}
          disableScroll={false}
          onClose={closeImageViewer}
          src={images}
        />
      )}
    </div>
  );
}

ImageViewer.defaultProps = {
  altText: '',
  title: '',
};

ImageViewer.propTypes = {
  altText: PropTypes.string,
  src: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default ImageViewer;
