import React from 'react';

import { useTranslation } from 'react-i18next';

import { useNotifications } from 'src/api/notifications';
import Page from 'src/components/layout/Page';
import DataGrid from 'src/components/ui-kit/DataGrid';
import usePagination from 'src/helpers/usePagination';
import Notification from 'src/views/Notifications/Notification';

function Notifications() {
  const { t } = useTranslation();

  const {
    fetchNextPage,
    hasNextPage,
    isFetched,
    isFetching,
    items: notifications,
  } = useNotifications({
    size: 20,
  });

  const { onLoadMore } = usePagination({ fetchNextPage });

  return (
    <Page title={t('Notifications')}>
      <h1 className="mt-1">{t('Notifications')}</h1>

      <DataGrid
        emptyText={t('You currently have no notifications')}
        hasNextPage={hasNextPage}
        isEmpty={isFetched && notifications.length === 0}
        isFetching={isFetching}
        onLoadMore={onLoadMore}
      >
        <div className="mt-6 grid gap-4">
          {notifications.map((notification) => (
            <Notification key={notification.id} data={notification} />
          ))}
        </div>
      </DataGrid>
    </Page>
  );
}

export default Notifications;
