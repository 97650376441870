import React from 'react';

import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import StudentFeedbacksLessonChart
  from 'src/views/Teacher/Dashboard/GridItems/ClassPerformanceSection/StudentFeedbacksLessonChart';
import { useDashboard } from 'src/views/Teacher/Dashboard/useDashboard';

function buildChartData(stats) {
  const { engagement, rigor } = stats;
  return Object.keys(engagement).map((id) => ({
    id,
    name: engagement[id].name,
    [i18n.t('engagement')]: Number(engagement[id].value.toFixed(2)),
    [i18n.t('rigor')]: Number(rigor[id].value.toFixed(2)),
  }));
}

function ClassPerformanceSection() {
  const { t } = useTranslation();

  const { studentFeedbackStats } = useDashboard();

  const chartData = buildChartData(studentFeedbackStats);

  return (
    <section className="ClassCourseSection ho-card grid gap-4 p-4">
      <h4>{t('Class performance')}</h4>

      <StudentFeedbacksLessonChart data={chartData} />
    </section>
  );
}

export default ClassPerformanceSection;
