import { useMutation, useQuery } from 'react-query';

import { api } from 'src/api/api';

export const ME_QUERY_NAME = 'ME_QUERY_NAME';

export function useMe(options) {
  const fetcher = () => api.get('/account').then((response) => response.data);

  return useQuery(ME_QUERY_NAME, fetcher, options);
}

const updateLogo = (blob) => {
  const body = new FormData();
  body.append('file', blob);
  return api.post('/account/logo', body);
};

export function useAccountLogoMutation() {
  return useMutation(updateLogo);
}

export function useUpdateProfileMutation() {
  return useMutation((body) => api.put('/account', body));
}
