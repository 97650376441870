import React from 'react';

import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Avatar from 'src/components/common/Avatar';

function CommentItem(props) {
  const { data: { commentModel: comment, userModel: user } } = props;

  const { t } = useTranslation();

  return (
    <div className="CommentItem ho-card p-6 grid gap-2">
      <div className="grid grid-cols-[max-content_auto_max-content] items-center gap-2">
        <Avatar
          image={user ? user.logoUrl : null}
          size="sm"
          variant="secondary"
        />

        {user ? (
          <span className="font-bold">{`${user.firstName} ${user.lastName}`}</span>
        ) : (
          <span className="font-bold text-ho-grey-300">{t('Deleted user')}</span>
        )}

        <time>
          {format(new Date(comment.eventTime * 1000), 'PPpp')}
        </time>
      </div>

      <p className="text-sm">{comment.value}</p>
    </div>
  );
}

CommentItem.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CommentItem;
