import React from 'react';

import { QueryClientProvider } from 'react-query';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { queryClient } from 'src/api/api';
import Loading from 'src/components/ui-kit/Loading';
import { CurrentUserProvider, useCurrentUser } from 'src/contexts/useCurrentUser';
import { ModalContainerProvider, useModal } from 'src/helpers/useModal';
import ChooseAccount from 'src/views/ChooseAccount';
import Login from 'src/views/Login/Login';
import LoginWithEmail from 'src/views/LoginWithEmail/LoginWithEmail';
import NotFound from 'src/views/NotFound/NotFound';
import Privacy from 'src/views/Privacy';
import Signup from 'src/views/Signup/Signup';
import Student from 'src/views/Student/Student';
import Study from 'src/views/Study/Study';
import { DashboardProvider } from 'src/views/Teacher/Dashboard/useDashboard';
import Teacher from 'src/views/Teacher/Teacher';
import Terms from 'src/views/Terms';
import 'react-toastify/dist/ReactToastify.css';
import 'cropperjs/dist/cropper.css';

function AppContent() {
  const { isLoading, me, role } = useCurrentUser();
  const { ModalComponent } = useModal();

  return (
    <div className="App">
      {ModalComponent}
      {isLoading ? (
        <Loading />
      ) : (
        <Routes>
          <Route element={<ChooseAccount />} path="/welcome" />
          <Route element={<Login userRole="teacher" />} path="/teacher/login" />
          <Route element={<Login userRole="student" />} path="/student/login" />
          <Route element={<Signup userRole="teacher" />} path="/teacher/signup" />
          <Route element={<Signup userRole="student" />} path="/student/signup" />
          <Route element={<LoginWithEmail userRole="student" />} path="/student/login/email" />
          <Route element={<LoginWithEmail userRole="teacher" />} path="/teacher/login/email" />
          {me && role === 'student' && (
            <Route element={<Student />} path="/student/*" />
          )}
          {me && role === 'teacher' && (
          <Route
            element={(
              <DashboardProvider>
                <Teacher />
              </DashboardProvider>
              )}
            path="/teacher/*"
          />
          )}
          <Route element={<Study />} path="/study/*" />
          <Route
            element={<Navigate replace to="/welcome" />}
            path="/"
          />
          <Route element={<Privacy />} path="/privacy" />
          <Route element={<Terms />} path="/terms" />
          {me && (
          <Route element={<NotFound />} path="*" />
          )}
        </Routes>
      )}
    </div>
  );
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <CurrentUserProvider>
        <ModalContainerProvider>
          <ToastContainer style={{ width: 'auto' }} />
          <AppContent />
        </ModalContainerProvider>
      </CurrentUserProvider>
    </QueryClientProvider>
  );
}

export default App;
