import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function ClassroomLaunchStatus(props) {
  const { launchStatus } = props;

  const { t } = useTranslation();

  return (
    <div className="ClassroomLaunchStatus flex gap-2 items-center">
      <div className={classNames('h-3.5 w-3.5 rounded-full', {
        'bg-Green': launchStatus === 'STARTED',
        'bg-MainYellow': launchStatus === 'PRE_LAUNCH',
        'bg-Red': launchStatus === 'NOT_STARTED',
      })}
      />
      {t(launchStatus)}
    </div>
  );
}

ClassroomLaunchStatus.propTypes = {
  launchStatus: PropTypes.oneOf(['NOT_STARTED', 'STARTED', 'PRE_LAUNCH']).isRequired,
};

export default ClassroomLaunchStatus;
