import { useParams } from 'react-router-dom';

import { useClassroom, useLaunchClassroomMutation, usePreLaunchClassroomMutation } from 'src/api/classrooms';
import ClassroomModal from 'src/components/modals/ClassroomModal/ClassroomModal';
import DeleteClassroomModal from 'src/components/modals/DeleteClassroomModal/DeleteClassroomModal';
import { showErrorMessage } from 'src/helpers/toast';
import { useModal } from 'src/helpers/useModal';

function useClassroomState() {
  const { closeModal, openModal } = useModal();

  const { classroomId } = useParams();
  const { data: classroom, isFetching, refetch } = useClassroom(classroomId);

  const onSuccess = async () => {
    await refetch();
    closeModal();
  };

  const handleDelete = async () => openModal(DeleteClassroomModal, {
    classroom,
    onClose: closeModal,
    onSuccess,
  });

  // TODO: Classrooms list doesn't refetch
  const { mutateAsync: _launchClassroom } = useLaunchClassroomMutation();
  const { mutateAsync: _preLaunchClassroom } = usePreLaunchClassroomMutation();

  const launchClassroom = async () => {
    try {
      await _launchClassroom(classroom.id);
      await onSuccess();
    } catch (e) {
      showErrorMessage(e.message);
    }
  };

  const preLaunchClassroom = async () => {
    try {
      await _preLaunchClassroom(classroom.id);
      await onSuccess();
    } catch (e) {
      showErrorMessage(e.message);
    }
  };

  const handleEdit = () => openModal(ClassroomModal, {
    classroom,
    onClose: closeModal,
    onSuccess: async () => {
      await refetch();
      closeModal();
    },
  });

  const actions = {
    handleDelete,
    handleEdit,
    launchClassroom,
    preLaunchClassroom,
  };

  return {
    actions,
    classroom,
    handleDelete,
    handleEdit,
    isFetching,
    refetch,
  };
}

export default useClassroomState;
