import { useInfiniteQuery, useMutation, useQuery } from 'react-query';

import { api } from 'src/api/api';
import { transformInfiniteQueryResult } from 'src/api/helpers';

export const COURSE_QUERY_NAME = 'COURSE_QUERY_NAME';

export const COURSES_QUERY_NAME = 'COURSES_QUERY_NAME';
export const COURSES_QUERY_NAME_CHECK = 'COURSES_QUERY_NAME_CHECK';

export const COURSE_NOTES_QUERY_NAME = 'COURSE_NOTES_QUERY_NAME';

export const statuses = {
  AVAILABLE_ONLY: 'AVAILABLE_ONLY',
  PURCHASED_ONLY: 'PURCHASED_ONLY',
};

export const useCourse = ({ courseId, role }) => useQuery(
  [COURSE_QUERY_NAME, courseId],
  () => api.get(`/${role}/courses/${courseId}`).then((response) => response.data),
  { cacheTime: 0 },
);

export function useCourses({ checkMode = false, role, size, status }, options = {}) {
  const fetchCourses = ({ pageParam = 0 }) => api.get(`/${role}/courses`, {
    params: {
      page: pageParam,
      size,
      ...status && { status },
    },
  }).then((response) => response.data);

  const result = useInfiniteQuery(
    checkMode ? COURSES_QUERY_NAME_CHECK : COURSES_QUERY_NAME,
    fetchCourses,
    {
      ...options,
      cacheTime: 0,
      getNextPageParam: (lastPage) => (lastPage.last ? undefined : lastPage.number + 1),
    },
  );

  return transformInfiniteQueryResult(result);
}

export const redeemCode = ({ body, courseId }) => api.post(`/teacher/courses/${courseId}/redeem_code`, body);

export const useRedeemCodeMutation = () => useMutation(redeemCode);

export const transformNotesResponse = (notes) => {
  if (!notes) {
    return { questionnaireNotesMap: {}, quizNotesMap: {} };
  }

  const questionnaireNotesMap = notes.questionnaireNotes.reduce((map, note) => ({
    ...map,
    [note.questionnaireId]: note,
  }), {});
  const quizNotesMap = notes.quizNotes.reduce((map, note) => ({
    ...map,
    [note.quizId]: note,
  }), {});
  return {
    questionnaireNotesMap,
    quizNotesMap,
  };
};

export const useCourseNotes = ({ courseId, role, studentId }) => {
  const isTeacherViewingStudentResponses = role === 'teacher' && !!studentId;

  return useQuery(
    {
      cacheTime: 0,
      enabled: role === 'student' || isTeacherViewingStudentResponses,
      queryFn: () => api.get(
        `/student/courses/${courseId}/notes`,
        {
          headers: {
            ...isTeacherViewingStudentResponses && {
              'X-HO-STUDENT-ID': studentId,
            },
          },
        },
      ).then((response) => response.data),
      queryKey: [COURSE_NOTES_QUERY_NAME, courseId],
    },
  );
};

export const useCreateCourseNoteMutation = () => useMutation(({ body, courseId }) => api.post(`/student/courses/${courseId}/notes`, body));

export const useAddCourseQuizzesAnswersMutation = () => useMutation(
  ({ body, courseId, quizId }) => api.post(`/student/courses/${courseId}/quizzes/${quizId}/answers`, body),
);

export const useHideLessonMutation = () => useMutation(({ courseId, lessonId }) => api.post(`/teacher/courses/${courseId}/lessons/${lessonId}/hide`));

export const useShowLessonMutation = () => useMutation(({ courseId, lessonId }) => api.post(`/teacher/courses/${courseId}/lessons/${lessonId}/show`));
