import React, { useRef, useState } from 'react';

import { signOut } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { Popover } from 'react-tiny-popover';

import { clearAuthData } from 'src/api/api';
import { firebaseAuth } from 'src/api/firebase';
import Avatar from 'src/components/common/Avatar';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import { isFeatureEnabled, FEATURE_FLAGS } from 'src/helpers/featureFlags';
import { ReactComponent as IconEllipsisVertical } from 'src/images/icons/ellipsis-vertical.svg';

function CurrentUserDropdown() {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const triggerRef = useRef();
  const { loading, me, role } = useCurrentUser();
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (loading || !me) {
    return '';
  }

  const onLogout = async () => {
    await signOut(firebaseAuth);
    clearAuthData();
    navigate('/welcome');
  };

  const name = me.firstName && me.lastName
    ? `${me.firstName} ${me.lastName}`
    : t('My Account');

  const getContent = ({ childRect }) => (
    <div
      className="CurrentUserWidget__popover ho-card px-5 py-4"
      onClick={() => setIsPopoverOpen(false)}
      onKeyDown={() => {}}
      role="button"
      style={{
        width: `${childRect.width}px`,
      }}
      tabIndex="0"
    >
      <nav>
        <ul>
          <li>
            <NavLink className="block py-2 hover:text-MainYellow" to={`/${role}/profile`}>
              {t('My Profile')}
            </NavLink>
          </li>
          {isFeatureEnabled(FEATURE_FLAGS.MANAGE_CLASSES) && (
            <li>
              <NavLink
                className="block py-2 hover:text-MainYellow"
                to="/teacher/manage-classes"
              >
                {t('Manage classes')}
              </NavLink>
            </li>
          )}
          <li>
            <div className="h-px bg-ho-grey-200 my-2" />
          </li>
          <li>
            <button
              className="w-full text-left button--text py-2 hover:text-MainYellow"
              onClick={onLogout}
            >
              {t('Log out')}
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );

  return (
    <Popover
      align="start"
      boundaryElement={triggerRef.current}
      containerStyle={{ zIndex: 100 }}
      content={getContent}
      isOpen={isPopoverOpen}
      onClickOutside={() => setIsPopoverOpen(false)}
      padding={10}
      positions={['bottom']}
      reposition
    >
      <button
        className="CurrentUserWidget__trigger"
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
      >
        <div className="flex items-center gap-2 text-white">
          <Avatar image={me.logoUrl} size="sm" />
          <span className="truncate text-sm" title={name}>
            {name}
          </span>
          <IconEllipsisVertical />
        </div>
      </button>
    </Popover>
  );
}

export default CurrentUserDropdown;
