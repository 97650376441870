export const publicRoutes = [
  '/welcome',
  '/terms',
  '/privacy',
  '/student/login',
  '/teacher/login',
  '/student/login/email',
  '/teacher/login/email',
  '/student/signup',
  '/teacher/signup',
];

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  ...process.env.REACT_APP_MEASUREMENT_ID && {
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  },
};

export const SUPPORT_URL = 'https://historyowls.tawk.help/';

export const TERMS_URL = '/terms';

export const PRIVACY_URL = '/privacy';
