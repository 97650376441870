import React from 'react';

import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { ReactComponent as IconDelete } from 'src/images/icons/delete.svg';
import { ReactComponent as IconEye } from 'src/images/icons/eye.svg';

function ClassroomMembersRowActions(props) {
  const { classroom, member, onClickDeleteButton } = props;

  return (
    <div className="ClassroomMembersRowActions text-DarkBlue/40 hover:text-DarkBlue/80 flex items-center justify-center gap-4">
      <button onClick={() => onClickDeleteButton(member)}>
        <IconDelete />
      </button>

      <NavLink to={`/teacher/classrooms/${classroom.id}/students/${member.userId}`}>
        <IconEye className="w-6" />
      </NavLink>
    </div>
  );
}

ClassroomMembersRowActions.propTypes = {
  classroom: PropTypes.object.isRequired,
  member: PropTypes.object.isRequired,
  onClickDeleteButton: PropTypes.func.isRequired,
};

export default ClassroomMembersRowActions;
