import isEmpty from 'lodash-es/isEmpty';
import { useMutation, useQuery } from 'react-query';

import { api } from 'src/api/api';

const STUDENT_FEEDBACK_QUERY_NAME = 'STUDENT_FEEDBACK_QUERY_NAME';

export const useStudentFeedback = ({ courseId, lessonId }, options = {}) => useQuery({
  queryFn: () => api
    .get(`/student/courses/${courseId}/lessons/${lessonId}/feedback`)
    .then((response) => {
      const { feedback } = response.data;
      if (isEmpty(feedback)) {
        return null;
      }
      return feedback;
    }),
  queryKey: [STUDENT_FEEDBACK_QUERY_NAME, courseId, lessonId],
  ...options,
});

export const useStudentFeedbackMutation = ({ courseId, lessonId }) => useMutation(
  ({ body }) => api.post(`/student/courses/${courseId}/lessons/${lessonId}/feedback`, body),
);
