import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import colors from 'src/theme/colors';

const toPercent = (value) => `${value}%`;

function CourseLessonsProgressChart(props) {
  const { data } = props;

  const { t } = useTranslation();

  return (
    <div className="CourseLessonsProgressChart max-w-[800px]">
      <ResponsiveContainer
        height={300}
        width="100%"
      >
        <BarChart
          data={data}
        >
          <XAxis dataKey="name" />
          <YAxis
            domain={[0, 100]}
            tickFormatter={toPercent}
            ticks={[
              10, 20, 30, 40, 50, 60, 70, 80, 90, 100,
            ]}
          />
          <Tooltip formatter={toPercent} />
          <Legend
            iconType="circle"
            verticalAlign="top"
          />

          <Bar dataKey={t('progress')} fill={colors['ho-blue-300']} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

CourseLessonsProgressChart.propTypes = {
  data: PropTypes.array.isRequired,
};

export default CourseLessonsProgressChart;
