import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// import Footer from 'src/components/common/Footer';
import Page from 'src/components/layout/Page';
import { ReactComponent as CreateAccount } from 'src/images/create-account.svg';
import { ReactComponent as Logo } from 'src/images/logo.svg';
import LoginWithEmailForm from 'src/views/Login/LoginWithEmailForm';

function LoginWithEmail(props) {
  const { userRole } = props;

  const { t } = useTranslation();

  return (
    <Page className="LoginWithEmail flex" title={t('Login with email')}>
      <section className="hidden lg:flex flex-col w-[520px] bg-blue-600 h-screen">
        <div className="p-7">
          <Logo />
        </div>
        <div className="flex-1 flex flex-col justify-end">
          <CreateAccount />
        </div>
      </section>

      <section className="flex-1 flex flex-col items-center justify-center h-screen">
        <div className="flex-1 flex flex-col justify-center">
          <div className="grid gap-10">
            <h1>{t('Login with email')}</h1>

            <LoginWithEmailForm userRole={userRole} />
          </div>
        </div>
        {/* <Footer /> */}
      </section>
    </Page>
  );
}

LoginWithEmail.propTypes = {
  userRole: PropTypes.oneOf(['teacher', 'student']).isRequired,
};

export default LoginWithEmail;
