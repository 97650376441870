import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import i18n from 'i18next';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useStudentFeedbackMutation } from 'src/api/feedback';
import Modal from 'src/components/ui-kit/Modal';
import ProgressBar from 'src/components/ui-kit/ProgressBar';
import StarRating from 'src/components/ui-kit/StarRating';
import { showErrorMessage, showSuccessMessage } from 'src/helpers/toast';
import { useModal } from 'src/helpers/useModal';
import useValidationHelpers from 'src/helpers/useValidationHelpers';
import { isValidRating } from 'src/helpers/validators';
import LottieAnimation from 'src/images/lottie/success.json';

const schema = () => yup.object({
  engagement: yup
    .number()
    .required(i18n.t('Field is required'))
    .test(
      'ratingValidator',
      i18n.t('Not valid value'),
      isValidRating,
    ),
  rigor: yup
    .number()
    .required(i18n.t('Field is required'))
    .test(
      'ratingValidator',
      i18n.t('Not valid value'),
      isValidRating,
    ),
}).required();

const initialFormData = () => ({
  engagement: 0,
  rigor: 0,
});

function FeedbackModal(props) {
  const { courseId, isOpen, lessonId, onClose, onSuccess } = props;

  const { t } = useTranslation();
  const { closeModal } = useModal();

  const handleClose = () => {
    closeModal();
    onClose();
  };

  const { control, formState, handleSubmit } = useForm({
    defaultValues: initialFormData(),
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema()),
  });
  const { getFieldError } = useValidationHelpers({ formState });

  const { mutateAsync: sendFeedback } = useStudentFeedbackMutation({ courseId, lessonId });

  const onSubmit = async (formData, event) => {
    event.preventDefault();

    try {
      await sendFeedback({
        body: {
          answer: formData.rigor,
          feedbackType: 'RIGOR',
        },
      });
      await sendFeedback({
        body: {
          answer: formData.engagement,
          feedbackType: 'ENGAGEMENT',
        },
      });
      onSuccess();
      handleClose();
      showSuccessMessage(t('Feedback successfully sent!'));
    } catch (e) {
      showErrorMessage(e?.response?.data?.message ?? e.message);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('Congratulations!')}
    >
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="grid">
          <div className="grid gap-4 justify-center">
            <div className="w-48 -mb-10 -mt-16">
              <Lottie animationData={LottieAnimation} loop />
            </div>
            <ProgressBar
              progress={100}
              showText
              text="Lesson {{progress}}% completed"
            />
          </div>

          <label className="mt-6 text-sm">
            {t('The lesson is rigorous and developed my ability to think critically about the subject.')}
            <span className="label-star"> *</span>
          </label>

          <div className="flex justify-center">
            <Controller
              control={control}
              id="rigor"
              name="rigor"
              render={({ field: { onChange, value } }) => (
                <StarRating
                  name="rigor"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </div>

          <p className="error-message mt-1">{getFieldError('rigor')}</p>
        </div>

        <div className="grid mt-5">
          <label className="mb-2 text-sm">
            {t('The lesson is engaging and relevant. I believe that what I am being asked to learn in this lesson is interesting and important.')}
            <span className="label-star"> *</span>
          </label>

          <div className="flex justify-center">
            <Controller
              control={control}
              id="engagement"
              name="engagement"
              render={({ field: { onChange, value } }) => (
                <StarRating
                  name="engagement"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </div>
          <p className="error-message mt-1">{getFieldError('engagement')}</p>
        </div>

        <div className="mt-3 grid gap-6">
          <button
            className="ho-link underline w-full"
            onClick={handleClose}
          >
            {t('Skip')}
          </button>

          <button
            className="button--secondary"
            disabled={!formState.isValid}
            type="submit"
          >
            {t('Rate lesson')}
          </button>
        </div>
      </form>
    </Modal>
  );
}

FeedbackModal.propTypes = {
  courseId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  lessonId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default FeedbackModal;
