import React, { useCallback, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { statuses, useCreateModuleTrackingMutation } from 'src/api/tracking';
import { useCurrentLesson } from 'src/contexts/useCurrentLesson';
import { checkIfHasUnansweredContentItems } from 'src/helpers/lessons';
import { ReactComponent as IconCircleCheck } from 'src/images/icons-deprecated/circle-check.svg';

function ModuleTrackingStatus(props) {
  const { module } = props;

  const { t } = useTranslation();

  const {
    course, fetchCourse,
    questionnaireNotesMap, quizNotesMap,
  } = useCurrentLesson();
  const { mutateAsync: createModuleTracking } = useCreateModuleTrackingMutation();

  const currentStatus = course.courseTrackingModel.moduleStatuses[module.id];

  const hasAllAnswers = !checkIfHasUnansweredContentItems({
    module, questionnaireNotesMap, quizNotesMap,
  });

  const updateStatus = useCallback(async (status) => {
    await createModuleTracking({ body: {
      moduleId: module.id,
      status,
    } });
    await fetchCourse();
  }, [createModuleTracking, fetchCourse, module]);

  useEffect(() => {
    async function handleStatusesCheck() {
      try {
        if (currentStatus === statuses.NOT_STARTED) {
          if (hasAllAnswers) {
            await updateStatus(statuses.COMPLETED);
          } else {
            await updateStatus(statuses.IN_PROGRESS);
          }
        } else if (currentStatus === statuses.IN_PROGRESS) {
          if (hasAllAnswers) {
            await updateStatus(statuses.COMPLETED);
          }
        }
      } catch (e) {
        // Ignore error
      }
    }

    handleStatusesCheck();
  }, [currentStatus, hasAllAnswers, updateStatus]);

  return (
    <div className="ModuleTrackingStatus">
      {currentStatus === statuses.IN_PROGRESS && (
        <div className="flex items-center gap-2">
          <IconCircleCheck className="w-8 fill-MainYellow" />
          <span>{t('Module tasks remaining')}</span>
        </div>
      )}
      {currentStatus === statuses.COMPLETED && (
        <div className="flex items-center gap-2">
          <IconCircleCheck className="w-8 fill-teal-500" />
          <span>{t('Module completed')}</span>
        </div>
      )}
    </div>
  );
}

ModuleTrackingStatus.propTypes = {
  module: PropTypes.object.isRequired,
};

export default ModuleTrackingStatus;
