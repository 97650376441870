/* eslint-disable sort-keys */
import { useSearchParams } from 'react-router-dom';

import { statuses } from '../api/tracking';
import { useCurrentUser } from '../contexts/useCurrentUser';

export const INDEXES_MAP = {
  INTRO: 1,
  LAUNCH: 2,
  WORTHY_TASK: 3,
  MINI_VIDEO: 4,
  PSA_A: 5.1,
  PSA_B: 5.2,
  PSA_C: 5.3,
  DISCUSSION: 6,
  TAKEAWAYS: 7,
  EXIT: 8,
};

export function useLessonPreviewUrl() {
  const [searchParams] = useSearchParams();
  const { role } = useCurrentUser();
  return {
    buildUrl({
      courseId,
      lessonId,
      moduleId = 1,
      studentId = searchParams.get('studentId'),
      studentName = searchParams.get('studentName'),
    }) {
      const query = studentId && studentName ? `?studentId=${studentId}&studentName=${studentName}` : '';
      return `/${role}/courses/${courseId}/lessons/${lessonId}/modules/${moduleId}${query}`;
    },
  };
}

export function getContentItem(module, type) {
  return module.contentItems.find((item) => item.type === type);
}

export function moduleHasContentItem(module, type) {
  return !!getContentItem(module, type);
}

export function checkIfHasUnansweredContentItems({ module, questionnaireNotesMap, quizNotesMap }) {
  return module.contentItems.reduce((hasUnanswered, contentItem) => {
    if (contentItem.type === 'QUIZ') {
      const hasCurrentUnanswered = contentItem.quizzes.some((quiz) => !quizNotesMap[quiz.id]);
      return hasUnanswered || hasCurrentUnanswered;
    }
    if (contentItem.type === 'QUESTIONNAIRE') {
      const hasCurrentUnanswered = contentItem.questionnaires.some(
        (questionnaire) => {
          const note = questionnaireNotesMap[questionnaire.id];
          return !note || !note.value;
        },
      );
      return hasUnanswered || hasCurrentUnanswered;
    }
    return false;
  }, false);
}

export function calculateProgress(moduleStatusesMap, lesson) {
  const currentLessonModuleIds = lesson.modules.map((module) => module.id);
  const allCurrentLessonModuleStatuses = Object
    .entries(moduleStatusesMap)
    .filter(([moduleId]) => currentLessonModuleIds.includes(parseInt(moduleId, 10)))
    .map(([, status]) => status);

  const completedModules = allCurrentLessonModuleStatuses
    .filter((status) => status === statuses.COMPLETED);

  const completedModulesCount = completedModules.length;
  const allModulesCount = allCurrentLessonModuleStatuses.length;
  const ratio = Math.floor((completedModulesCount / allModulesCount) * 100);
  return { ratio, allModulesCount, completedModulesCount };
}
