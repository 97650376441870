import React from 'react';

import classNames from 'classnames';
import { getYear } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { PRIVACY_URL, TERMS_URL } from 'src/config';

const variants = ['light', 'dark'];

function Footer(props) {
  const { variant } = props;

  const { t } = useTranslation();
  const year = getYear(new Date());

  return (
    <footer className={classNames('Footer flex h-8 shrink-0 items-center text-xs justify-center gap-6', {
      'bg-DarkestBlue text-white': variant === 'dark',
    })}
    >
      <p>{t('© Copyright {{year}}. All Rights Reserved', { year })}</p>
      <ul className="flex gap-6">
        <li>
          <a
            className="ho-link"
            href={TERMS_URL}
            rel="noreferrer"
            target="_blank"
          >
            {t('Terms')}
          </a>
        </li>
        <li>
          <a
            className="ho-link"
            href={PRIVACY_URL}
            rel="noreferrer"
            target="_blank"
          >
            {t('Privacy')}
          </a>
        </li>
      </ul>
    </footer>
  );
}

Footer.propTypes = {
  variant: PropTypes.oneOf(variants),
};

Footer.defaultProps = {
  variant: 'dark',
};

export default Footer;
