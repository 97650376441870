import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { ReactComponent as IconChevronRight } from 'src/images/icons/chevron-right.svg';
import BreadcrumbModel from 'src/models/BreadcrumbModel';

function Breadcrumbs(props) {
  const { breadcrumbs } = props;

  const lastIndex = breadcrumbs.length - 1;

  return (
    <nav className="Breadcrumbs">
      <ul className="flex items-center">
        {breadcrumbs.map((breadcrumb, index) => (index === lastIndex ? (
          <li key={breadcrumb.key} className="font-bold">{breadcrumb.name}</li>
        ) : (
          <Fragment key={breadcrumb.key}>
            <li>
              <NavLink className="ho-link-underline" to={breadcrumb.route}>
                {breadcrumb.name}
              </NavLink>
            </li>
            <li>
              <IconChevronRight />
            </li>
          </Fragment>
        )))}
      </ul>
    </nav>
  );
}

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.instanceOf(BreadcrumbModel)).isRequired,
};

export default Breadcrumbs;
