import React from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useCourse } from 'src/api/courses';
import { useStudentStats } from 'src/api/stats';
import CourseLessons from 'src/components/common/Course/CourseLessons';
import CourseOverview from 'src/components/common/Course/CourseOverview';
import CourseProfessor from 'src/components/common/Course/CourseProfessor';
import Page from 'src/components/layout/Page';
import Breadcrumbs from 'src/components/ui-kit/Breadcrumbs';
import Loading from 'src/components/ui-kit/Loading';
import SidebarMenu from 'src/components/ui-kit/SidebarMenu';
import { useCurrentUser } from 'src/contexts/useCurrentUser';

import LessonCard from './LessonCard';
import useCourseNavigation from './useCourseNavigation';

function Course() {
  const { t } = useTranslation();
  const { courseId } = useParams();
  const { role } = useCurrentUser();
  const { data: course, isFetching } = useCourse(
    { courseId, role },
  );
  const { data: stats } = useStudentStats({ courseId });

  const {
    activeMenuItem,
    breadcrumbs,
    menuItems,
    setActiveMenuItem,
  } = useCourseNavigation(course);

  if (isFetching) {
    return (
      <Page title={t('Course')}>
        <Loading />
      </Page>
    );
  }

  return (
    <Page title={t('Course')}>
      <h1 className="mb-2">{course.name}</h1>

      <Breadcrumbs breadcrumbs={breadcrumbs} />

      <section className="grid grid-cols-[208px_auto] 2xl:grid-cols-[208px_auto_208px] gap-6 mt-9 pb-10">
        <SidebarMenu
          activeMenuItem={activeMenuItem}
          className="-ml-6"
          menuItems={menuItems}
          setActiveMenuItem={setActiveMenuItem}
        />

        {activeMenuItem === 'overview' && (
          <CourseOverview course={course} />
        )}

        {activeMenuItem === 'professor' && (
          <CourseProfessor course={course} />
        )}

        {activeMenuItem === 'lessons' && stats && (
          <CourseLessons
            course={course}
            LessonCard={LessonCard}
            stats={stats}
          />
        )}
      </section>
    </Page>
  );
}

export default Course;
