import React from 'react';

import Vimeo from '@u-wave/react-vimeo';
import PropTypes from 'prop-types';

function CourseVideo(props) {
  const { course } = props;

  return (
    <div className="CourseVideo">
      <Vimeo
        autoplay={false}
        controls
        responsive
        showByline={false}
        showPortrait={false}
        showTitle={false}
        video={course.videoUrl}
      />
    </div>
  );
}

CourseVideo.propTypes = {
  course: PropTypes.object.isRequired,
};

export default CourseVideo;
