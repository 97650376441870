import React from 'react';

import capitalize from 'lodash-es/capitalize';
import { useTranslation } from 'react-i18next';

import ProfileAvatar from 'src/components/common/ProfileAvatar';
import Page from 'src/components/layout/Page';
import ProfileModal from 'src/components/modals/ProfileModal/ProfileModal';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import { useModal } from 'src/helpers/useModal';
import { ReactComponent as IconEdit } from 'src/images/icons-deprecated/edit.svg';

function Profile() {
  const { me, role } = useCurrentUser();
  const { t } = useTranslation();
  const { openModal } = useModal();

  const onEdit = () => {
    openModal(ProfileModal);
  };

  return (
    <Page title={t('Profile')}>
      <h1 className="mt-1">{t('Profile')}</h1>

      <div className="Profile mt-6 ho-card px-5 py-4 flex items-start gap-4 relative">
        <ProfileAvatar />

        <section className="grid grid-cols-[max-content_auto] gap-x-8 gap-y-2 text-sm">
          <span className="font-bold">
            {t(`${capitalize(role)} name`)}
            :
          </span>
          <span className="text-sm">
            {
              me.firstName && me.lastName
                ? `${me.firstName} ${me.lastName}`
                : '-'
            }
          </span>

          <span className="font-bold">
            {t('Email')}
            :
          </span>
          <span>
            <a
              href={`mailto:${me.email}`}
              rel="noreferrer"
              target="_blank"
            >
              {me.email}
            </a>
          </span>
        </section>

        <button className="absolute right-5 top-4 p-2 rounded-full" onClick={onEdit}>
          <IconEdit className="stroke-ho-grey-300" />
        </button>
      </div>
    </Page>
  );
}

export default Profile;
