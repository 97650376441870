import React from 'react';

import { useTranslation } from 'react-i18next';

import { useClassrooms } from 'src/api/classrooms';
import Page from 'src/components/layout/Page';
import ClassroomModal from 'src/components/modals/ClassroomModal/ClassroomModal';
import DataGrid from 'src/components/ui-kit/DataGrid';
import { useModal } from 'src/helpers/useModal';
import usePagination from 'src/helpers/usePagination';
import { ReactComponent as IconPlus } from 'src/images/icons/plus.svg';

import ClassroomTableRow from './ClassroomTableRow';

function Classrooms() {
  const { t } = useTranslation();

  const {
    fetchNextPage,
    hasNextPage,
    isFetched,
    isFetching,
    items: classrooms,
    refetch,
  } = useClassrooms({ size: 20 });

  const { onLoadMore } = usePagination({ fetchNextPage });
  const { openModal } = useModal();

  const onCreateClassroomButtonClick = () => {
    openModal(ClassroomModal, { onSuccess: refetch });
  };

  return (
    <Page title={t('Classrooms')}>
      <section className="flex items-center justify-between">
        <h1>{t('Classrooms')}</h1>

        <button className="button--primary flex items-center gap-1.5" onClick={onCreateClassroomButtonClick}>
          <IconPlus />
          {t('Create classroom')}
        </button>
      </section>

      <section className="ho-card mt-8">
        <div className="h-20 grid items-center px-8">
          Select course
        </div>
        <DataGrid
          emptyText={t('You have no classrooms. Click "Create classroom" button to start')}
          hasNextPage={hasNextPage}
          isEmpty={isFetched && classrooms.length === 0}
          isFetching={isFetching}
          onLoadMore={onLoadMore}
        >
          <table className="table-grid text-sm">
            <thead>
              <tr className="grid pl-8 pr-4 bg-MainYellow/[0.15] h-10 items-center grid-cols-[160px_1fr_1fr_160px]">
                <th>{t('Status')}</th>
                <th>{t('Classroom Name')}</th>
                <th>{t('Course')}</th>
                <th className="text-center">{t('Actions')}</th>
              </tr>
            </thead>

            <tbody>
              {classrooms.map((classroom) => (
                <ClassroomTableRow
                  key={classroom.id}
                  classroom={classroom}
                  onChange={refetch}
                />
              ))}
            </tbody>
          </table>
        </DataGrid>
      </section>
    </Page>
  );
}

export default Classrooms;
