import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink, useParams } from 'react-router-dom';

import ProgressBar from 'src/components/ui-kit/ProgressBar';
import { useLessonPreviewUrl } from 'src/helpers/lessons';
import { ReactComponent as IconEye } from 'src/images/icons/eye.svg';

function ClassroomStudentProgressTableViewRow(props) {
  const { className, course, lesson, stats, studentName } = props;

  const { studentId } = useParams();
  const { buildUrl } = useLessonPreviewUrl();

  const lessonUrl = buildUrl({
    courseId: course.id,
    lessonId: lesson.id,
    moduleId: 1,
    studentId,
    studentName,
  });

  return (
    <tr
      className={classNames('grid items-center pl-8 pr-4 w-full h-[52px] items-center hover:bg-Grey w-full', className)}
    >
      <td>
        <NavLink
          className="text-Blue hover:underline"
          to={lessonUrl}
        >
          {lesson.name}
        </NavLink>
      </td>
      <td className="pr-4">
        <ProgressBar
          className="w-full max-w-4xl"
          progress={Math.floor(stats[lesson.id] * 100)}
          rounded
          showText
        />
      </td>
      <td>
        <div className="ClassroomMembersRowActions text-DarkBlue/40 hover:text-DarkBlue/80 flex items-center justify-center gap-4">
          <NavLink to={lessonUrl}>
            <IconEye className="w-6" />
          </NavLink>
        </div>
      </td>
    </tr>
  );
}

ClassroomStudentProgressTableViewRow.propTypes = {
  className: PropTypes.string.isRequired,
  course: PropTypes.object.isRequired,
  lesson: PropTypes.object.isRequired,
  stats: PropTypes.object.isRequired,
  studentName: PropTypes.string.isRequired,
};

export default ClassroomStudentProgressTableViewRow;
