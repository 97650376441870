import { toast } from 'react-toastify';

// https://fkhadra.github.io/react-toastify/api/toast
const defaultOptions = {
  autoClose: 3000,
  bodyStyle: {
    height: 40,
    maxHeight: 40,
    padding: '0 24px',
    width: 'auto',
  },
  className: 'h-10 p-0 mt-8 rounded-[22px] shadow-ho-toast',
  closeButton: false,
  closeOnClick: true,
  hideProgressBar: true,
  position: toast.POSITION.TOP_CENTER,
  style: {
    borderRadius: '22px',
    height: 40,
    maxHeight: 40,
    minHeight: 40,
    padding: 0,
    width: 'auto',
  },
};

export const showSuccessMessage = (message, options) => toast(
  message,
  {
    ...defaultOptions,
    bodyClassName: 'flex items-center bg-ho-green px-6 py-3 text-sm text-center rounded-[22px] text-white',
    ...options,
  },
);

export const showErrorMessage = (message, options) => toast(
  message,
  {
    ...defaultOptions,
    bodyClassName: 'flex items-center bg-ho-declined px-6 py-3 text-sm rounded-[22px] text-white',
    ...options,
  },
);

// https://fkhadra.github.io/react-toastify/api/toast
export default function useToast() {
  return {
    showErrorMessage,
    showSuccessMessage,
    toast,
  };
}
