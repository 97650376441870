import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import SidebarMenuItemModel from 'src/models/SidebarMenuItemModel';

function SidebarMenu(props) {
  const { activeMenuItem, className, menuItems, setActiveMenuItem } = props;

  const onClick = (menuItem) => () => {
    setActiveMenuItem(menuItem.key);
    if (menuItem.action) {
      menuItem.action();
    }
  };

  return (
    <nav className={classNames('SidebarMenu', className)}>
      <ul>
        {menuItems.map((menuItem) => (
          <li key={menuItem.key}>
            <button
              className={classNames('h-12 rounded-r-full relative text-left items-center w-full pl-6 flex gap-3', {
                'bg-MainYellow/[0.15] font-bold': menuItem.key === activeMenuItem,
                'text-DarkBlue/[0.8]': menuItem.key !== activeMenuItem,
              })}
              onClick={onClick(menuItem)}
            >
              {menuItem.key === activeMenuItem && (
                <div className="bg-MainYellow w-1 absolute left-0 top-0 h-12" />
              )}
              {menuItem.Icon}
              {menuItem.title}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
}

SidebarMenu.propTypes = {
  activeMenuItem: PropTypes.string.isRequired,
  className: PropTypes.string,
  menuItems: PropTypes.arrayOf(PropTypes.instanceOf(SidebarMenuItemModel)).isRequired,
  setActiveMenuItem: PropTypes.func.isRequired,
};

SidebarMenu.defaultProps = {
  className: '',
};

export default SidebarMenu;
