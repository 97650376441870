import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as IconAlert } from 'src/images/icons/alert.svg';
import { ReactComponent as IconSuccess } from 'src/images/icons/success.svg';

const variants = ['error', 'warning', 'success'];

function Alert(props) {
  const { message, variant } = props;

  return (
    <div className={classNames('Alert px-6 py-2 rounded-[25px] flex items-center gap-5', {
      'bg-Green/40': variant === 'success',
      'bg-MainYellow/50': variant === 'warning',
      'bg-Red/25': variant === 'error',
    })}
    >
      {variant === 'error' && (
        <IconAlert className="flex-shrink-0" />
      )}
      {variant === 'warning' && (
        <IconAlert className="flex-shrink-0" />
      )}
      {variant === 'success' && (
        <IconSuccess className="flex-shrink-0" />
      )}
      <p>{message}</p>
    </div>
  );
}

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(variants).isRequired,
};

export default Alert;
