import React from 'react';

import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';

import { useCurrentLesson } from 'src/contexts/useCurrentLesson';
import { useLessonPreviewUrl } from 'src/helpers/lessons';

function ModuleNavigation() {
  const { lesson } = useCurrentLesson();
  const { courseId, lessonId, moduleId } = useParams();
  const { t } = useTranslation();
  const { buildUrl } = useLessonPreviewUrl();

  const currentIndex = Number(moduleId) - 1;

  const hasPrevious = currentIndex > 0;
  const hasNext = currentIndex < lesson.modules.length - 1;

  return (
    <div className="ModuleNavigation flex items-center justify-center gap-6">
      {hasPrevious && (
        <NavLink
          className="button--primary flex items-center gap-2"
          to={buildUrl({ courseId, lessonId, moduleId: currentIndex })}
        >
          {t('Previous')}
        </NavLink>
      )}

      {hasNext && (
        <NavLink
          className="button--primary flex items-center gap-2"
          to={buildUrl({ courseId, lessonId, moduleId: currentIndex + 2 })}
        >
          {t('Next')}
        </NavLink>
      )}
    </div>
  );
}

export default ModuleNavigation;
