import React from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as IconBell } from 'src/images/icons/bell.svg';

function IconNotifications(props) {
  const { count } = props;

  return (
    <span className="IconNotifications relative">
      {count !== 0 && (
        <div className="absolute -top-0.5 -right-0.5 h-3.5 w-3.5 rounded-full flex items-center justify-center text-[8px] bg-ho-declined">
          {count}
        </div>
      )}
      <IconBell className="fill-current" />
    </span>
  );
}

IconNotifications.propTypes = {
  count: PropTypes.number.isRequired,
};

export default IconNotifications;
