class SidebarMenuItemModel {
  constructor({ Icon, action, key, title }) {
    this.key = key;
    this.title = title;
    this.Icon = Icon;
    this.action = action;
  }
}

export default SidebarMenuItemModel;
