import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const colorsTailwind = require('tailwindcss/colors');

function StudentFeedbacksLessonChart(props) {
  const { data } = props;

  const { t } = useTranslation();

  return (
    <div className="StudentFeedbacksLessonChart max-w-[800px]">
      <ResponsiveContainer
        height={300}
        width="100%"
      >
        <LineChart data={data}>
          <XAxis
            dataKey="name"
            dy={4}
          />
          <YAxis
            domain={[0, 5]}
            dx={-2}
            label={{
              angle: -90,
              dx: -12,
              value: t('Average student rating'),
            }}
            ticks={[0, 1, 2, 3, 4, 5]}
          />
          <Tooltip />
          <Legend
            iconType="circle"
            verticalAlign="top"
          />

          <Line
            dataKey={t('rigor')}
            stroke={colorsTailwind.teal[500]}
            type="monotone"
          />
          <Line
            dataKey={t('engagement')}
            stroke={colorsTailwind.rose[600]}
            type="monotone"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

StudentFeedbacksLessonChart.propTypes = {
  data: PropTypes.array.isRequired,
};

export default StudentFeedbacksLessonChart;
