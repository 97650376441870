import React, { useRef } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useDiscussionImageUrlMutation } from 'src/api/discussion';
import CropImageModal from 'src/components/common/CropImageModal';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import { toBase64 } from 'src/helpers/file';
import { showErrorMessage, showSuccessMessage } from 'src/helpers/toast';
import { useModal } from 'src/helpers/useModal';
import { ReactComponent as IconImage } from 'src/images/icons/image.svg';
import { ReactComponent as IconEdit } from 'src/images/icons-deprecated/edit.svg';

function DiscussionImage(props) {
  const { discussion, onImageChange } = props;

  const { t } = useTranslation();
  const { closeModal, openModal } = useModal();
  const inputRef = useRef();
  const { role } = useCurrentUser();

  const { mutateAsync: updateImageUrl } = useDiscussionImageUrlMutation();

  const onChange = async (event) => {
    const file = event.target.files[0];
    const image = await toBase64(file);
    openModal(CropImageModal, {
      cropperProps: {
        aspectRatio: 4 / 3,
        initialAspectRati: 4 / 3,
      },
      image,
      onClose() {
        inputRef.current.value = '';
      },
      async onSuccess(blob) {
        try {
          await updateImageUrl({ blob, discussionId: discussion.id });
          onImageChange();
          closeModal();
          showSuccessMessage(t('Discussions image successfully updated'));
        } catch (e) {
          showErrorMessage(e.message);
        }
      },
      title: t('Edit discussion image'),
    });
  };

  return (
    <div className="DiscussionImage w-full h-60 rounded-md bg-MainBlue overflow-hidden flex items-center justify-center relative">
      {discussion.imageUrl ? (
        <img alt={t('Discussions image')} className="w-full h-full" src={discussion.imageUrl} />
      ) : (
        <IconImage className="transform scale-[4] text-White" />
      )}
      <button className="absolute right-5 top-4 p-2 rounded-full" onClick={() => inputRef.current.click()}>
        {role === 'teacher' && (
          <IconEdit className="stroke-White" />
        )}
        <input
          ref={inputRef}
          accept="image/png, image/jpeg, image/gif, image/bmp, image/svg"
          className="hidden"
          onChange={onChange}
          type="file"
        />
      </button>
    </div>
  );
}

DiscussionImage.propTypes = {
  discussion: PropTypes.object.isRequired,
  onImageChange: PropTypes.func.isRequired,
};

export default DiscussionImage;
