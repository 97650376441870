import React from 'react';

import { useTranslation } from 'react-i18next';

import Page from 'src/components/layout/Page';
import Alert from 'src/components/ui-kit/Alert';
import Breadcrumbs from 'src/components/ui-kit/Breadcrumbs';
import Loading from 'src/components/ui-kit/Loading';
import { ReactComponent as IconLaunch } from 'src/images/icons/launch.svg';
import { ReactComponent as IconPrelaunch } from 'src/images/icons/prelaunch.svg';
import NotFound from 'src/views/NotFound/NotFound';
import ClassroomMembers from 'src/views/Teacher/Classroom/ClassroomMembers/ClassroomMembers';

import ClassPerformanceSection from './ClassPerformanceSection';
import ClassroomActionsDropdown from './ClassroomActionsDropdown';
import ClassroomCourseSection from './ClassroomCourseSection';
import useClassroomNavigation from './useClassroomNavigation';
import useClassroomState from './useClassroomState';

function Classroom() {
  const { actions, classroom, isFetching } = useClassroomState();
  const { t } = useTranslation();
  const { breadcrumbs } = useClassroomNavigation(classroom);

  if (isFetching) {
    return (
      <Page title={t('Loading')}>
        <Loading />
      </Page>
    );
  }

  if (!classroom) {
    return (
      <NotFound />
    );
  }

  const { launchStatus } = classroom;

  return (
    <Page title={classroom.name}>
      <div className="flex justify-between items-center">
        <div className="grid gap-2">
          <h1>{classroom.name}</h1>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>

        <div className="flex gap-4 items-center">
          {['NOT_STARTED'].includes(launchStatus) && (
            <button
              className="button--primary flex gap-1 items-center"
              onClick={actions.preLaunchClassroom}
            >
              <IconPrelaunch />
              {t('Prelaunch Classroom')}
            </button>
          )}
          {['NOT_STARTED', 'PRE_LAUNCH'].includes(launchStatus) && (
            <button
              className="button--secondary-outlined flex gap-1 items-center"
              onClick={actions.launchClassroom}
            >
              <IconLaunch />
              {t('Launch Classroom')}
            </button>
          )}
          <ClassroomActionsDropdown actions={actions} />
        </div>
      </div>

      <div className="grid gap-6 grid-cols-[395px_1fr] mt-9">
        <div className="grid gap-4">
          <section>
            {launchStatus === 'NOT_STARTED' && (
              <Alert
                message={t('Not started: The course is not available for students in this classroom')}
                variant="error"
              />
            )}
            {launchStatus === 'PRE_LAUNCH' && (
              <Alert
                message={t('Pre-Launched: Only the first 2 sections are available for students in this classroom, including the introduction and launch task')}
                variant="warning"
              />
            )}
            {launchStatus === 'STARTED' && (
              <Alert
                message={t('This classroom has been launched!')}
                variant="success"
              />
            )}
          </section>

          {classroom && (
            <ClassroomCourseSection classroom={classroom} />
          )}

          <ClassPerformanceSection />
        </div>

        <ClassroomMembers classroom={classroom} />
      </div>
    </Page>
  );
}

export default Classroom;
