import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useDiscussionComments } from 'src/api/discussion-comments';
import DataGrid from 'src/components/ui-kit/DataGrid';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import CommentItem from 'src/views/Discussions/CommentItem';
import CommentForm from 'src/views/Discussions/DiscussionComments/CommentForm';

function DiscussionCommentsSection(props) {
  const { discussion } = props;

  const { role } = useCurrentUser();
  const { t } = useTranslation();

  const {
    data: comments = [],
    isFetching,
    refetch,
  } = useDiscussionComments({ discussionId: discussion.id, role });

  return (
    <>
      <CommentForm discussion={discussion} onSuccess={refetch} />

      <div className="font-bold">
        {t('{{count}} Comments', { count: comments.length })}
      </div>

      <DataGrid
        emptyText={t('You currently have no comments')}
        hasNextPage={false}
        isEmpty={false}
        isFetching={isFetching}
        onLoadMore={() => {}}
      >
        <div className="mt-6 grid gap-4">
          {comments.map((commentItem) => (
            <CommentItem key={commentItem.commentModel.id} data={commentItem} />
          ))}
        </div>
      </DataGrid>
    </>
  );
}

DiscussionCommentsSection.propTypes = {
  discussion: PropTypes.object.isRequired,
};

export default DiscussionCommentsSection;
