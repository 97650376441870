import { useState } from 'react';

export default function usePagination({ fetchNextPage, initialPage = 0 }) {
  const [page, setPage] = useState(initialPage);

  const onLoadMore = async () => {
    const nextPage = page + 1;
    setPage(nextPage);
    await fetchNextPage();
  };

  return {
    onLoadMore,
    page,
  };
}
