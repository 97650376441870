import React, { createContext, useMemo } from 'react';

import lowerCase from 'lodash-es/lowerCase';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useMe } from 'src/api/account';
import { clearAuthData } from 'src/api/api';
import { showErrorMessage } from 'src/helpers/toast';

export const CurrentUserContext = createContext({
  currency: '$',
  defaultRoute: '/',
  error: null,
  fetchMe: () => {},
  isFetched: false,
  isLoading: true,
  me: null,
  onCompleted: () => {},
  role: null,
});

export const getUserRole = (user) => (user ? lowerCase(user.roles[0].role) : null);
export const getUserDefaultRoute = (user) => {
  if (!user) {
    return '/';
  }
  const role = getUserRole(user);
  if (role === 'student') {
    return '/student/courses';
  }
  return '/teacher/dashboard';
};

export function CurrentUserProvider(props) {
  const { children } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const goToWelcomePage = () => navigate('/welcome', { replace: true });

  const { data: me, error, isFetched, isLoading, refetch } = useMe({
    onError(e) {
      // 401 is handled globally
      if (e.request.status === 400) {
        showErrorMessage(t('Something went wrong. Please login again'));
        clearAuthData();
        goToWelcomePage();
      }
    },
    refetchOnWindowFocus: false,
  });

  const role = getUserRole(me);
  const defaultRoute = getUserDefaultRoute(me);

  const context = useMemo(() => ({
    currency: '$',
    defaultRoute,
    error,
    fetchMe: refetch,
    isFetched,
    isLoading,
    me,
    role,
  }), [defaultRoute, error, refetch, isFetched, isLoading, me, role]);

  return (
    <CurrentUserContext.Provider value={context}>
      {children}
    </CurrentUserContext.Provider>
  );
}

CurrentUserProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useCurrentUser = () => React.useContext(CurrentUserContext);
