/* eslint-disable react/no-unstable-nested-components */
import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactMultiEmail } from 'react-multi-email';

import { ReactComponent as IconTimes } from 'src/images/icons/times.svg';

function InputMultipleEmails(props) {
  const { onChange } = props;

  const { t } = useTranslation();

  return (
    <div className="InputMultipleEmails">
      <ReactMultiEmail
        getLabel={(email, index, removeEmail) => (
          <div
            key={index}
            data-tag
          >
            {email}
            <button
              className="rounded-full bg-White"
              data-tag-handle
              onClick={() => removeEmail(index)}
            >
              <IconTimes className="text-Black" />
            </button>
          </div>
        )}
        onChange={onChange}
        placeholder={t('Type multiple emails here')}
      />
    </div>
  );
}

InputMultipleEmails.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default InputMultipleEmails;
