import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { Route, Routes, useMatch, useNavigate } from 'react-router-dom';

import TopBarMenu from 'src/components/common/TopBarMenu/TopBarMenu';
import Layout from 'src/components/layout/Layout';
import Loading from 'src/components/ui-kit/Loading';
import { SUPPORT_URL } from 'src/config';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import { FEATURE_FLAGS, isFeatureEnabled } from 'src/helpers/featureFlags';
import { openUrlInNewTab } from 'src/helpers/url';
import { ReactComponent as IconCommunication } from 'src/images/icons/communication.svg';
import { ReactComponent as IconComputer } from 'src/images/icons/computer.svg';
import { ReactComponent as IconHelp } from 'src/images/icons/help.svg';
import { ReactComponent as IconHome } from 'src/images/icons/home.svg';
import { ReactComponent as IconTwoUsers } from 'src/images/icons/two-users.svg';
import NavigationItemModel from 'src/models/NavigationItemModel';
import DiscussionComments from 'src/views/Discussions/DiscussionComments/DiscussionComments';
import Discussions from 'src/views/Discussions/Discussions';
import NotFound from 'src/views/NotFound/NotFound';
import Notifications from 'src/views/Notifications/Notifications';
import Classroom from 'src/views/Teacher/Classroom/Classroom';
import Classrooms from 'src/views/Teacher/Classrooms/Classrooms';
import ClassroomStudent from 'src/views/Teacher/ClassroomStudent/ClassroomStudent';
import Course from 'src/views/Teacher/Course/Course';
import Courses from 'src/views/Teacher/Courses/Courses';
import Dashboard from 'src/views/Teacher/Dashboard/Dashboard';
import { useDashboard } from 'src/views/Teacher/Dashboard/useDashboard';
import IconNotificationsFetcher from 'src/views/Teacher/IconNotificationsFetcher';
import ManageClasses from 'src/views/Teacher/ManageClasses/ManageClasses';
import Profile from 'src/views/Teacher/Profile/Profile';

import Lesson from '../Study/Lesson/Lesson';

function Teacher() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { defaultRoute, isFetched, isLoading, me } = useCurrentUser();
  const { hasCourses } = useDashboard();

  const navigationItems = [
    new NavigationItemModel(
      {
        Icon: <IconHome />,
        absolutePath: '/teacher/dashboard',
        component: <Dashboard />,
        name: t('Dashboard'),
        relativePath: 'dashboard',
        to: '/teacher/dashboard/',
      },
    ),
    new NavigationItemModel(
      {
        Icon: <IconTwoUsers className="fill-current h-6" />,
        absolutePath: '/teacher/classrooms',
        component: <Classrooms />,
        name: t('Classrooms'),
        relativePath: 'classrooms',
        to: '/teacher/classrooms',
      },
    ),
    hasCourses ? new NavigationItemModel(
      {
        Icon: <IconComputer />,
        absolutePath: '/teacher/courses',
        component: <Courses />,
        name: t('Courses'),
        relativePath: 'courses',
        to: '/teacher/courses',
      },
    ) : null,
    new NavigationItemModel(
      {
        Icon: <IconCommunication className="fill-current" />,
        absolutePath: '/teacher/discussion',
        component: <Discussions />,
        name: t('Discussion'),
        relativePath: 'discussion',
        to: '/teacher/discussion',
      },
    ),
    new NavigationItemModel(
      {
        Icon: <IconHelp className="stroke-current" />,
        absolutePath: '',
        action() {
          openUrlInNewTab(SUPPORT_URL);
        },
        name: t('Help'),
      },
    ),
    new NavigationItemModel(
      {
        Icon: <IconNotificationsFetcher />,
        absolutePath: '/teacher/notifications',
        component: <Notifications />,
        name: t('Notifications'),
        relativePath: 'notifications',
        to: '/teacher/notifications',
      },
    ),
  ].filter(Boolean);

  const match = useMatch({
    exact: true,
    path: '/teacher',
  });

  useEffect(() => {
    if (match) {
      navigate(defaultRoute);
    }
  }, [navigate, match, defaultRoute]);

  return (
    <Layout
      Menu={<TopBarMenu navigationItems={navigationItems} />}
      SidebarComponent={null}
    >
      {isLoading || !isFetched || !me ? (
        <Loading />
      ) : (
        <Routes>
          { navigationItems.map((navigationItem, index) => (
            <Route
              key={index}
              element={navigationItem.component}
              path={navigationItem.relativePath}
            />
          ))}
          <Route element={<Profile />} path="profile" />
          <Route element={<Classroom />} path="classrooms/:classroomId" />
          <Route element={<ClassroomStudent />} path="classrooms/:classroomId/students/:studentId" />
          <Route element={<Course />} path="courses/:courseId" />
          <Route element={<Lesson />} path="courses/:courseId/lessons/:lessonId/*" />
          <Route element={<DiscussionComments />} path="discussion/:discussionId" />
          {isFeatureEnabled(FEATURE_FLAGS.MANAGE_CLASSES) && (
            <Route element={<ManageClasses />} path="manage-classes" />
          )}
          {me && (
            <Route element={<NotFound />} path="*" />
          )}
        </Routes>
      )}
    </Layout>
  );
}

export default Teacher;
