function useFeedbackLocalStorage({ courseId, lessonId }) {
  const key = `feedback-${courseId}-${lessonId}`;

  return {
    hasProvidedFeedback: () => !!localStorage.getItem(key),
    saveFeedback: () => localStorage.setItem(key, 'true'),
  };
}

export default useFeedbackLocalStorage;
