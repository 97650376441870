import React from 'react';

import { useTranslation } from 'react-i18next';

import Page from 'src/components/layout/Page';
import { ReactComponent as IconPlus } from 'src/images/icons-deprecated/plus.svg';
import ClassesGridView from 'src/views/Teacher/ManageClasses/ClassesGridView';

function ManageClasses() {
  const { t } = useTranslation();
  return (
    <Page title={t('Manage classes')}>
      <section className="flex justify-between items-center">
        <h1 className="mt-1 ">{t('Manage your classes')}</h1>

        <button className="button--primary-outlined flex items-center gap-2">
          <IconPlus className="fill-current" />
          {t('Add New Class')}
        </button>
      </section>

      <section className="mt-6">
        <ClassesGridView />
      </section>
    </Page>
  );
}

export default ManageClasses;
