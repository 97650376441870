import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams, Route, Routes, useNavigate, useMatch, useSearchParams } from 'react-router-dom';

import Breadcrumbs from 'src/components/ui-kit/Breadcrumbs';
import Loading from 'src/components/ui-kit/Loading';
import SidebarMenu from 'src/components/ui-kit/SidebarMenu';
import { useCurrentLesson, CurrentLessonProvider } from 'src/contexts/useCurrentLesson';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import { useLessonPreviewUrl } from 'src/helpers/lessons';
import { ReactComponent as IconInfo } from 'src/images/icons/info.svg';
import LessonModule from 'src/views/Study/LessonModule/LessonModule';

import useLessonNavigation from './useLessonNavigation';

function LessonLayout() {
  const { course, lesson } = useCurrentLesson();
  const { courseId, lessonId } = useParams();
  const navigate = useNavigate();
  const { buildUrl } = useLessonPreviewUrl();
  const { role } = useCurrentUser();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const match = useMatch({
    path: `/${role}/courses/:courseId/lessons/:lessonId/modules/:moduleId`,
  });

  // Navigate to the first module if given module index doesn't represent any
  useEffect(() => {
    const moduleIndex = match ? Number(match.params.moduleId) - 1 : -1;
    const doesModuleExist = lesson.modules[moduleIndex];
    if (!match || !doesModuleExist) {
      navigate(buildUrl({ courseId, lessonId, moduleId: 1 }));
    }
  }, [navigate, courseId, lessonId, lesson, match, buildUrl]);

  const {
    activeMenuItem,
    breadcrumbs,
    menuItems,
    setActiveMenuItem,
  } = useLessonNavigation(lesson, course);

  const studentName = searchParams.get('studentName');

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="mb-1">{lesson.name}</h1>

        {role === 'teacher' && studentName && (
          <div className="px-5 py-2.5 text-White text-center bg-Green rounded-3xl flex gap-2 text-sm items-center">
            <IconInfo />
            {t('You are currently viewing {{studentName}} responses', { studentName })}
          </div>
        )}
      </div>

      <Breadcrumbs breadcrumbs={breadcrumbs} />

      <section className="grid grid-cols-[240px_auto] 2xl:grid-cols-[240px_auto_208px] gap-6 mt-9 pb-10">
        <SidebarMenu
          activeMenuItem={activeMenuItem}
          className="-ml-6"
          menuItems={menuItems}
          setActiveMenuItem={setActiveMenuItem}
        />

        <Routes>
          <Route
            element={<LessonModule lesson={lesson} />}
            path="modules/:moduleId"
          />
        </Routes>
      </section>
    </div>
  );
}

function LessonProvider() {
  const { course, lesson } = useCurrentLesson();

  if (!lesson || !course) {
    return (
      <Loading />
    );
  }

  return (
    <LessonLayout />
  );
}

function Lesson() {
  return (
    <CurrentLessonProvider>
      <LessonProvider />
    </CurrentLessonProvider>
  );
}

export default Lesson;
