import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

function useDiscussionForm({ discussion }) {
  const { t } = useTranslation();

  const initialFormData = discussion ? ({
    body: discussion.value,
    classroom: {
      id: discussion.classroomId,
    },
    title: discussion.title,
  }) : ({
    body: '',
    classroom: null,
    title: '',
  });

  const validationResolver = yupResolver(yup.object({
    body: yup
      .string()
      .required(t('Field is required')),
    classroom: yup
      .object()
      .required(t('Field is required')),
    title: yup
      .string()
      .required(t('Field is required')),
  }).required());

  return useForm({
    defaultValues: initialFormData,
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: validationResolver,
  });
}

export default useDiscussionForm;
