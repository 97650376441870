import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import QuestionnaireForm from 'src/views/Study/LessonModule/QuestionnaireForm';

function QUESTIONNAIRE(props) {
  const { className, contentItem } = props;

  return (
    <div className={classNames('QUESTIONNAIRE', className)}>
      <fieldset className="grid gap-4">
        {/* <legend className="my-2 text-sm html prose"
         dangerouslySetInnerHTML={{ __html: contentItem.name }} /> */}

        {contentItem.questionnaires.map((questionnaire) => (
          <QuestionnaireForm
            key={questionnaire.id}
            questionnaire={questionnaire}
          />
        ))}
      </fieldset>
    </div>
  );
}

QUESTIONNAIRE.defaultProps = {
  className: '',
};

QUESTIONNAIRE.propTypes = {
  className: PropTypes.string,
  contentItem: PropTypes.object.isRequired,
};

export default QUESTIONNAIRE;
