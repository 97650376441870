import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Popover } from 'react-tiny-popover';

import { ReactComponent as IconDelete } from 'src/images/icons/delete.svg';
import { ReactComponent as IconEdit } from 'src/images/icons/edit.svg';
import { ReactComponent as IconEllipsisVertical } from 'src/images/icons/ellipsis-vertical.svg';

function ClassroomActionsDropdown(props) {
  const { actions } = props;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const triggerRef = useRef();
  const { t } = useTranslation();

  const getContent = () => (
    <div
      className="CurrentUserWidget__popover px-5 py-3 w-60 shadow-ho-default rounded-[10px] bg-White"
      onClick={() => setIsPopoverOpen(false)}
      onKeyDown={() => {}}
      role="button"
      tabIndex="0"
    >
      <nav>
        <ul>
          <li>
            <button
              className="flex items-center gap-3 py-2.5 hover:text-MainYellow"
              onClick={actions.handleDelete}
            >
              <IconDelete />
              {t('Delete')}
            </button>
          </li>
          <li>
            <button
              className="flex items-center gap-3 py-2.5 hover:text-MainYellow"
              onClick={actions.handleEdit}
            >
              <IconEdit />
              {t('Edit')}
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );

  return (
    <Popover
      align="end"
      boundaryElement={triggerRef.current}
      containerStyle={{ zIndex: 100 }}
      content={getContent}
      isOpen={isPopoverOpen}
      onClickOutside={() => setIsPopoverOpen(false)}
      padding={10}
      positions={['bottom']}
      reposition
    >
      <button
        className="flex p-2 rounded-full bg-White"
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
      >
        <IconEllipsisVertical />
      </button>
    </Popover>
  );
}

ClassroomActionsDropdown.propTypes = {
  actions: PropTypes.object.isRequired,
};

export default ClassroomActionsDropdown;
