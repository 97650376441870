import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactComponent as IconBook } from 'src/images/icons-deprecated/book.svg';
import { ReactComponent as IconQuiz } from 'src/images/icons-deprecated/quiz.svg';
import { ReactComponent as IconUsers } from 'src/images/icons-deprecated/users.svg';

function ModuleCard(props) {
  const { course } = props;

  const { t } = useTranslation();

  return (
    <div className="ModuleCard flex flex-col ho-card h-[332px] max-h-[332px]">
      <div
        className="flex-1 w-full bg-ho-grey-light rounded-tr-[20px] rounded-tl-[20px] min-h-[223px]"
        style={{
          backgroundImage: `url(${course.imageUrl})`,
          backgroundPositionY: '50%',
          backgroundSize: 'cover',
        }}
      />
      <div className="px-8 pb-6">
        <h4 className="mt-4">{course.name}</h4>
        <ul className="flex gap-3 mt-6">
          <li className="flex gap-2 items-center text-xs font-light">
            <IconBook className="stroke-ho-blue-300" />
            {t('{{count}} Lessons', { count: course.stats.lessons })}
          </li>
          <li className="flex gap-2 items-center text-xs font-light">
            <IconQuiz className="stroke-ho-blue-300" />
            {t('{{count}} Quizzes', { count: course.stats.quizCount })}
          </li>
          <li className="flex gap-2 items-center text-xs font-light">
            <IconUsers className="stroke-ho-blue-300" />
            {t('{{count}} Students', { count: course.stats.students })}
          </li>
        </ul>
      </div>
    </div>
  );
}

ModuleCard.propTypes = {
  course: PropTypes.object.isRequired,
};

export default ModuleCard;
