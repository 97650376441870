import React from 'react';

import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import ModuleCard from 'src/components/common/ModuleCard';
import { useDashboard } from 'src/views/Teacher/Dashboard/useDashboard';

function CurrentCourseSection() {
  const { course } = useDashboard();

  const { t } = useTranslation();

  return (
    <section className="CurrentCourseSection">
      <h4>{t('Current course')}</h4>

      <div className="mt-5">
        <NavLink to={`/teacher/courses/${course.id}`}>
          <ModuleCard course={course} />
        </NavLink>
      </div>
    </section>
  );
}

export default CurrentCourseSection;
