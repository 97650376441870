import React from 'react';

import classNames from 'classnames';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { NavLink, useParams } from 'react-router-dom';

import ProgressBar from 'src/components/ui-kit/ProgressBar';
import { useCurrentUser } from 'src/contexts/useCurrentUser';

import ClassroomMembersRowActions from './ClassroomMembersRowActions';

function getNameText(member, me) {
  const { firstName, lastName, userId } = member;
  if (userId === me.id) {
    if (firstName && lastName) {
      return i18n.t('{{firstName}} {{lastName}} (You)', { firstName, lastName });
    }
    return i18n.t('You');
  }
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  return '';
}

function ClassroomMembersRow(props) {
  const { className, classroom, hasStats, member, onClickDeleteButton, stats } = props;

  const { classroomId } = useParams();
  const { me } = useCurrentUser();

  const progress = stats[member.userId] ? Math.floor(stats[member.userId] * 100) : 0;
  const isTeacher = member.userId === me.id;
  const nameText = getNameText(member, me);

  return (
    <tr className={classNames('ClassroomMembersRow grid items-center pl-8 pr-4 w-full h-[52px] items-center hover:bg-Grey', className, {
      'bg-Grey': isTeacher,
    })}
    >
      <td>
        {!isTeacher && hasStats ? (
          <NavLink
            className="text-Blue hover:underline"
            to={`/teacher/classrooms/${classroomId}/students/${member.userId}`}
          >
            {nameText}
          </NavLink>
        ) : (nameText)}
      </td>
      <td>
        <a
          className="text-Blue underline"
          href={`mailto:${member.email}`}
          rel="noreferrer"
          target="_blank"
        >
          {member.email}
        </a>
      </td>
      <td className="pr-4">
        {!isTeacher && (
          <ProgressBar
            className="w-full"
            progress={progress}
            rounded
            showText
          />
        )}
      </td>
      <td className="text-right">
        {!isTeacher && (
          <ClassroomMembersRowActions
            classroom={classroom}
            member={member}
            onClickDeleteButton={onClickDeleteButton}
          />
        )}
      </td>
    </tr>
  );
}

ClassroomMembersRow.propTypes = {
  className: PropTypes.string.isRequired,
  classroom: PropTypes.object.isRequired,
  hasStats: PropTypes.bool.isRequired,
  member: PropTypes.object.isRequired,
  onClickDeleteButton: PropTypes.func.isRequired,
  stats: PropTypes.object.isRequired,
};

export default ClassroomMembersRow;
