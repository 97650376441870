export const FEATURE_FLAGS = {
  COURSE_STARS: 'COURSE_STARS',
  EDIT_COURSE: 'EDIT_COURSE',
  MANAGE_CLASSES: 'MANAGE_CLASSES',
  WISH_LIST: 'WISH_LIST',
};

const enabledFeatures = [
  // FEATURE_FLAGS.MANAGE_CLASSES,
];

export function isFeatureEnabled(featureKey) {
  return enabledFeatures.includes(featureKey);
}
