import React from 'react';

import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { useCurrentUser } from 'src/contexts/useCurrentUser';
import { ReactComponent as IconComments } from 'src/images/icons/comments.svg';
import { ReactComponent as IconImage } from 'src/images/icons/image.svg';

function DiscussionItem(props) {
  const { discussion } = props;

  const { role } = useCurrentUser();

  return (
    <div className="DiscussionItem grid items-center ho-card grid-cols-[290px_1fr] items-center overflow-hidden">
      <div className="bg-MainBlue h-full text-White flex items-center justify-center">
        <IconImage className="transform scale-[2.5]" />
      </div>

      <div className="p-6">
        <div className="flex justify-between items-center">
          <div className="grid gap-1">
            <NavLink className="text-Blue hover:underline text-sm" to={`/teacher/classrooms/${discussion.classroomId}`}>
              {discussion.classroomName}
            </NavLink>

            <NavLink
              key={discussion.id}
              className="h4"
              to={`/${role}/discussion/${discussion.id}`}
            >
              {discussion.title}
            </NavLink>
          </div>

          <span className="flex gap-1 items-center">
            <IconComments />
            {discussion.commentsCount}
          </span>
        </div>
        <p className="html prose line-clamp-2 mt-2" dangerouslySetInnerHTML={{ __html: discussion.value }} />
      </div>
    </div>
  );
}

DiscussionItem.propTypes = {
  discussion: PropTypes.object.isRequired,
};

export default DiscussionItem;
