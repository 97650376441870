import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

function TEXT(props) {
  const { className, contentItem } = props;

  return (
    <div
      className={classNames('TEXT prose html', className)}
      dangerouslySetInnerHTML={{ __html: contentItem.body }}
    />
  );
}

TEXT.defaultProps = {
  className: '',
};

TEXT.propTypes = {
  className: PropTypes.string,
  contentItem: PropTypes.object.isRequired,
};

export default TEXT;
