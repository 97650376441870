import React from 'react';

import PropTypes from 'prop-types';
import ReactSelect, { components } from 'react-select';

import { statuses, useCourses } from 'src/api/courses';
import Avatar from 'src/components/common/Avatar';
import { useCurrentUser } from 'src/contexts/useCurrentUser';

// eslint-disable-next-line react/prop-types
function Control({ children, ...props }) {
  // eslint-disable-next-line react/prop-types
  const [selectedCourse] = props.getValue();
  return (
    <components.Control {...props}>
      <div className="flex flex-1 items-center py-3 px-4 cursor-pointer">
        {selectedCourse && (
          <Avatar image={selectedCourse.imageUrl} size="sm" />
        )}
        {children}
      </div>
    </components.Control>
  );
}

function SelectCourseDropdown(props) {
  const { onChange, placeholder, value } = props;
  const { role } = useCurrentUser();

  const { isFetching, items: courses = [] } = useCourses({
    role,
    size: 20,
    status: statuses.PURCHASED_ONLY,
  });

  return (
    <ReactSelect
      className="SelectCourseDropdown"
      components={{
        Control,
        IndicatorSeparator: () => null,
      }}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      isClearable={false}
      isDisabled={isFetching}
      isSearchable={false}
      onChange={onChange}
      options={courses}
      placeholder={placeholder}
      value={value}
    />
  );
}

SelectCourseDropdown.defaultProps = {
  placeholder: '',
  value: null,
};

SelectCourseDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.object,
};

export default SelectCourseDropdown;
