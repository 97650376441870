import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useMatch, useNavigate } from 'react-router-dom';

import NavigationItemModel from 'src/models/NavigationItemModel';

function TopBarMenuItem(props) {
  const { navigationItem } = props;
  const { Icon, absolutePath, action, name, to } = navigationItem;

  const navigate = useNavigate();

  const match = useMatch({
    path: absolutePath,
  });

  const onClick = () => {
    if (to) {
      navigate(to);
    } else if (action) {
      action();
    }
  };

  const className = classNames(
    'button--text grid gap-1 place-items-center text-xs font-medium',
    {
      'text-MainYellow': match,
      'text-white hover:text-MainYellow': !match,
    },
  );

  return (
    <li className="TopBarMenuItem">
      <button className={className} onClick={onClick}>
        {Icon}
        {name}
      </button>
    </li>
  );
}

TopBarMenuItem.propTypes = {
  navigationItem: PropTypes.instanceOf(NavigationItemModel).isRequired,
};

export default TopBarMenuItem;
