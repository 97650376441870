import React from 'react';

import { useTranslation } from 'react-i18next';

import SelectCourseDropdown from 'src/components/common/SelectCourseDropdown';
import { useDashboard } from 'src/views/Teacher/Dashboard/useDashboard';

function SelectCourseSection() {
  const { course, setCourse } = useDashboard();

  const { t } = useTranslation();

  return (
    <div className="SelectCourseSection col-span-2">
      <h4>{t('Select course')}</h4>

      <div className="mt-5">
        <SelectCourseDropdown
          onChange={setCourse}
          value={course}
        />
      </div>
    </div>
  );
}

export default SelectCourseSection;
