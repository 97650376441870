import React from 'react';

import Lottie from 'lottie-react';
import { useTranslation } from 'react-i18next';

import Page from 'src/components/layout/Page';
import LottieAnimation from 'src/images/lottie/404.json';

function NotFound() {
  const { t } = useTranslation();
  return (
    <Page title={t('Page Not found')}>
      <Lottie animationData={LottieAnimation} className="mx-auto max-w-[1000px]" />
      <p className="text-center mt-10 text-xl">{t('The page you are looking for has been lost in space')}</p>
    </Page>
  );
}

export default NotFound;
