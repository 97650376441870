import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useClassroomsMembers } from 'src/api/classrooms';
import { useTeacherClassroomStats } from 'src/api/stats';
import DeleteMemberModal from 'src/components/modals/DeleteMember/DeleteMemberModal';
import InviteMembersModal from 'src/components/modals/InviteMembersModal/InviteMembersModal';
import DataGrid from 'src/components/ui-kit/DataGrid';
import { useModal } from 'src/helpers/useModal';
import { ReactComponent as IconAddStudent } from 'src/images/icons/add-student.svg';
import ClassroomMembersRow from 'src/views/Teacher/Classroom/ClassroomMembers/ClassroomMembersRow';

function ClassroomMembers(props) {
  const { classroom } = props;

  const hasStats = classroom.launchStatus === 'STARTED';

  const {
    data: members = [],
    isFetching,
    refetch,
  } = useClassroomsMembers(classroom.id);

  const { data: stats = {} } = useTeacherClassroomStats(
    { classroomId: classroom.id },
    { enabled: hasStats },
  );

  const { t } = useTranslation();

  const { closeModal, openModal } = useModal();

  const onClickInviteButton = () => {
    openModal(InviteMembersModal, {
      classroom,
      onSuccess: refetch,
    });
  };

  const onClickDeleteButton = (member) => {
    openModal(DeleteMemberModal, {
      classroom,
      member,
      onClose: closeModal,
      onSuccess: async () => {
        await refetch();
        closeModal();
      },
    });
  };

  const gridColsClass = 'grid-cols-[2fr_2fr_1fr_112px]';

  // TODO: Refactor table implementation make it a general component and avoid duplicates
  return (
    <section className="ClassroomMembers ho-card">
      <div className="h-20 flex items-center justify-between px-8">
        <h4>{t('Members')}</h4>

        <button
          className="button--secondary-outlined button--sm flex items-center justify-center w-auto h-7 gap-1"
          onClick={onClickInviteButton}
        >
          <IconAddStudent />
          {t('Invite members')}
        </button>
      </div>
      <DataGrid
        emptyText={t('You have no invited students')}
        hasNextPage={false}
        isEmpty={members.length === 0}
        isFetching={isFetching}
        onLoadMore={() => {}}
      >
        <table className="table-grid text-sm">
          <thead>
            <tr className={classNames('grid pl-8 pr-4 bg-MainYellow/[0.15] h-10 items-center', gridColsClass)}>
              <th className="font-bold">{t('Name')}</th>
              <th className="font-bold">{t('Email')}</th>
              <th className="font-bold justify-end">{t('Progress')}</th>
              <th className="text-center">{t('Actions')}</th>
            </tr>
          </thead>

          <tbody className="mt-5">
            {members.map((member) => (
              <ClassroomMembersRow
                key={member.id}
                className={gridColsClass}
                classroom={classroom}
                hasStats={hasStats}
                member={member}
                onClickDeleteButton={onClickDeleteButton}
                stats={stats}
              />
            ))}
          </tbody>
        </table>
      </DataGrid>
    </section>
  );
}

ClassroomMembers.propTypes = {
  classroom: PropTypes.object.isRequired,
};

export default ClassroomMembers;
