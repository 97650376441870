import React, { useEffect } from 'react';

import { Route, Routes, useMatch, useNavigate } from 'react-router-dom';

import TopBarMenu from 'src/components/common/TopBarMenu/TopBarMenu';
import Layout from 'src/components/layout/Layout';
import Loading from 'src/components/ui-kit/Loading';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import DiscussionComments from 'src/views/Discussions/DiscussionComments/DiscussionComments';
import NotFound from 'src/views/NotFound/NotFound';
import Course from 'src/views/Student/Course/Course';
import Profile from 'src/views/Student/Profile/Profile';

import useStudentNavigation from './useStudentNavigation';
import Lesson from '../Study/Lesson/Lesson';

const hasComponent = (item) => !!item.component;

function Student() {
  const navigate = useNavigate();
  const { isLoading, me } = useCurrentUser();

  const { navigationItems } = useStudentNavigation();

  const match = useMatch({
    exact: true,
    path: '/student',
  });

  useEffect(() => {
    if (match) {
      navigate('/student/courses');
    }
  }, [navigate, match]);

  return (
    <Layout
      Menu={<TopBarMenu navigationItems={navigationItems} />}
      SidebarComponent={null}
    >
      {isLoading || !me ? (
        <Loading />
      ) : (
        <Routes>
          { navigationItems
            .filter(hasComponent)
            .map((navigationItem, index) => (
              <Route
                key={index}
                element={navigationItem.component}
                path={navigationItem.relativePath}
              />
            ))}
          <Route element={<Course />} path="courses/:courseId" />
          <Route element={<Lesson />} path="courses/:courseId/lessons/:lessonId/*" />
          <Route element={<Profile />} path="profile" />
          <Route element={<DiscussionComments />} path="discussion/:discussionId" />
          {me && (
          <Route element={<NotFound />} path="*" />
          )}
        </Routes>
      )}
    </Layout>
  );
}

export default Student;
