import React, { useState, useEffect } from 'react';

import { debounce } from 'lodash-es';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useCreateCourseNoteMutation } from 'src/api/courses';
import { useCurrentLesson } from 'src/contexts/useCurrentLesson';
import { useCurrentUser } from 'src/contexts/useCurrentUser';

function QuestionnaireForm(props) {
  const { questionnaire } = props;

  const [defaultValue, setDefaultValue] = useState('');
  const { role } = useCurrentUser();
  const { fetchNotes, questionnaireNotesMap } = useCurrentLesson();
  const { courseId } = useParams();

  const { isLoading: isSaving, mutateAsync: createCourseNote } = useCreateCourseNoteMutation();

  const { t } = useTranslation();

  const questionnaireNote = questionnaireNotesMap[questionnaire.id];

  useEffect(() => {
    if (questionnaireNote) {
      setDefaultValue(questionnaireNote.value);
    }
  }, [questionnaireNote]);

  const onSave = async (value) => {
    await createCourseNote({
      body: {
        questionnaireNote: {
          questionnaireId: questionnaire.id,
          value,
        },
      },
      courseId,
    });
    await fetchNotes();
  };

  const onInput = debounce(async (e) => {
    const { value } = e.target;
    await onSave(value);
  }, 700);

  const name = `textarea-${questionnaire.id}`;

  return (
    <form className="QuestionnaireForm" noValidate>
      <div>
        <label htmlFor={name}>
          <span className="html prose" dangerouslySetInnerHTML={{ __html: questionnaire.body }} />
        </label>

        <div className="bg-White p-6">
          <p className="text-xs">{t('Answer')}</p>
          <textarea
            className="textarea mt-2"
            defaultValue={defaultValue}
            id={name}
            name={name}
            onInput={onInput}
            placeholder={t('Enter response here')}
            readOnly={role === 'teacher'}
            rows={3}
          />
        </div>

        {isSaving && (
          <p className="text-ho-blue-600 text-sm">
            {t('Saving...')}
          </p>
        )}
      </div>
    </form>
  );
}

QuestionnaireForm.propTypes = {
  questionnaire: PropTypes.object.isRequired,
};

export default QuestionnaireForm;
