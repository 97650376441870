import React from 'react';

import { useTranslation } from 'react-i18next';

import WelcomeBanner from 'src/components/common/WelcomeBanner';
import Page from 'src/components/layout/Page';
import Loading from 'src/components/ui-kit/Loading';
import ClassPerformanceSection from
  'src/views/Teacher/Classroom/ClassPerformanceSection';
import DashboardEmptyState from 'src/views/Teacher/Dashboard/DashboardEmptyState';
// import ClassCompletionSection
//   from 'src/views/Teacher/Dashboard/GridItems/ClassCompletionSection/ClassCompletionSection';
import CurrentCourseSection from
  'src/views/Teacher/Dashboard/GridItems/CurrentCourseSection';
import SelectCourseSection from 'src/views/Teacher/Dashboard/GridItems/SelectCourseSection';
import { useDashboard } from 'src/views/Teacher/Dashboard/useDashboard';

function Dashboard() {
  const { hasClassrooms, hasCourses, isFetching } = useDashboard();

  const { t } = useTranslation();

  if (isFetching) {
    return (
      <Page className="Dashboard" title={t('Loading...')}>
        <Loading />
      </Page>
    );
  }

  return (
    <Page className="Dashboard grid gap-8" title={t('Dashboard')}>
      <WelcomeBanner />

      {hasCourses && hasClassrooms ? (
        <section className=" grid grid-cols-2 gap-8 pb-10">
          <SelectCourseSection />
          {/* <ClassCompletionSection /> */}
          <CurrentCourseSection />
          <ClassPerformanceSection />
        </section>
      ) : (
        <DashboardEmptyState />
      )}
    </Page>
  );
}

export default Dashboard;
