/* eslint-disable max-len, no-irregular-whitespace */
import React from 'react';

import { useTranslation } from 'react-i18next';

import Page from 'src/components/layout/Page';

// generated with https://wordtohtml.net/
function Privacy() {
  const { t } = useTranslation();

  return (
    <Page className="Terms w-full" title={t('Terms')}>
      <section className="html prose max-w-full px-24 py-5">
        <div>
          <h3>HistoryOwls.com Privacy Policy&nbsp;&nbsp;</h3>
        </div>
        <div>
          <p>
            The History Owls Privacy Policy at&nbsp;
            <a href="http://www.historyowls.com/privacy-policy/" rel="noreferrer noopener" target="_blank">www.HistoryOwls.com/privacy-policy/</a>
            {' '}
            (&ldquo;Privacy Policy&rdquo;) is incorporated by reference into this Agreement.&nbsp;
          </p>
        </div>
        <div>
          <ol start="1">
            <li>
              <p>User Accounts.&nbsp;To access certain features of the Website, you must create a user account (&ldquo;Account&rdquo;). You agree to maintain the accuracy of the Account information and to promptly update that information if it changes. Since you are responsible for all activities that occur under your Account, user name and password and therefore, we strongly encourage you to maintain the confidentiality of your you agree to keep your password confidential. You agree to promptly inform History Owls if there are any changes to such information. History Owls reserves the right to require you to alter your&nbsp;user&nbsp;name or password as part of regular maintenance and upgrades to the Website. You agree to defend, indemnify and hold History Owls harmless from and against any claims, liabilities or expenses arising out of use of the Website or Services by someone using your credentials, assuming the claims do not arise as a result of an error or omission by History Owls. You agree not to: (a) provide any false information as part of your Account information; (b) create an Account for anyone other than yourself (except for those instances when you are creating the Account on behalf of an entity you may represent and bind to the terms of this Agreement); (c) create or use more than one Account; (d) transfer your Account to anyone else; (e) permit others to use your Account; or (f) use or access other persons&rsquo; Accounts.&nbsp;</p>
            </li>
          </ol>
        </div>
        <div>
          <ol start="2">
            <li>
              <p>User Content.&nbsp;</p>
            </li>
          </ol>
        </div>
        <div>
          <ol start="1">
            <li>
              <p>License.&nbsp;By posting or submitting content to the Website (&ldquo;User Content&rdquo;), you grant History Owls an irrevocable, non-exclusive, royalty-free and paid, worldwide, transferable license, with the right to sublicense through multiple tiers, to reproduce, distribute, modify, publicly display, publicly perform, prepare derivative works of, transmit, and otherwise use your User Content in any manner and for any purpose associated with History Owls&rsquo; operations. You represent and warrant that: (a) you have the right to grant the foregoing license and to post and submit the User Content; and (b) the User Content does not infringe, misappropriate, or violate any third-party rights (including intellectual property rights). You are solely responsible for and assume all risk associated with your User Content.&nbsp;</p>
            </li>
          </ol>
        </div>
        <div>
          <ol start="2">
            <li>
              <p>User Guidelines.&nbsp;You represent, warrant, and agree to the following in your access and use of the Website:&nbsp;</p>
            </li>
          </ol>
        </div>
        <div>
          <ul>
            <li>
              <p>
                you will comply with all laws, including intellectual property and privacy laws;&nbsp;
                <br />
&nbsp;
              </p>
            </li>
            <li>
              <p>
                you will not post inappropriate, inaccurate, or objectionable content to the Website;&nbsp;
                <br />
&nbsp;
              </p>
            </li>
            <li>
              <p>
                you will not bully, harass, or advocate harassment of another user or person;&nbsp;
                <br />
&nbsp;
              </p>
            </li>
            <li>
              <p>
                you will not solicit any personally identifiable information or passwords for commercial or unlawful purposes from other users;&nbsp;
                <br />
&nbsp;
              </p>
            </li>
            <li>
              <p>
                you will not post content that constitutes &ldquo;junk mail&rdquo; or &ldquo;chain letters&rdquo;;&nbsp;
                <br />
&nbsp;
              </p>
            </li>
            <li>
              <p>
                you will not post content that is obscene ort that promotes racism, bigotry, hatred, or physical harm of any kind against any group or individual;&nbsp;
                <br />
&nbsp;
              </p>
            </li>
            <li>
              <p>
                you will not post or upload any virus, time bomb, worm, corrupted file, or other software routine capable of disrupting, disabling, or harming the operation of, or providing unauthorized access, to the Website;&nbsp;
                <br />
&nbsp;
              </p>
            </li>
            <li>
              <p>
                you will not use the Website to do or promote anything that is unlawful, illegal, misleading, defamatory, or libelous; and&nbsp;
                <br />
&nbsp;
              </p>
            </li>
            <li>
              <p>you will not promote, or upload or post anything that contains, an illegal and/or unauthorized copy of another person&rsquo;s copyrighted work (whether marked as such or not).&nbsp;</p>
            </li>
          </ul>
        </div>
        <div>
          <p>If you violate any guideline listed above or any term of this Agreement, History Owls reserves the right (but is not obligated) to investigate and take appropriate legal action in its sole discretion, including removing User Content from the Website, notifying the appropriate authorities, barring you from accessing the Website and terminating your Account.&nbsp;</p>
        </div>
        <div>
          <ol start="3">
            <li>
              <p>Proprietary Rights.&nbsp;</p>
            </li>
          </ol>
        </div>
        <div>
          <ol start="1">
            <li>
              <p>Use of the Service.&nbsp;Subject to the terms of the Agreement and any separate, explicit agreement you enter into with History Owls for specific content, History Owls grants you during the term of this Agreement a limited, non-exclusive, non-transferable, revocable license to access and use the Website and download any content made available by History Owls for download through the Website, in each case solely for (i) your personal, non-commercial purposes, if you are an individual, or (ii) your internal business purposes, if you are a company or other legal entity. You agree not to: (a) rent, lease, loan, sell, license, duplicate, copy or transfer any aspect of the Website or content contained therein, excluding User Content (&ldquo;Website Content&rdquo;) to any third party; (b) interfere with, disrupt, alter, translate, or modify the Website or any part thereof, or create an undue burden on the Website or the networks or services connected to the Website; (c) reverse engineer decompile, disassemble, or reverse compile the Website or Website Content; or (d) introduce software or automated agents or scripts to the Website so as to produce multiple accounts, generate automated searches, requests and queries, or strip, scrape, or mine data from the Website.&nbsp;</p>
            </li>
          </ol>
        </div>
        <div>
          <ol start="2">
            <li>
              <p>Ownership.&nbsp;History Owls and its licensors own the Website, all Website Content, and all intellectual property rights related to the foregoing. Any unauthorized reproduction, modification, distribution, transmission, display, or performance of any portion of the Website or Website Content is strictly prohibited. History Owls reserves all rights not expressly granted under this Agreement. There exist no implied licenses in this Agreement.&nbsp;</p>
            </li>
          </ol>
        </div>
        <div>
          <ol start="3">
            <li>
              <p>Feedback.&nbsp;History Owls will treat any feedback or suggestions you provide to it as non-confidential and non-proprietary. You grant History Owls an irrevocable license and right to use any feedback or suggestions you provide in any manner and for any purpose with no obligation to compensate you.&nbsp;</p>
            </li>
          </ol>
        </div>
        <div>
          <ol start="4">
            <li>
              <p>Copyright Policy and the Digital Millennium Copyright Act (&ldquo;DMCA&rdquo;). History Owls is committed to&nbsp;respecting and protecting the legal rights of its own copyrights and those of other copyright owners.&nbsp;&nbsp;</p>
            </li>
          </ol>
        </div>
        <div>
          <ol start="1">
            <li>
              <p>History Owls Copyrights.&nbsp;You may not post, distribute, or reproduce any History Owls copyrighted material, trademarks, or other proprietary information unless you have the right; i.e., an explicit written license, to do so. It is History Owls&rsquo; policy to, in its sole discretion and without advance notice, terminate the Account of any user that infringes copyright or other intellectual property rights of History Owls.&nbsp;</p>
            </li>
          </ol>
        </div>
        <div>
          <ol start="2">
            <li>
              <p>Other Copyrights.&nbsp;History Owls adheres to the following notice and take down policy, in full compliance with Section 512(c)(3) of the DMCA (17 U.S.C. &sect; 512 et seq.). If you believe the Website Content or User Content infringes upon your intellectual property rights, please submit a notification alleging such infringement (a &ldquo;DMCA Takedown Notice&rdquo;). To be valid, a DMCA Takedown Notice must: (a) be provided to History Owls&rsquo; Copyright Agent, and (b) include:&nbsp;</p>
            </li>
          </ol>
        </div>
        <div>
          <ul>
            <li>
              <p>A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;&nbsp;</p>
            </li>
            <li>
              <p>Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works;&nbsp;</p>
            </li>
            <li>
              <p>Identification of the material claimed to be infringing or to be the subject of infringing activity and that you believe should be removed or access disabled, and information reasonably sufficient to permit the service provider to locate the material;&nbsp;</p>
            </li>
            <li>
              <p>Information reasonably sufficient to permit the service provider to contact you, such as an address, telephone number, and, if available, an electronic mail;&nbsp;</p>
            </li>
            <li>
              <p>A statement you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and&nbsp;</p>
            </li>
            <li>
              <p>
                A statement that, under penalty of perjury, the information in the notification is accurate and you are authorized to act on behalf of the owner of the exclusive right that is allegedly infringed.&nbsp;
                <br />
                <br />
                Only DMCA Takedown Notices should go to the Copyright Agent, and any other feedback, comments, or other communications should be directed to the applicable customer service links posted on the Website. You acknowledge that your DMCA Takedown Notice must comply with all the requirements of this Section for History Owls to be authorized to takedown any Website Content or User Content.&nbsp;
                <br />
                <br />
                Designated Agent:&nbsp;
                <br />
                <br />
                History Owls, LLC&nbsp;
                <br />
                ATTN: Copyright Agent&nbsp;
                <br />
                [address]&nbsp;
              </p>
            </li>
            <li>
              <p>
                Email address: [email]&nbsp;
                <br />
&nbsp;
              </p>
            </li>
          </ul>
        </div>
        <div>
          <ol start="5">
            <li>
              <p>Third Party Websites and Services.&nbsp;The Website may contain links to other Internet websites and services maintained by third parties. These links are provided for your reference only. History Owls does not control, operate, or endorse in any respect information, products, or services on such third-party sites and is not responsible for such information, products, or services. Many third-party sites have their own terms of use and privacy policies that differ from those of History Owls. This Agreement and incorporated Privacy Policy only apply to the Website and do not apply to any other site or service.&nbsp;&nbsp;</p>
            </li>
          </ol>
        </div>
        <div>
          <ol start="6">
            <li>
              <p>Availability and Modification of Service.&nbsp;History Owls reserves the right, from time to time, to suspend, modify, or discontinue the Website and Services, in whole or in part, with or without notice. You agree that History Owls will not be liable to you or to any third party for any discontinuance, suspension, or modification of the Website or Services, in whole or in part.&nbsp;&nbsp;</p>
            </li>
          </ol>
        </div>
        <div>
          <ol start="7">
            <li>
              <p>Termination.&nbsp;Subject to this Section, this Agreement will remain in full force and effect while you use the Website or maintain an Account. You may terminate your Account at any time and for any reason through the appropriate account management page on the Website. History Owls reserves the right to terminate your Account immediately effective upon sending notice to you at the email address you provide when creating your Account. You agree that we will have no liability to you for any costs, expenses, losses, damages, or liabilities arising out of or relating to our termination of your Account, your access to and use of the Website and Website Content, or this Agreement. Even after this Agreement is terminated for any reason, those provisions of this Agreement that reasonably should survive a termination or expiration will remain in effect.&nbsp;</p>
            </li>
          </ol>
        </div>
        <div>
          <ol start="8">
            <li>
              <p>Warranty Disclaimer. TO THE EXTENT PERMITTED UNDER APPLICABLE LAW, HISTORY OWLS PROVIDES THE SITE, SITE CONTENT AND SERVICES &quot;AS-IS&quot; AND &ldquo;AS AVAILABLE&rdquo; AND EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, AND NON-INFRINGEMENT. HISTORY OWLS MAKES NO WARRANTY THAT THE SITE OR SITE CONTENT WILL BE UNINTERRUPTED, FREE OF VIRUSES OR OTHER HARMFUL CODE, TIMELY, SECURE, OR ERROR-FREE. HISTORY OWLS DOES NOT ASSUME ANY OBLIGATION TO MONITOR ACTIVITIES CONDUCTED ON THE SITE.&nbsp;</p>
            </li>
          </ol>
        </div>
        <div>
          <ol start="9">
            <li>
              <p>Limitation of Liability.&nbsp;TO THE EXTENT PERMITTED UNDER APPLICABLE LAW, (A) HISTORY OWLS WILL NOT BE LIABLE FOR ANY LOSS OF USE, LOST PROFITS, OR INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES IN CONNECTION WITH THE SITE, SITE CONTENT, OR THIS AGREEMENT, EVEN IF HISTORY OWLS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES; AND (B) HISTORY OWLS&rsquo; TOTAL CUMULATIVE LIABILITY IN CONNECTION WITH THE SITE, SITE CONTENT, AND THIS AGREEMENT WILL AT ALL TIMES BE LIMITED TO FIFTY U.S. DOLLARS (U.S. $50.00). THE EXISTENCE OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT. IN NO EVENT WILL HISTORY OWLS&rsquo; OFFICERS, DIRECTORS, EMPLOYEE, AGENTS OR AFFILIATED COMPANIES HAVE ANY LIABILITY UNDER OR RESULTING FROM THIS AGREEMENT.&nbsp;</p>
            </li>
          </ol>
        </div>
        <div>
          <ol start="10">
            <li>
              <p>User Indemnification.&nbsp;You agree to indemnify and hold History Owls and its affiliates, officers, directors, agents, and employees harmless from any loss, liability, claim, demand, costs, or expenses, including reasonable attorneys&rsquo; fees, arising from or related to your: (a) breach of the terms of this Agreement; (b) User Content; (c) use of the Website; or (d) violation of applicable laws or any of the rights of third parties.&nbsp;</p>
            </li>
          </ol>
        </div>
        <div>
          <ol start="11">
            <li>
              <p>Electronic Communications.&nbsp;The communications between you and History Owls use electronic means, whether you use the Website or send us emails, or whether History Owls posts notices on the Website or communicates with you via email. For contractual purposes, you (a) consent to receive communications from History Owls in an electronic form; and (b) agree that all terms, agreements, notices, disclosures, and other communications that History Owls provides to you electronically satisfy any legal requirement that such communications would satisfy if it were in a hardcopy writing. The foregoing does not affect your non-waivable rights.&nbsp;</p>
            </li>
          </ol>
        </div>
        <div>
          <ol start="12">
            <li>
              <p>Governing Law; Arbitration.&nbsp;This Agreement, and any claim, dispute or other controversy related to this Agreement, will be governed by the laws of the State of New York, without giving effect to any conflict of laws principles that require the application of the laws of a different jurisdiction. Any action or proceeding related to this Agreement must be brought in a federal or state court in New York County, New York, and each party irrevocably submits to the jurisdiction and venue of any such court in any such action or proceeding, except that: (i) a party bringing an action may choose to resolve the dispute through binding non-appearance-based arbitration in accordance with the following: (a) the arbitration will be provided through a nationally-recognized alternative dispute resolution provider mutually agreed upon by the parties; (b) the arbitration will be conducted in one or more of the following manners at the option of the party initiating arbitration: telephone, online, or written submissions; (c) the arbitration will not involve any personal appearances by the parties or witnesses unless otherwise agreed by the parties; and (d) any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction; and (ii) History Owls may seek injunctive relief in any court having jurisdiction to protect its intellectual property or confidential or proprietary information.&nbsp;</p>
            </li>
          </ol>
        </div>
        <div>
          <ol start="13">
            <li>
              <p>General.&nbsp;If any provision of this Agreement is determined to be unenforceable, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect. All waivers by History Owls are effective only in writing. Any waiver or failure by History Owls to enforce any provision on one occasion will not be deemed a waiver of any other provision or of such provision on any other occasion. Notwithstanding the foregoing, History Owls may seek injunctive or other equitable relief, wherever it deems appropriate, to protect or enforce its rights under this Agreement. The headings of Sections of this Agreement are for convenience and are not to be used in interpreting this Agreement. &ldquo;Includes&rdquo; and &ldquo;including&rdquo; are not limiting. This Agreement and the Privacy Policy constitute the final, complete, and exclusive agreement between the parties regarding the subject hereof and supersede all prior or contemporaneous agreements, understandings, and communication, whether written or oral. If a conflict occurs or inconsistency between the terms of this Agreement and any other terms, this Agreement will take precedence and govern.&nbsp;</p>
            </li>
          </ol>
        </div>
        <div>
          <ol start="14">
            <li>
              <p>Changes and Updates.&nbsp;History Owls may amend this Agreement or the Privacy Policy from time to time. If the changes made to the Agreement or the Privacy Policy are material, History Owls will notify you by posting the change on the Website or sending you an email to the last email address you provided (if any). Any changes to this Agreement or the Privacy Policy will be effective immediately for any new users of the Website; otherwise, these changes will be effective upon the earlier of thirty (30) calendar days following the dispatch of an email notice to you or thirty (30) calendar days following posting a notice on the Website, whichever is earlier. You are responsible at all times for updating your Account to provide to History Owls your current email address. If the last email address you have provided is not valid or is not usable for delivering to you the notice described above, dispatch of the email containing such notice will nonetheless constitute effective notice of the changes described in the notice. Continued use of the Website or accessing the Website Content following notice of such changes shall indicate your acknowledgment of, and agreement to be bound by, such changes. Except as otherwise provided in this section, no amendment to this Agreement or the Privacy Policy will be valid unless in a writing hand-signed by the parties.&nbsp;</p>
            </li>
          </ol>
        </div>
        <div>
          <ol start="15">
            <li>
              <p>Termination.&nbsp;Notwithstanding any of these Website Terms, History Owls reserves the right, without notice and in its sole discretion, to terminate your account or to block your use of the Website.&nbsp;</p>
            </li>
          </ol>
        </div>
        <div>
          <ol start="16">
            <li>
              <p>Questions and Contact Information.&nbsp;Please contact us if you have any questions or comments about the Website or Website Terms.&nbsp;</p>
            </li>
          </ol>
        </div>
        <div>
          <p>&nbsp;</p>
        </div>
      </section>
    </Page>
  );
}

export default Privacy;
