import i18n from 'i18next';

import MenuDropdownItemModel from 'src/models/MenuDropdownItemModel';
import EditClassNameModal from 'src/views/Teacher/ManageClasses/EditClassNameModal';
import ManageCoTeachersModal from 'src/views/Teacher/ManageClasses/ManageCoTeachersModal';

function useClassActions({ openModal }) {
  const getDropdownItems = (data) => [
    new MenuDropdownItemModel(i18n.t('Edit class name'), () => {
      openModal(EditClassNameModal, { data });
    }),
    new MenuDropdownItemModel(i18n.t('Manage Co-Teachers'), () => {
      openModal(ManageCoTeachersModal, { data });
    }),
    new MenuDropdownItemModel(
      data.status === 'archived' ? i18n.t('Restore') : i18n.t('Archive'),
      () => {},
    ),
  ];

  return {
    getDropdownItems,
  };
}

export default useClassActions;
