import React, { useLayoutEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Modal from 'src/components/ui-kit/Modal';
import useValidationHelpers from 'src/helpers/useValidationHelpers';

const schema = () => yup.object({
  name: yup
    .string()
    .required(i18n.t('Field is required')),
}).required();

const initialFormData = ({ name }) => ({
  name,
});

function EditClassNameModal(props) {
  const { data, isOpen, onClose } = props;
  const { t } = useTranslation();

  const { formState, handleSubmit, register, setFocus } = useForm({
    defaultValues: initialFormData(data),
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema()),
  });

  const { getFieldError } = useValidationHelpers({
    formState,
  });

  useLayoutEffect(() => {
    setTimeout(() => setFocus('name'));
  }, [setFocus]);

  const onSubmit = () => {};

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('Edit class name')}
    >
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="grid">
          <label className="label" htmlFor="EditClassNameModal__name">
            {t('Enter class name')}
            <span className="label-star"> *</span>
          </label>
          <input
            className={classNames('input mt-2', { 'input--has-error': getFieldError('name') })}
            id="EditClassNameModal__name"
            name="name"
            {...register('name')}
          />
          <p className="error-message mt-1">{getFieldError('email')}</p>
        </div>

        <div className="mt-10 flex justify-center">
          <button
            className={classNames('button--secondary', { 'visually-disabled': !formState.isValid })}
            type="submit"
          >
            {t('Save')}
          </button>
        </div>
      </form>
    </Modal>
  );
}

EditClassNameModal.defaultProps = {
  onClose: () => {},
};

EditClassNameModal.propTypes = {
  data: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default EditClassNameModal;
