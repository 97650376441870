import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as IconBook } from 'src/images/icons/book.svg';
// import { ReactComponent as IconDiscussion } from 'src/images/icons/discussion.svg';
import { ReactComponent as IconDocuments } from 'src/images/icons/documents.svg';
import { ReactComponent as IconOpenBook } from 'src/images/icons/open-book.svg';
import { ReactComponent as IconSources } from 'src/images/icons/sources.svg';
// import { ReactComponent as IconTwoUsers } from 'src/images/icons/two-users.svg';
import { ReactComponent as IconUser } from 'src/images/icons/user.svg';
import BreadcrumbModel from 'src/models/BreadcrumbModel';
import SidebarMenuItemModel from 'src/models/SidebarMenuItemModel';

function useCourseNavigation(course) {
  const { t } = useTranslation();

  const menuItems = [
    new SidebarMenuItemModel({
      Icon: <IconOpenBook className="w-4" />,
      key: 'overview',
      title: t('Overview'),
    }),
    new SidebarMenuItemModel({
      Icon: <IconUser className="w-4" />,
      key: 'professor',
      title: t('Professor'),
    }),
    new SidebarMenuItemModel({
      Icon: <IconBook className="w-4" />,
      key: 'lessons',
      title: t('Lessons'),
    }),
    // new SidebarMenuItemModel({
    //   Icon: <IconTwoUsers className="w-4" />,
    //   key: 'classrooms',
    //   title: t('Classrooms'),
    // }),
    // TODO: Redesign: Cleanup
    // new SidebarMenuItemModel({
    //   Icon: <IconDiscussion className="w-4" />,
    //   key: 'discussions',
    //   title: t('Discussions'),
    // }),
    course && course.purchased ? new SidebarMenuItemModel({
      Icon: <IconSources className="w-4" />,
      key: 'source',
      title: t('Source List'),
    }) : null,
    course && course.purchased ? new SidebarMenuItemModel({
      Icon: <IconDocuments className="w-4" />,
      key: 'teacherDocs',
      title: t('Teacher documents'),
    }) : null,
  ].filter(Boolean);

  const breadcrumbs = [
    new BreadcrumbModel({
      key: 'courses',
      name: t('Courses'),
      route: '/teacher/courses',
    }),
    course ? new BreadcrumbModel({
      key: course.id,
      name: course.name,
      route: null,
    }) : null,
  ].filter(Boolean);

  const [activeMenuItem, setActiveMenuItem] = useState(menuItems[0].key);

  return {
    activeMenuItem,
    breadcrumbs,
    menuItems,
    setActiveMenuItem,
  };
}

export default useCourseNavigation;
