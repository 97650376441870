import React, { useRef } from 'react';

import Vimeo from '@u-wave/react-vimeo';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function VIDEO(props) {
  const { className, contentItem, onLoaded } = props;

  const ref = useRef();

  return (
    <div className={classNames('VIDEO', className)}>
      <Vimeo
        ref={ref}
        autoplay={false}
        controls
        onLoaded={() => onLoaded(ref.current.container)}
        responsive
        showByline={false}
        showPortrait={false}
        showTitle={false}
        video={contentItem.body}
      />
    </div>
  );
}

VIDEO.defaultProps = {
  className: '',
};

VIDEO.propTypes = {
  className: PropTypes.string,
  contentItem: PropTypes.object.isRequired,
  onLoaded: PropTypes.func.isRequired,
};

export default VIDEO;
