import React from 'react';

import PropTypes from 'prop-types';

import NavigationItemModel from 'src/models/NavigationItemModel';

import TopBarMenuItem from './TopBarMenuItem';

function TopBarMenu(props) {
  const { navigationItems } = props;

  return (
    <nav className="TopBarMenu">
      <ul className="flex gap-8 items-center">
        {navigationItems.map((navigationItem, index) => (
          <TopBarMenuItem key={index} navigationItem={navigationItem} />
        ))}
      </ul>
    </nav>
  );
}

TopBarMenu.propTypes = {
  navigationItems: PropTypes.arrayOf(PropTypes.instanceOf(NavigationItemModel)).isRequired,
};
export default TopBarMenu;
