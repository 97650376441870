import React from 'react';

import Lottie from 'lottie-react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

import ClassroomModal from 'src/components/modals/ClassroomModal/ClassroomModal';
import { useModal } from 'src/helpers/useModal';
import LottieAnimation from 'src/images/lottie/no-data.json';
import { useDashboard } from 'src/views/Teacher/Dashboard/useDashboard';

function DashboardEmptyState() {
  const { fetchClassrooms, hasClassrooms, hasCourses } = useDashboard();

  const { openModal } = useModal();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onCreateClassroom = () => {
    openModal(ClassroomModal, {
      async onSuccess(classroom) {
        await fetchClassrooms();
        navigate(`/teacher/classrooms/${classroom.id}`);
      },
    });
  };

  return (
    <section className="DashboardEmptyState">
      <div className="grid w-96 m-auto gap-4">
        <Lottie animationData={LottieAnimation} loop />
        {!hasCourses && (
          <>
            <h5 className="text-center">
              {t('This is your starting page. Click the button below to purchase your first course')}
            </h5>
            <NavLink className="button--primary block text-center" to="/teacher/add-courses">
              {t('Start searching')}
            </NavLink>
          </>
        )}
        {hasCourses && !hasClassrooms && (
          <>
            <h5 className="text-center">
              {t('Well done! Now click the button below to create your first classroom and invite students')}
            </h5>
            <button className="button--primary block text-center" onClick={onCreateClassroom}>
              {t('Create classroom')}
            </button>
          </>
        )}
      </div>
    </section>
  );
}

export default DashboardEmptyState;
