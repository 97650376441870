import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as IconSearch } from 'src/images/icons/search.svg';

function InputSearch(props) {
  const { className, onInput, placeholder, value } = props;

  return (
    <div className={classNames(
      'InputSearch',
      'h-10 flex rounded-[32px] border border-DarkBlue/20 items-center px-4',
      className,
    )}
    >
      <IconSearch />
      <input
        className="h-8 border-0 focus:ring-0 "
        onInput={onInput}
        placeholder={placeholder}
        type="text"
        value={value}
      />
    </div>
  );
}

InputSearch.propTypes = {
  className: PropTypes.string,
  onInput: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
};

InputSearch.defaultProps = {
  className: '',
  placeholder: '',
};

export default InputSearch;
