import React from 'react';

import { useTranslation } from 'react-i18next';

import { useDiscussions } from 'src/api/discussion';
import Page from 'src/components/layout/Page';
import DiscussionModal from 'src/components/modals/DiscusssionModal/DiscussionModal';
import DataGrid from 'src/components/ui-kit/DataGrid';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import { useModal } from 'src/helpers/useModal';
import usePagination from 'src/helpers/usePagination';
import { ReactComponent as IconPlus } from 'src/images/icons/plus.svg';
import DiscussionItem from 'src/views/Discussions/DiscussionItem';

function Discussions() {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { role } = useCurrentUser();

  const {
    fetchNextPage,
    hasNextPage,
    isFetched,
    isFetching,
    items: discussions,
    refetch,
  } = useDiscussions(
    { size: 20 },
    role,
  );

  const { onLoadMore } = usePagination({ fetchNextPage });

  const onAddPost = () => {
    openModal(DiscussionModal, { onSuccess: refetch });
  };

  return (
    <Page title={t('Discussions')}>
      <section className="flex items-center justify-between">
        <h1 className="mt-1">{t('Discussions')}</h1>

        {role === 'teacher' && (
          <button className="button--primary flex gap-1 items-center" onClick={onAddPost}>
            <IconPlus />
            {t('Add discussion')}
          </button>
        )}
      </section>

      <DataGrid
        emptyText={role === 'student'
          ? t('You currently have no discussions')
          : t('You currently have no discussions. Click "Add Discussions" button to create one')}
        hasNextPage={hasNextPage}
        isEmpty={isFetched && discussions.length === 0}
        isFetching={isFetching}
        onLoadMore={onLoadMore}
      >
        <div className="mt-6 grid gap-4">
          {discussions.map((discussion) => (
            <DiscussionItem key={discussion.id} discussion={discussion} />
          ))}
        </div>
      </DataGrid>
    </Page>
  );
}

export default Discussions;
