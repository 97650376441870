import React, { useRef } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import { ReactComponent as IconClose } from 'src/images/icons-deprecated/close.svg';
// Transitions
// https://github.com/reactjs/react-modal/blob/master/docs/styles/transitions.md

const sizes = ['sm', 'md'];

function Modal(props) {
  const {
    children, isOpen,
    onClose, shouldCloseOnOverlayClick,
    shouldShowCloseButton, size, title,
  } = props;

  const modalContent = useRef();

  return (
    <ReactModal
      ariaHideApp={false}
      className={classNames('Modal', `Modal--${size}`)}
      closeTimeoutMS={50}
      contentLabel={title}
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName="Modal__overlay"
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldFocusAfterRender={false}
    >
      <div className="Modal__content-wrapper">
        {shouldShowCloseButton && (
          <header className="flex justify-end">
            <button
              className="self-start"
              onClick={onClose}
            >
              <IconClose className="fill-current" />
            </button>
          </header>
        )}
        <h4 className="text-center">{title}</h4>
        <div ref={modalContent} className="Modal__content mt-12">
          {children}
        </div>
      </div>
    </ReactModal>
  );
}

Modal.defaultProps = {
  shouldCloseOnOverlayClick: false,
  shouldShowCloseButton: true,
  size: 'md',
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  shouldCloseOnOverlayClick: PropTypes.bool,
  shouldShowCloseButton: PropTypes.bool,
  size: PropTypes.oneOf(sizes),
  title: PropTypes.string.isRequired,
};

export default Modal;
