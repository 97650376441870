import React from 'react';

import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Avatar from 'src/components/common/Avatar';
import Divider from 'src/components/ui-kit/Divider';

const toYear = (date) => format(new Date(date * 1000), 'yyyy');

function CourseProfessor(props) {
  const { course } = props;
  const { t } = useTranslation();

  const { professor } = course;

  return (
    <section className="CourseProfessor ho-card p-6">
      <h4 className="text-center">{t('About professor {{professor}}', { professor: `${professor.firstName} ${professor.lastName}` })}</h4>

      <div className="grid gap-6 grid-cols-[240px_auto] mt-8">
        <Avatar
          image={professor.logoUrl}
          size="responsive"
          variant="rectangular"
        />

        <ul className="text-center grid gap-1">
          {professor.schools.map((school, index) => (
            <li key={index} className="text-sm text-left list-disc">
              {`${school.name}, ${toYear(school.to)}`}
            </li>
          ))}
        </ul>
      </div>

      <Divider className="my-8" />

      <p className="html prose max-w-full" dangerouslySetInnerHTML={{ __html: professor.bio }} />
    </section>
  );
}

CourseProfessor.propTypes = {
  course: PropTypes.object.isRequired,
};

export default CourseProfessor;
