import React, { createContext, useMemo, useCallback, useState } from 'react';

import PropTypes from 'prop-types';

export const ModelContainerContext = createContext({
  ModalComponent: null,
  closeModal: () => {},
  isOpen: false,
  openModal: () => {},
});

export function ModalContainerProvider(props) {
  const { children } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [ModalComponent, setModalComponent] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
    setModalComponent(null);
  };

  const openModal = useCallback((Component, modalProps = {}) => {
    setIsOpen(true);
    setModalComponent(
      <Component
        {...modalProps}
        isOpen
        onClose={() => {
          closeModal();
          if (modalProps.onClose) {
            modalProps.onClose();
          }
        }}
      />,
    );
  }, [setIsOpen]);

  const context = useMemo(() => ({
    ModalComponent,
    closeModal,
    isOpen,
    openModal,
  }), [ModalComponent, isOpen, openModal]);

  return (
    <ModelContainerContext.Provider value={context}>
      {children}
    </ModelContainerContext.Provider>
  );
}

ModalContainerProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useModal = () => React.useContext(ModelContainerContext);
