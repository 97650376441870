import { useInfiniteQuery, useMutation, useQuery } from 'react-query';

import { api } from 'src/api/api';
import { transformInfiniteQueryResult } from 'src/api/helpers';

export const CLASSROOM_QUERY_NAME = 'CLASSROOM_QUERY_NAME';
export const CLASSROOMS_QUERY_NAME = 'CLASSROOMS_QUERY_NAME';
export const CLASSROOM_MEMBERS_QUERY_NAME = 'CLASSROOM_MEMBERS_QUERY_NAME';

export function useClassrooms({ page, size }, options = {}) {
  const fetchClassrooms = ({ pageParam = page }) => api.get('/teacher/classrooms', {
    params: {
      page: pageParam,
      size,
    },
  }).then((response) => response.data);

  const result = useInfiniteQuery(CLASSROOMS_QUERY_NAME, fetchClassrooms, {
    cacheTime: 0,
    getNextPageParam: (lastPage) => (lastPage.last ? undefined : lastPage.number + 1),
    ...options,
  });

  return transformInfiniteQueryResult(result);
}

export const useClassroom = (classroomId) => useQuery(
  [CLASSROOM_QUERY_NAME, classroomId],
  () => api.get(`/teacher/classrooms/${classroomId}`).then((response) => response.data),
);

export const useCreateClassroomMutation = () => useMutation((body) => api.post('/teacher/classrooms', body));

export const useUpdateClassroomMutation = () => useMutation(({ body, id }) => api.put(`/teacher/classrooms/${id}`, body));

export const useDeleteClassroomMutation = () => useMutation(({ id }) => api.delete(`/teacher/classrooms/${id}`));

export const usePreLaunchClassroomMutation = () => useMutation((classroomId) => api.post(`/teacher/classrooms/${classroomId}/pre-launch`));

export const useLaunchClassroomMutation = () => useMutation((classroomId) => api.post(`/teacher/classrooms/${classroomId}/launch`));

export const useClassroomsMembers = (classroomId) => useQuery(
  [CLASSROOM_MEMBERS_QUERY_NAME, classroomId],
  () => api.get(`/teacher/classrooms/${classroomId}/members`).then((response) => response.data),
);

export const useInviteClassroomMembersMutation = () => useMutation(
  ({ body, classroomId }) => api.post(`/teacher/classrooms/${classroomId}/members`, body),
);

export const useDeleteClassroomMembersMutation = () => useMutation(
  ({ classroomId, memberId }) => api.delete(`/teacher/classrooms/${classroomId}/members/${memberId}`),
);
