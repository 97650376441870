import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { useLessonPreviewUrl } from 'src/helpers/lessons';

import LessonCardToggleVisibilityButton from './LessonCardToggleVisibilityButton';

function LessonCard(props) {
  const { course, lesson, onCourseChange } = props;

  // const { currency } = useCurrentUser();
  const { buildUrl } = useLessonPreviewUrl();

  return (
    <div className={classNames('LessonCard ho-card overflow-hidden', {
      'opacity-70': !lesson.visible,
    })}
    >
      <div>
        <div
          className="h-40 bg-ho-grey-light"
          style={{
            backgroundImage: `url(${course.imageUrl})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        />
      </div>

      <h4 className="mt-3.5 px-6 pb-4 pt-2 truncate flex items-center justify-between">
        {course.purchased ? (
          <NavLink
            title={lesson.name}
            to={buildUrl({ courseId: course.id, lessonId: lesson.id })}
          >
            {lesson.name}
          </NavLink>
        ) : lesson.name}

        <LessonCardToggleVisibilityButton
          course={course}
          lesson={lesson}
          onSuccess={onCourseChange}
        />
      </h4>
    </div>
  );
}

LessonCard.propTypes = {
  course: PropTypes.object.isRequired,
  lesson: PropTypes.object.isRequired,
  onCourseChange: PropTypes.func.isRequired,
};

export default LessonCard;
