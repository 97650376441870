import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function ProgressBar(props) {
  const { className, progress, rounded, showText, text } = props;

  const { t } = useTranslation();

  return (
    <div className={classNames('ProgressBar', className)}>
      <div className="relative">
        <div className={classNames('overflow-hidden h-2.5 text-xs flex bg-LightBlue', {
          'rounded-full': rounded,
        })}
        >
          <div
            className={classNames('shadow-none flex rounded-r-full flex-col text-center whitespace-nowrap text-white justify-center', {
              'bg-Green': progress > 0,
            })}
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>
      {showText && (
        <div className="mt-1 text-xs text-right">{t(text, { progress })}</div>
      )}
    </div>
  );
}

ProgressBar.defaultProps = {
  className: '',
  rounded: false,
  showText: false,
  text: '{{progress}}%',
};

ProgressBar.propTypes = {
  className: PropTypes.string,
  progress: PropTypes.number.isRequired,
  rounded: PropTypes.bool,
  showText: PropTypes.bool,
  text: PropTypes.string,
};

export default ProgressBar;
