import { useTranslation } from 'react-i18next';

import BreadcrumbModel from 'src/models/BreadcrumbModel';

function useClassroomNavigation(classroom) {
  const { t } = useTranslation();

  const breadcrumbs = [
    new BreadcrumbModel({
      key: 'classrooms',
      name: t('Classrooms'),
      route: '/teacher/classrooms',
    }),
    classroom ? new BreadcrumbModel({
      key: classroom.id,
      name: classroom.name,
      route: null,
    }) : null,
  ].filter(Boolean);

  return {
    breadcrumbs,
  };
}

export default useClassroomNavigation;
