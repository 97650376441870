function findQuizAttempt(quizId, course) {
  const attempt = course.quizAnswerAttempts
    ? course.quizAnswerAttempts.find((_attempt) => _attempt.quizId === quizId)
    : null;

  if (attempt) {
    return {
      ...attempt,
      attempted: true,
      quizAnswerIds: attempt.answers.map((answer) => answer.quizAnswerId),
    };
  }
  return {
    answers: [],
    attempted: false,
    completed: false,
    correct: false,
    id: null,
    quizAnswerIds: [],
    quizId: null,
  };
}

export function buildForm(quizzes, course) {
  return quizzes.reduce((formData, quiz) => ({
    ...formData,
    [quiz.id]: {
      ...findQuizAttempt(quiz.id, course),
      quizId: quiz.id,
    },
  }), {});
}

export function getQuizFormStates(formData) {
  const isAnswered = Object.values(formData).every((value) => value.quizAnswerIds.length > 0);
  const isCompleted = Object.values(formData).every((value) => value.completed);

  return {
    isAnswered,
    isCompleted,
  };
}
