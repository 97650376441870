import React from 'react';

import PropTypes from 'prop-types';

import { useCourse } from 'src/api/courses';
import CourseCard from 'src/components/common/CourseCard';
import Loading from 'src/components/ui-kit/Loading';
import { useCurrentUser } from 'src/contexts/useCurrentUser';

function ClassroomCourseSection(props) {
  const { classroom } = props;

  const { role } = useCurrentUser();
  const { data: course, isFetching } = useCourse({ courseId: classroom.courseId, role });

  return (
    <section className="ClassroomCourseSection">
      {isFetching ? (
        <Loading />
      ) : (
        <CourseCard
          course={course}
          showActionButton={false}
        />
      )}
    </section>
  );
}

ClassroomCourseSection.propTypes = {
  classroom: PropTypes.object.isRequired,
};

export default ClassroomCourseSection;
