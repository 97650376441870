import React from 'react';

import { useTranslation } from 'react-i18next';

import { statuses } from 'src/api/courses';
import CoursesDataGrid from 'src/components/common/CoursesDataGrid';
import Page from 'src/components/layout/Page';

function Courses() {
  const { t } = useTranslation();

  return (
    <Page title={t('Courses')}>
      <section className="flex justify-between items-center">
        <h1>{t('Courses')}</h1>
      </section>

      <section className="mt-8">
        <CoursesDataGrid params={{ status: statuses.PURCHASED_ONLY }} />
      </section>
    </Page>
  );
}

export default Courses;
