/* eslint-disable global-require */
import React, { useEffect, useState, useRef } from 'react';

import PropTypes from 'prop-types';

import Loading from 'src/components/ui-kit/Loading';

function HTMLEditor(props) {
  const { name, onChange, placeholder, value } = props;
  const editorRef = useRef();
  const { CKEditor, ClassicEditor } = editorRef.current || {};

  const [editorLoaded, setEditorLoaded] = useState(false);

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  useEffect(() => {
    editorRef.current = {
      CKEditor: require('@ckeditor/ckeditor5-react').CKEditor, // v3+
      ClassicEditor: require('@ckeditor/ckeditor5-build-classic'),
    };
  }, []);

  const config = {
    placeholder,
    toolbar: [
      'heading',
      'bold', 'italic',
      'link',
      'bulletedList', 'numberedList',
      'blockQuote',
      'undo', 'redo',
    ],
  };

  return (
    <div className="HTMLEditor html">
      {editorLoaded ? (
        <CKEditor
          config={config}
          data={value}
          editor={ClassicEditor}
          name={name}
          onChange={(event, editor) => {
            const data = editor.getData();
            onChange(data);
          }}
          type=""
        />
      ) : (
        <Loading />
      )}
    </div>
  );
}

HTMLEditor.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
};

HTMLEditor.defaultProps = {
  placeholder: '',
};

export default HTMLEditor;
