import React, { useLayoutEffect } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCreateDiscussionMutation, useUpdateDiscussionMutation } from 'src/api/discussion';
import SelectClassroomDropdown from 'src/components/common/SelectClassroomDropdown';
import HTMLEditor from 'src/components/ui-kit/HTMLEditor';
import Modal from 'src/components/ui-kit/Modal';
import { showErrorMessage, showSuccessMessage } from 'src/helpers/toast';
import { useModal } from 'src/helpers/useModal';
import useValidationHelpers from 'src/helpers/useValidationHelpers';

import useDiscussionForm from './useDiscussionForm';

function DiscussionModal(props) {
  const { discussion, isOpen, onClose, onSuccess } = props;
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const isEditing = !!discussion;

  const { control, formState, handleSubmit, register, setFocus } = useDiscussionForm({
    discussion,
  });
  const { getFieldError } = useValidationHelpers({ formState });

  const { mutateAsync: createDiscussion } = useCreateDiscussionMutation();
  const { mutateAsync: updateDiscussion } = useUpdateDiscussionMutation();

  useLayoutEffect(() => {
    if (isEditing) {
      setTimeout(() => setFocus('title'));
    }
  }, [setFocus, isEditing]);

  const onSubmit = async (formData, event) => {
    event.preventDefault();

    try {
      if (isEditing) {
        await updateDiscussion({ body: formData, id: discussion.id });
      } else {
        await createDiscussion({
          body: formData.body,
          classroomId: formData.classroom.id,
          title: formData.title,
        });
      }
      onSuccess();
      closeModal();
      if (isEditing) {
        showSuccessMessage(t('Post successfully updated'));
      } else {
        showSuccessMessage(t('Post successfully created'));
      }
    } catch (e) {
      showErrorMessage(e.message);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      shouldShowCloseButton={false}
      title={isEditing ? t('Edit discussion') : t('Create Discussion')}
    >
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        {!isEditing && (
        <div className="grid">
          <Controller
            control={control}
            id="classroom"
            name="classroom"
            render={({ field: { onChange, value } }) => (
              <SelectClassroomDropdown
                onChange={onChange}
                value={value}
              />
            )}
          />
        </div>
        )}

        <div className="grid mt-5">
          <input
            className={classNames('input mt-2', { 'input--has-error': getFieldError('title') })}
            id="DiscussionModal__title"
            name="title"
            placeholder={t('Discussions title*')}
            {...register('title')}
          />
          <p className="error-message mt-1">{getFieldError('title')}</p>
        </div>

        <div className="grid mt-5">
          <Controller
            control={control}
            id="body"
            name="body"
            render={({ field: { onChange, value } }) => (
              <HTMLEditor
                name="body"
                onChange={onChange}
                placeholder={t('Discussions description*')}
                value={value}
              />
            )}
          />
          <p className="error-message mt-1">{getFieldError('body')}</p>
        </div>

        <div className="mt-6 flex items-center justify-between">
          <button
            className="button--secondary-outlined"
            onClick={onClose}
            type="button"
          >
            {t('Cancel')}
          </button>
          <button
            className={classNames('button--primary', { 'visually-disabled': !formState.isValid })}
            disabled={!formState.isDirty}
            type="submit"
          >
            {t('Save')}
          </button>
        </div>
      </form>
    </Modal>
  );
}

DiscussionModal.defaultProps = {
  discussion: null,
  onClose: null,
};

DiscussionModal.propTypes = {
  discussion: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
};

export default DiscussionModal;
