/* eslint-disable no-param-reassign */
import axios from 'axios';
import i18n from 'i18next';
import { QueryClient, setLogger } from 'react-query';
import { matchPath } from 'react-router-dom';

import { publicRoutes } from 'src/config';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

const storage = localStorage;

export const setToken = (token) => storage.setItem('token', token);

export const getToken = () => storage.getItem('token');

export const clearAuthData = () => {
  storage.removeItem('token');
};

api.interceptors.request.use((config) => {
  const token = getToken();
  const locale = i18n.language;

  if (locale) {
    config.headers['Content-Language'] = locale;
  }

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

function handle401(pathname) {
  if (pathname.startsWith('/student')) {
    const match = matchPath('/student/courses/:id', pathname);
    if (match) {
      window.location.replace(`/student/login?redirect=${pathname}`);
    } else {
      window.location.replace('/student/login');
    }
  } else if (pathname.startsWith('/teacher')) {
    window.location.replace('/teacher/login');
  } else {
    window.location.replace('/welcome');
  }
}

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const { pathname } = window.location;
    if (error.response) {
      if (error.response.status === 401 && !publicRoutes.includes(pathname)) {
        handle401(pathname);
      }
    }

    return Promise.reject(error);
  },
);

// eslint-disable-next-line react-hooks/rules-of-hooks
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: true,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

setLogger({
  error(...args) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error(...args);
    } else {
      // Add sentry
    }
  },
});
