import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

function useSignupForm({ userRole }) {
  const { t } = useTranslation();

  const initialFormData = {
    confirmPassword: '',
    email: '',
    password: '',
    ...userRole === 'teacher' && {
      school: '',
      title: '',
    },
    agreeToServices: false,
  };

  const validationResolver = yupResolver(yup.object({
    confirmPassword: yup
      .string()
      .test(
        'passwordMatch',
        t('Passwords do not match'),
        (value, { parent: { password } }) => value === password,
      )
      .required(t('Field is required')),
    email: yup
      .string()
      .required(t('Field is required'))
      .email(t('Must be a valid email field')),
    firstName: yup
      .string()
      .required(t('Field is required')),
    lastName: yup
      .string()
      .required(t('Field is required')),
    password: yup
      .string()
      .required(t('Field is required')),
    ...userRole === 'teacher' && {
      school: yup
        .string()
        .required(t('Field is required')),
      title: yup
        .string()
        .required(t('Field is required')),
    },
    agreeToServices: yup
      .boolean()
      .required(t('Field is required')),
  }).required());

  return useForm({
    defaultValues: initialFormData,
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: validationResolver,
  });
}

export default useSignupForm;
