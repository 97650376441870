import React from 'react';

import PropTypes from 'prop-types';

import LessonCard from './LessonCard';

function CourseLessons(props) {
  const { course, onCourseChange } = props;

  return (
    <section className="CourseLessons">
      <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-7">
        {course.lessons.map((lesson) => (
          <LessonCard
            key={lesson.id}
            course={course}
            lesson={lesson}
            onCourseChange={onCourseChange}
          />
        ))}
      </div>
    </section>
  );
}

CourseLessons.propTypes = {
  course: PropTypes.object.isRequired,
  onCourseChange: PropTypes.func.isRequired,
};

export default CourseLessons;
