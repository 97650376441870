import React from 'react';

import Lottie from 'lottie-react';
import PropTypes from 'prop-types';

import LottieAnimation from 'src/images/lottie/empty.json';

function EmptyState(props) {
  const { text } = props;

  return (
    <div className="EmptyState grid items-center justify-center">
      <div className="w-96">
        <Lottie animationData={LottieAnimation} />
        <h5 className="text-center -mt-20">{text}</h5>
      </div>
    </div>
  );
}

EmptyState.propTypes = {
  text: PropTypes.string.isRequired,
};

export default EmptyState;
