import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

function useClassroomForm({ classroom }) {
  const { t } = useTranslation();

  const initialFormData = classroom ? ({
    course: { id: classroom.courseId },
    name: classroom.name,
  }) : ({
    course: null,
    name: '',
  });

  const validationResolver = yupResolver(yup.object({
    course: yup
      .object()
      .required(t('Field is required')),
    name: yup
      .string()
      .required(t('Field is required')),
  }).required());

  return {
    initialFormData,
    validationResolver,
  };
}

export default useClassroomForm;
