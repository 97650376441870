import React from 'react';

import PropTypes from 'prop-types';

import DEFAULT from './contentSections/DEFAULT';
import MINI_VIDEO from './contentSections/MINI_VIDEO';

function ModuleDynamicContent(props) {
  const { module } = props;

  return (
    <div className="ModuleDynamicContent grid grid-cols-2 gap-6">
      {module.type === 'MINI_VIDEO' ? (
        <MINI_VIDEO module={module} />
      ) : (
        <DEFAULT module={module} />
      )}
    </div>
  );
}

ModuleDynamicContent.propTypes = {
  module: PropTypes.object.isRequired,
};

export default ModuleDynamicContent;
