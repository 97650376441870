import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import ProgressBar from 'src/components/ui-kit/ProgressBar';
import { useLessonPreviewUrl, calculateProgress } from 'src/helpers/lessons';
import { ReactComponent as IconFiles } from 'src/images/icons/files.svg';

function LessonCard(props) {
  const { course, lesson, progress } = props;

  const { buildUrl } = useLessonPreviewUrl();

  const { allModulesCount, completedModulesCount } = calculateProgress(
    course.courseTrackingModel.moduleStatuses,
    lesson,
  );

  return (
    <NavLink
      className={classNames('LessonCard ho-card overflow-hidden')}
      to={buildUrl({ courseId: course.id, lessonId: lesson.id })}
    >
      <div className="relative">
        <div
          className="h-40 bg-ho-grey-light"
          style={{
            backgroundImage: `url(${course.imageUrl})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        />
      </div>

      <ProgressBar progress={progress} />

      <div className="px-6 pb-6 mt-1">
        <div className={classNames('grid grid-cols-[max-content_auto_max-content] gap-1 items-center text-xs', {
          'text-DarkBlue/40': progress === 0,
          'text-DarkBlue/80': progress > 0,
        })}
        >
          <span>
            {`${completedModulesCount} / ${allModulesCount}`}
          </span>
          <IconFiles />
          <span>
            {`${progress}%`}
          </span>
        </div>

        <h4
          className="mt-3.5 truncate"
          title={lesson.name}
        >
          {lesson.name}
        </h4>
      </div>
    </NavLink>
  );
}

LessonCard.propTypes = {
  course: PropTypes.object.isRequired,
  lesson: PropTypes.object.isRequired,
  progress: PropTypes.number.isRequired,
};

export default LessonCard;
