import React, { useLayoutEffect } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

function Page(props) {
  const { children, className, title } = props;

  // Set document title
  useLayoutEffect(() => {
    document.title = title;
  });

  return (
    <main className={classNames('Page', className)}>
      { children }
    </main>
  );
}

Page.defaultProps = {
  className: '',
};

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Page;
