import React, { useState } from 'react';

import classNames from 'classnames';
import Fuse from 'fuse.js';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DataGrid from 'src/components/ui-kit/DataGrid';
import InputSearch from 'src/components/ui-kit/InputSearch';
import { ReactComponent as IconFileImage } from 'src/images/icons/file-image.svg';
import { ReactComponent as IconFileText } from 'src/images/icons/file-text.svg';
import { ReactComponent as IconFileVideo } from 'src/images/icons/file-video.svg';

const iconsMap = {
  IMAGE: <IconFileImage />,
  TEXT: <IconFileText />,
  VIDEO: <IconFileVideo />,
};

function getSourceItemsWithLesson(course, searchValue) {
  const { sourceItems } = course;

  const mappedItems = sourceItems.map((sourceItem) => ({
    ...sourceItem,
    lesson: course.lessons.find(
      (lesson) => Number(sourceItem.lessonId) === Number(lesson.id),
    ) || null,
  }));

  if (!searchValue) {
    return mappedItems;
  }

  const fuse = new Fuse(mappedItems, {
    keys: ['title', 'lesson.name'],
    threshold: 0.2,
  });

  return fuse.search(searchValue).map(({ item }) => item);
}

function CourseSourceList(props) {
  const { course } = props;
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');

  const items = getSourceItemsWithLesson(course, searchValue);

  return (
    <section className="CourseSourceList ho-card">
      <div className="p-6">
        <InputSearch
          className="w-96"
          onInput={(event) => setSearchValue(event.target.value)}
          placeholder={t('Find')}
          value={searchValue}
        />
      </div>
      <DataGrid
        emptyText={t('No source list items available')}
        hasNextPage={false}
        isEmpty={items.length === 0}
        isFetching={false}
        onLoadMore={() => {}}
      >
        <table className="table-grid">
          <thead>
            <tr className="grid px-8 bg-MainYellow/[0.15] h-10 items-center grid-cols-[160px_100px_auto]">
              <th className="text-left">{t('Lesson')}</th>
              <th>{t('Type')}</th>
              <th className="text-left">{t('Name and the link')}</th>
            </tr>
          </thead>

          <tbody className="mt-5">
            {items.map((item, index) => (
              <tr
                key={index}
                className={classNames('grid px-8 justify-start items-center h-14 grid-cols-[160px_100px_auto]', {
                  'mb-6': index === items.length - 1,
                })}
              >
                <td>
                  {item.lesson ? item.lesson.name : ''}
                </td>
                <td className="flex justify-center" title={t(item.sourceType)}>
                  {iconsMap[item.sourceType]}
                </td>
                <td>
                  {item.url ? (
                    <a
                      className="ho-link-underline line-clamp-2"
                      href={item.url}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {item.title}
                    </a>
                  ) : (item.title)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </DataGrid>
    </section>
  );
}

CourseSourceList.propTypes = {
  course: PropTypes.object.isRequired,
};

export default CourseSourceList;
