import React from 'react';

import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

// import Footer from 'src/components/common/Footer';
import Page from 'src/components/layout/Page';
import { ReactComponent as ChooseAccountSvg } from 'src/images/choose-account.svg';
import { ReactComponent as Logo } from 'src/images/logo.svg';

function ChooseAccount() {
  const { t } = useTranslation();

  return (
    <Page className="ChooseAccount flex" title={t('Welcome')}>
      <section className="p-7 hidden lg:flex flex-col w-[520px] bg-blue-600 h-screen">
        <Logo />
        <div className="flex-1 flex flex-col justify-center">
          <ChooseAccountSvg />
        </div>
      </section>

      <section className="flex-1 flex flex-col items-center justify-center h-screen">
        <div className="flex-1 flex flex-col justify-center">
          <div>
            <h1>{t('Welcome to History Owls!')}</h1>
            <div className="mt-3">{t('Choose an account type to proceed.')}</div>

            <h5 className="mt-16">{t('I am a:')}</h5>
            <div className="mt-2 grid gap-5">
              <NavLink
                className="bg-white shadow-ho-default rounded-[10px] p-5 flex items-center justify-center"
                to="/teacher/login"
              >
                <h5>{t('Teacher')}</h5>
              </NavLink>
              <NavLink
                className="bg-white shadow-ho-default rounded-[10px] p-5 flex items-center justify-center"
                to="/student/login"
              >
                <h5>{t('Student')}</h5>
              </NavLink>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </section>
    </Page>
  );
}

export default ChooseAccount;
