import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

function useProfileForm({ me }) {
  const { t } = useTranslation();

  const initialFormData = {
    firstName: me.firstName || '',
    lastName: me.lastName || '',
    school: me.school || '',
    title: me.title || '',
  };

  const validationResolver = yupResolver(yup.object({
    firstName: yup
      .string()
      .required(t('Field is required')),
    lastName: yup
      .string()
      .required(t('Field is required')),
    school: yup
      .string()
      .required(t('Field is required')),
    title: yup
      .string()
      .required(t('Field is required')),
  }).required());

  return useForm({
    defaultValues: initialFormData,
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: validationResolver,
  });
}

export default useProfileForm;
