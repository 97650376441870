import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

function CourseLessons(props) {
  const { LessonCard, className, course, stats } = props;

  return (
    <section className={classNames('CourseLessons', className)}>
      <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-7">
        {course.lessons.map((lesson) => (
          <LessonCard
            key={lesson.id}
            course={course}
            lesson={lesson}
            progress={stats ? Math.floor(stats[lesson.id] * 100) : 0}
          />
        ))}
      </div>
    </section>
  );
}

CourseLessons.propTypes = {
  LessonCard: PropTypes.func.isRequired,
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
  stats: PropTypes.object,
};

CourseLessons.defaultProps = {
  className: '',
  stats: {},
};

export default CourseLessons;
