import React from 'react';

import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';

import { useClassroom, useClassroomsMembers } from 'src/api/classrooms';
import Page from 'src/components/layout/Page';
import Loading from 'src/components/ui-kit/Loading';
import { ReactComponent as IconChevronLeft } from 'src/images/icons/chevron-left.svg';
import ClassroomStudentStats from 'src/views/Teacher/ClassroomStudent/ClassroomStudentStats';

function getNameText(member) {
  const { email, firstName, lastName } = member;

  if (firstName && lastName) {
    return i18n.t('{{firstName}} {{lastName}}', { email, firstName, lastName });
  }
  return i18n.t('Student ({{email}})', { email });
}

function ClassroomStudent() {
  const { classroomId, studentId } = useParams();
  const { data: classroom, isFetching: isFetchingClassroom } = useClassroom(classroomId);
  const { data: members = [], isFetching: isFetchingMembers } = useClassroomsMembers(classroomId);
  const hasStats = !!classroom && classroom.launchStatus === 'STARTED';

  const isFetching = isFetchingClassroom || isFetchingMembers;

  const { t } = useTranslation();

  const member = members.find((_member) => _member.userId === parseInt(studentId, 10));

  if (isFetching || !member || !classroom) {
    return (
      <Page title={t('Loading')}>
        <Loading />
      </Page>
    );
  }

  const name = getNameText(member);

  return (
    <Page className="ClassroomMember grid gap-8 pb-10" title={name}>
      <NavLink
        className="h1 text-lg ho-link flex gap-2 items-center"
        to={`/teacher/classrooms/${classroomId}`}
      >
        <IconChevronLeft />
        <span>{name}</span>
      </NavLink>

      {hasStats && (
        <ClassroomStudentStats classroom={classroom} studentName={name} />
      )}
    </Page>
  );
}

export default ClassroomStudent;
