import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DataGrid from 'src/components/ui-kit/DataGrid';

import ClassroomStudentProgressTableViewRow from './ClassroomStudentProgressTableViewRow';

function ClassroomStudentProgressTableView(props) {
  const { course, stats, studentName } = props;
  const { t } = useTranslation();

  // TODO: Show module status
  // const firstLesson = course.lessons[0];

  const gridColsClass = 'grid-cols-[1fr_1fr_88px]';

  return (
    <section className="ClassroomStudentProgressTableView ho-card">
      <div className="px-8 pt-6 pb-4">
        <h4>{t('View student responses')}</h4>
      </div>

      <DataGrid
        hasNextPage={false}
        isEmpty={false}
        isFetching={false}
        onLoadMore={() => {}}
      >
        <table className="table-grid">
          <thead>
            <tr className={classNames('grid pl-8 pr-4 bg-MainYellow/[0.15] h-10 items-center', gridColsClass)}>
              <th className="font-bold">{t('Lesson name')}</th>
              <th className="font-bold">{t('Progress')}</th>
              <th className="text-center">{t('Actions')}</th>
            </tr>
          </thead>

          <tbody className="mt-5">
            {course.lessons.map((lesson) => (
              <ClassroomStudentProgressTableViewRow
                key={lesson.id}
                className={gridColsClass}
                course={course}
                lesson={lesson}
                stats={stats}
                studentName={studentName}
              />
            ))}
          </tbody>
        </table>
      </DataGrid>
    </section>
  );
}

ClassroomStudentProgressTableView.propTypes = {
  course: PropTypes.object.isRequired,
  stats: PropTypes.object.isRequired,
  studentName: PropTypes.string.isRequired,
};

export default ClassroomStudentProgressTableView;
