import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';

import { useClassrooms } from 'src/api/classrooms';
import { useCurrentUser } from 'src/contexts/useCurrentUser';

// eslint-disable-next-line react/prop-types
function Control({ children, ...props }) {
  return (
    <components.Control {...props}>
      <div className="flex flex-1 items-center py-3 px-4 cursor-pointer">
        {children}
      </div>
    </components.Control>
  );
}

function SelectClassroomDropdown(props) {
  const { onChange, value } = props;
  const { role } = useCurrentUser();

  const { t } = useTranslation();

  const { isFetching, items: classrooms = [] } = useClassrooms({
    role,
    size: 100,
  });

  return (
    <Select
      className="SelectCourseDropdown"
      components={{
        Control,
        IndicatorSeparator: () => null,
      }}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      isClearable={false}
      isDisabled={isFetching}
      isSearchable={false}
      onChange={onChange}
      options={classrooms}
      placeholder={t('Select classroom')}
      value={value}
    />
  );
}

SelectClassroomDropdown.defaultProps = {
  value: null,
};

SelectClassroomDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
};

export default SelectClassroomDropdown;
