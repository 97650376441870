import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Footer from 'src/components/common/Footer';
import Page from 'src/components/layout/Page';
import { ReactComponent as Logo } from 'src/images/logo.svg';
import { ReactComponent as SignupSvg } from 'src/images/signup.svg';
import SignupForm from 'src/views/Signup/SignupForm';

function Signup(props) {
  const { userRole } = props;
  const { t } = useTranslation();

  return (
    <Page className="Signup grid grid-cols-[523px_auto]" title={t('Signup')}>
      <section className="hidden bg-Blue h-screen lg:flex flex-col justify-between">
        <div className="p-7">
          <Logo />
        </div>
        <div>
          <SignupSvg />
        </div>
      </section>

      <div className="flex-1 flex flex-col items-center justify-center h-screen">
        <section className="flex-1 flex flex-col justify-center">
          <div className="grid gap-6">
            <h1>{t('Welcome to History Owls!')}</h1>

            <SignupForm userRole={userRole} />
          </div>
        </section>

        <Footer variant="light" />
      </div>
    </Page>
  );
}

Signup.propTypes = {
  userRole: PropTypes.oneOf(['teacher', 'student']).isRequired,
};

export default Signup;
