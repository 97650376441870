import { useQuery } from 'react-query';

import { api } from 'src/api/api';

export const STUDENT_STATS_QUERY = 'STUDENT_STATS_QUERY';
export const TEACHER_CLASSROOM_STATS_QUERY = 'TEACHER_CLASSROOM_STATS_QUERY';
export const TEACHER_CLASSROOM_STUDENT_STATS_QUERY = 'TEACHER_CLASSROOM_STUDENT_STATS_QUERY';
export const STUDENT_FEEDBACKS_STATS_QUERY = 'STUDENT_FEEDBACKS_STATS_QUERY';

export function useStudentStats({ courseId }, options = {}) {
  return useQuery({
    cacheTime: 0,
    enabled: courseId !== null,
    queryFn: () => api
      .get('/student/stats', { params: { courseId } })
      .then((response) => response.data.data),
    queryKey: [courseId, STUDENT_STATS_QUERY],
    ...options,
  });
}

export function useTeacherClassroomStats({ classroomId }, options = {}) {
  return useQuery({
    cacheTime: 0,
    queryFn: () => api
      .get(`/teacher/stats/classroom/${classroomId}`)
      .then((response) => response.data.data),
    queryKey: [classroomId, TEACHER_CLASSROOM_STATS_QUERY],
    ...options,
  });
}

export function useTeacherClassroomMemberStats({ classroomId, studentId }) {
  return useQuery({
    cacheTime: 0,
    enabled: !!classroomId && !!studentId,
    queryFn: () => api
      .get(`/teacher/stats/classroom/${classroomId}/student/${studentId}`)
      .then((response) => response.data.data),
    queryKey: [classroomId, studentId, TEACHER_CLASSROOM_STUDENT_STATS_QUERY],
  });
}

export function useStudentFeedbackStats({ courseId }, options = {}) {
  return useQuery({
    cacheTime: 0,
    queryFn: () => api
      .get(`/teacher/courses/${courseId}/lesson-feedbacks`)
      .then((response) => response.data),
    queryKey: [courseId, STUDENT_FEEDBACKS_STATS_QUERY],
    ...options,
  });
}
