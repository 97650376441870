import React from 'react';

import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import ClassroomModal from 'src/components/modals/ClassroomModal/ClassroomModal';
import DeleteClassroomModal from 'src/components/modals/DeleteClassroomModal/DeleteClassroomModal';
import { useModal } from 'src/helpers/useModal';
import { ReactComponent as IconDelete } from 'src/images/icons/delete.svg';
import { ReactComponent as IconEdit } from 'src/images/icons/edit.svg';
import { ReactComponent as IconEye } from 'src/images/icons/eye.svg';

function ClassroomTableRowActions(props) {
  const { classroom, onChange } = props;

  const { closeModal, openModal } = useModal();

  const handleDelete = () => openModal(DeleteClassroomModal, {
    classroom,
    onClose: closeModal,
    onSuccess() {
      onChange();
      closeModal();
    },
  });

  const handleEdit = () => openModal(ClassroomModal, {
    classroom,
    onClose: closeModal,
    onSuccess() {
      onChange();
      closeModal();
    },
  });

  return (
    <div className="ClassroomTableRowActions text-DarkBlue/40 hover:text-DarkBlue/80 flex items-center justify-center gap-4">
      <button onClick={handleDelete}>
        <IconDelete />
      </button>

      <button onClick={handleEdit}>
        <IconEdit />
      </button>

      <NavLink to={`/teacher/classrooms/${classroom.id}`}>
        <IconEye className="w-6" />
      </NavLink>
    </div>
  );
}

ClassroomTableRowActions.propTypes = {
  classroom: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ClassroomTableRowActions;
