class NavigationItemModel {
  constructor({
    Icon,
    absolutePath,
    action,
    component,
    id,
    name,
    relativePath,
    to,
  }) {
    this.absolutePath = absolutePath;
    this.action = action;
    this.component = component;
    this.Icon = Icon;
    this.id = id;
    this.name = name;
    this.to = to;
    this.relativePath = relativePath;
  }
}

export default NavigationItemModel;
