import React from 'react';

import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import ClassroomLaunchStatus from './ClassroomLaunchStatus';
import ClassroomTableRowActions from './ClassroomTableRowActions';

function ClassroomTableRow(props) {
  const { classroom, onChange } = props;

  return (
    <tr className="ClassroomTableRow grid grid-cols-[160px_1fr_1fr_160px] pl-8 pr-4 w-full h-[52px] items-center hover:bg-Grey">
      <td>
        <ClassroomLaunchStatus launchStatus={classroom.launchStatus} />
      </td>
      <td>
        <NavLink
          className="p-1"
          to={`/teacher/classrooms/${classroom.id}`}
        >
          {classroom.name}
        </NavLink>
      </td>
      <td>
        <NavLink className="ho-link flex-1" to={`/teacher/courses/${classroom.courseId}`}>
          {classroom.courseName}
        </NavLink>
      </td>
      <td className="text-center">
        <ClassroomTableRowActions
          classroom={classroom}
          onChange={onChange}
        />
      </td>
    </tr>
  );
}

ClassroomTableRow.propTypes = {
  classroom: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default ClassroomTableRow;
