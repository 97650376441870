import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import ProgressBar from 'src/components/ui-kit/ProgressBar';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import { ReactComponent as IconLessons } from 'src/images/icons/lessons.svg';
import { ReactComponent as IconStudents } from 'src/images/icons/students.svg';

function CourseCard(props) {
  const { course, progress, showProgress } = props;

  const { role } = useCurrentUser();

  const { t } = useTranslation();

  return (
    <NavLink className="CourseCard ho-card overflow-hidden" to={`/${role}/courses/${course.id}`}>
      <div className="relative">
        <div
          className="h-40 bg-ho-grey-light"
          style={{
            backgroundImage: `url(${course.imageUrl})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        />
      </div>

      {showProgress && (
        <ProgressBar progress={progress} />
      )}

      <div className="px-6 pb-6 pt-3">
        <div className="flex items-center justify-between gap-2.5 text-MainBlue text-xs">
          <div className="flex gap-2.5 items-center">
            <div className="flex gap-1 items-center" title={t('Lessons')}>
              <IconLessons className="opacity-60 h-[18px] w-[18px]" />
              <span>{course.stats.lessons}</span>
            </div>

            <div className="flex gap-1 items-center" title={t('Students')}>
              <IconStudents className="opacity-60 h-[18px] w-[18px]" />
              <span>{course.stats.students}</span>
            </div>
          </div>

          <div className="self-end">
            {showProgress && (
              `${progress}%`
            )}
          </div>
        </div>

        <h4
          className="mt-3.5 truncate"
          title={course.name}
        >
          {course.name}
        </h4>

        <p className="mt-1 text-xs text-FontBlue">
          {t('By {{professorName}}', { professorName: `${course.professor.firstName} ${course.professor.lastName}` })}
        </p>

        {/* <div className="mt-5 flex justify-between items-center"> */}
        {/*  {!course.purchased && ( */}
        {/*  <h5> */}
        {/*    <span>{currency}</span> */}
        {/*    {course.price} */}
        {/*  </h5> */}
        {/*  )} */}
        {/* </div> */}
      </div>
    </NavLink>
  );
}

CourseCard.propTypes = {
  course: PropTypes.object.isRequired,
  progress: PropTypes.number,
  showProgress: PropTypes.bool,
};

CourseCard.defaultProps = {
  progress: 0,
  showProgress: false,
};

export default CourseCard;
