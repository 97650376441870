import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { statuses, useCourses } from 'src/api/courses';
import CourseCard from 'src/components/common/CourseCard';
import DataGrid from 'src/components/ui-kit/DataGrid';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import usePagination from 'src/helpers/usePagination';

function CoursesDataGrid(props) {
  const { params } = props;

  const { role } = useCurrentUser();

  const { t } = useTranslation();

  const {
    fetchNextPage,
    hasNextPage,
    isFetched,
    isFetching,
    items: courses,
  } = useCourses({
    ...params,
    role,
    size: 20,
  });

  const { onLoadMore } = usePagination({ fetchNextPage });

  return (
    <DataGrid
      emptyText={params.status === statuses.AVAILABLE_ONLY ? t('No available courses') : t('You have no purchased courses')}
      hasNextPage={hasNextPage}
      isEmpty={isFetched && courses.length === 0}
      isFetching={isFetching}
      onLoadMore={onLoadMore}
    >
      <div className="mt-6 grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-7">
        {courses.map((course) => (
          <CourseCard
            key={course.id}
            course={course}
          />
        ))}
      </div>
    </DataGrid>
  );
}

CoursesDataGrid.propTypes = {
  params: PropTypes.object.isRequired,
};

export default CoursesDataGrid;
