import React from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useCourse } from 'src/api/courses';
import CourseOverview from 'src/components/common/Course/CourseOverview';
import CourseProfessor from 'src/components/common/Course/CourseProfessor';
import Page from 'src/components/layout/Page';
import Breadcrumbs from 'src/components/ui-kit/Breadcrumbs';
import Loading from 'src/components/ui-kit/Loading';
import SidebarMenu from 'src/components/ui-kit/SidebarMenu';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import CourseSourceList from 'src/views/Teacher/Course/CourseSourceList/CourseSourceList';
import CourseTeacherDocuments from 'src/views/Teacher/Course/CourseTeacherDocuments';

import CourseLessons from './CourseLessons/CourseLessons';
import useCourseNavigation from './useCourseNavigation';

function Course() {
  const { t } = useTranslation();
  const { courseId } = useParams();
  const { role } = useCurrentUser();
  const { data: course, error, isFetching, refetch } = useCourse({ courseId, role });
  const {
    activeMenuItem,
    breadcrumbs,
    menuItems,
    setActiveMenuItem,
  } = useCourseNavigation(course);

  if (isFetching || error) {
    return (
      <Page title={t('Course')}>
        <Loading />
      </Page>
    );
  }

  return (
    <Page title={course.name}>
      <h1 className="mb-2">{course.name}</h1>

      <Breadcrumbs breadcrumbs={breadcrumbs} />

      <section className="grid grid-cols-[208px_auto] 2xl:grid-cols-[208px_auto_208px] gap-6 mt-9 pb-10">
        <SidebarMenu
          activeMenuItem={activeMenuItem}
          className="-ml-6"
          menuItems={menuItems}
          setActiveMenuItem={setActiveMenuItem}
        />

        {activeMenuItem === 'overview' && (
          <CourseOverview course={course} />
        )}

        {activeMenuItem === 'professor' && (
          <CourseProfessor course={course} />
        )}

        {activeMenuItem === 'lessons' && (
          <CourseLessons course={course} onCourseChange={refetch} />
        )}

        {activeMenuItem === 'source' && (
          <CourseSourceList course={course} />
        )}

        {activeMenuItem === 'teacherDocs' && (
          <CourseTeacherDocuments course={course} />
        )}
      </section>
    </Page>
  );
}

export default Course;
