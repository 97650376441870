import React, { useState, useRef } from 'react';

import PropTypes from 'prop-types';
import { Popover } from 'react-tiny-popover';

import { ReactComponent as IconMenu } from 'src/images/icons-deprecated/menu.svg';
import MenuDropdownItemModel from 'src/models/MenuDropdownItemModel';

const positions = ['top', 'left', 'right', 'bottom'];

function MenuDropdown(props) {
  const { data, items, position } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const triggerRef = useRef();

  const onItemClick = (item) => () => {
    if (item.action) {
      item.action(data);
    }

    setIsPopoverOpen(false);
  };

  const getContent = () => (
    <div className="MenuDropdown__popover ho-card px-5 py-4">
      <nav>
        <ul>
          {items.map((item) => (
            <li key={item.name}>
              <button
                className="button--text w-full block text-left py-2 hover:text-MainYellow"
                onClick={onItemClick(item)}
              >
                {item.name}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );

  return (
    <Popover
      align="end"
      boundaryElement={triggerRef.current}
      content={getContent}
      isOpen={isPopoverOpen}
      onClickOutside={() => setIsPopoverOpen(false)}
      padding={10}
      positions={[position]}
      reposition
    >
      <button
        ref={triggerRef}
        className="button--text p-4"
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
      >
        <IconMenu />
      </button>
    </Popover>
  );
}

MenuDropdown.defaultProps = {
  position: 'top',
};

MenuDropdown.propTypes = {
  data: PropTypes.any.isRequired,
  items: PropTypes.arrayOf(PropTypes.instanceOf(MenuDropdownItemModel)).isRequired,
  position: PropTypes.oneOf(positions),
};

export default MenuDropdown;
