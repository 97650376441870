module.exports = {
  // TODO: Have a think about alignment
  Black: '#000000',
  Blue: 'rgba(46, 125, 247, 1)',
  DarkBlue: 'rgba(18, 44, 84, 1)',
  DarkestBlue: '#09162A',
  FontBlue: 'rgba(18, 44, 84, 0.8)',
  FontDarkBlue: '#0E2343',
  Green: 'rgba(104, 179, 62, 1)',
  Grey: '#FCFCFD',
  GreyDisabled: '#DCDFE2',
  LightBlue: 'rgba(238, 241, 246, 1)',
  MainBlue: '#122C54',
  MainYellow: '#F6C644',
  Red: 'rgba(255, 65, 68, 1)',
  White: '#ffffff',

  // TODO: Redesign: Remove old colours
  'ho-blue-100': '#D5E5FD',
  'ho-blue-200': '#82B1FA',
  'ho-blue-300': '#6DA4F9',
  'ho-blue-400': '#5494F9',
  'ho-blue-500': '#438AF8',
  'ho-blue-600': '#2E7DF7',

  'ho-declined': '#FF4144',

  'ho-green': '#21D679',

  'ho-grey': '#E5E5E5',
  'ho-grey-100': '#E0E0E0',
  'ho-grey-200': '#BDBDBD',
  'ho-grey-300': '#636363',
  'ho-grey-400': '#474747',
  'ho-grey-light': '#F7F8FB',

  'ho-orange-100': '#FC7E34',
  'ho-orange-200': '#FF5E00',

  'ho-yellow': '#F6C644',
};
