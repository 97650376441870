import { useInfiniteQuery, useMutation, useQuery } from 'react-query';

import { api } from 'src/api/api';
import { transformInfiniteQueryResult } from 'src/api/helpers';

export const DISCUSSION_QUERY_NAME = 'DISCUSSION_QUERY_NAME';

export const DISCUSSIONS_QUERY_NAME = 'DISCUSSIONS_QUERY_NAME';

export function useDiscussion({ discussionId, role }) {
  return useQuery(
    [DISCUSSION_QUERY_NAME, discussionId],
    // () => api.get(`/teacher/discussions/${discussionId}`).then((response) => response.data),
    () => api.get(`/${role}/discussions`).then((response) => response.data.content.find((d) => d.id === Number(discussionId))),
  );
}

export function useDiscussions({ size }, role) {
  const fetchDiscussions = ({ pageParam = 0 }) => api.get(`/${role}/discussions`, {
    params: {
      page: pageParam,
      size,
    },
  }).then((response) => response.data);

  const result = useInfiniteQuery(DISCUSSIONS_QUERY_NAME, fetchDiscussions, {
    cacheTime: 0,
    getNextPageParam: (lastPage) => (lastPage.last ? undefined : lastPage.number + 1),
  });

  return transformInfiniteQueryResult(result);
}

export function useCreateDiscussionMutation() {
  return useMutation(
    (body) => api.post('/teacher/discussions', body),
  );
}

export function useUpdateDiscussionMutation() {
  return useMutation(
    ({ body, id }) => api.put(`/teacher/discussions/${id}`, body),
  );
}

export function useDiscussionImageUrlMutation() {
  return useMutation(({ blob, discussionId }) => {
    const body = new FormData();
    body.append('file', blob);
    return api.post(`/teacher/discussions/${discussionId}/image`, body);
  });
}

export function useDeleteDiscussionMutation() {
  return useMutation(
    ({ id }) => api.delete(`/teacher/discussions/${id}`),
  );
}
