import React, { useLayoutEffect } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCreateClassroomMutation, useUpdateClassroomMutation } from 'src/api/classrooms';
import Modal from 'src/components/ui-kit/Modal';
import { showErrorMessage, showSuccessMessage } from 'src/helpers/toast';
import { useModal } from 'src/helpers/useModal';
import useValidationHelpers from 'src/helpers/useValidationHelpers';

import useClassroomForm from './useClassroomForm';
import SelectCourseDropdown from '../../common/SelectCourseDropdown';

function ClassroomModal(props) {
  const { classroom, isOpen, onClose, onSuccess } = props;

  const isEditing = !!classroom;

  const { t } = useTranslation();
  const { closeModal } = useModal();

  const { initialFormData, validationResolver } = useClassroomForm({ classroom });
  const { control, formState, handleSubmit, register, setFocus } = useForm({
    defaultValues: initialFormData,
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: validationResolver,
  });
  const { getFieldError } = useValidationHelpers({ formState });

  const { mutateAsync: createClassroom } = useCreateClassroomMutation();
  const { mutateAsync: updateClassroom } = useUpdateClassroomMutation();

  useLayoutEffect(() => {
    setTimeout(() => setFocus('name'));
  }, [setFocus]);

  const onSubmit = async (formData, event) => {
    event.preventDefault();

    try {
      if (isEditing) {
        await updateClassroom({
          body: {
            name: formData.name,
          },
          id: classroom.id,
        });
        showSuccessMessage(t('Classroom successfully updated'));
      } else {
        await createClassroom({
          courseId: formData.course.id,
          name: formData.name,
        });
        showSuccessMessage(t('Classroom successfully created'));
      }
      onSuccess();
      closeModal();
    } catch (e) {
      showErrorMessage(e.response.data?.message ?? e.message);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      shouldShowCloseButton={false}
      size={isEditing ? 'sm' : 'md'}
      title={isEditing ? t('Edit Classroom') : t('Create Classroom')}
    >
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="grid">
          <input
            className={classNames('input mt-2', { 'input--has-error': getFieldError('name') })}
            id="ClassroomModal__name"
            name="name"
            placeholder={t('Classroom name*')}
            {...register('name')}
          />
          <p className="error-message mt-1">{getFieldError('name')}</p>
        </div>

        {!isEditing && (
          <div className="grid mt-5">
            <Controller
              control={control}
              id="ClassroomModal__course"
              name="course"
              render={({ field: { onChange, value } }) => (
                <SelectCourseDropdown
                  onChange={onChange}
                  placeholder={t('Select Course*')}
                  value={value}
                />
              )}
            />
            <p className="error-message mt-1">{getFieldError('course')}</p>
          </div>
        )}

        <div className="mt-10 flex items-center justify-between">
          <button
            className="button--secondary-outlined"
            onClick={onClose}
            type="button"
          >
            {t('Cancel')}
          </button>

          <button
            className="button--primary"
            disabled={!formState.isValid}
            type="submit"
          >
            {isEditing ? t('Save') : t('Create')}
          </button>
        </div>
      </form>
    </Modal>
  );
}

ClassroomModal.defaultProps = {
  classroom: null,
  onClose: null,
};

ClassroomModal.propTypes = {
  classroom: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
};

export default ClassroomModal;
