import React from 'react';

import PropTypes from 'prop-types';

import ImageViewer from 'src/components/ui-kit/ImageViewer';

function IMAGE(props) {
  const { contentItem } = props;

  return (
    <div className="IMAGE">
      <ImageViewer
        altText={contentItem.altText}
        src={contentItem.body}
        title={contentItem.altText}
      />
    </div>
  );
}

IMAGE.propTypes = {
  contentItem: PropTypes.object.isRequired,
};

export default IMAGE;
