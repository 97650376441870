import React from 'react';

import PropTypes from 'prop-types';

import Footer from 'src/components/common/Footer';
import Header from 'src/components/common/Header';

function Layout(props) {
  const { Menu, children } = props;

  return (
    <div className="Layout">
      <Header Menu={Menu} />
      <div className="h-screen flex flex-col overflow-y-scroll">
        <div className="Layout__content pt-[88px] pb-4 px-6 flex-1 mt-4">
          {children}
        </div>
        <Footer />
      </div>
    </div>
  );
}

Layout.propTypes = {
  Menu: PropTypes.node.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Layout;
