import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useShowLessonMutation, useHideLessonMutation } from 'src/api/courses';
import { showErrorMessage, showSuccessMessage } from 'src/helpers/toast';
import { ReactComponent as IconEyeCrossed } from 'src/images/icons/eye-crossed.svg';
import { ReactComponent as IconEye } from 'src/images/icons/eye.svg';

function LessonCardToggleVisibilityButton(props) {
  const {
    course: { id: courseId },
    lesson: { id: lessonId, visible },
    onSuccess,
  } = props;

  const { t } = useTranslation();

  const { isLoading: isShowingLesson, mutateAsync: showLesson } = useShowLessonMutation();
  const { isLoading: isHidingLesson, mutateAsync: hideLesson } = useHideLessonMutation();

  const onClick = async () => {
    try {
      if (visible) {
        await hideLesson({ courseId, lessonId });
        showSuccessMessage(t('The lesson is now hidden from the students'));
      } else {
        await showLesson({ courseId, lessonId });
        showSuccessMessage(t('The lesson is now visible to the students'));
      }
      onSuccess();
    } catch (e) {
      showErrorMessage(e.message);
    }
  };

  return (
    <button
      className="LessonCardToggleVisibilityButton p-1 scale-75"
      disabled={isHidingLesson || isShowingLesson}
      onClick={onClick}
      title={visible ? t('Hide the lesson from students') : t('Show the lesson to students')}
    >
      {visible ? <IconEye /> : <IconEyeCrossed />}
    </button>
  );
}

LessonCardToggleVisibilityButton.propTypes = {
  course: PropTypes.object.isRequired,
  lesson: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
export default LessonCardToggleVisibilityButton;
